import React, { createContext, useContext, useEffect, useState } from "react";

import axios from "axios";
import useAuth from "src/hooks/useAuth";

interface UserModulesType {
  [moduleName: string]: string[];
}

interface UserContextType {
  userModules: UserModulesType;
  loading: boolean;
}

interface UserModulesResponse {
  userModules: UserModulesType;
}

const UserContext = createContext<UserContextType | undefined>(undefined);

export const UserProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [userModules, setUserModules] = useState<UserModulesType>({});
  const [loading, setLoading] = useState(true);
  const auth = useAuth();

  useEffect(() => {
    const fetchUserModules = async () => {
      try {
        const response = await axios.get<UserModulesResponse>(
          `${process.env.REACT_APP_API_BASE_URL}api/accounts/current/module_access`,
        );
        setUserModules(response.data.userModules);
      } catch (error) {
        console.error("Error fetching user modules:", error);
      } finally {
        setLoading(false);
      }
    };
    if (auth.isAuthenticated) {
      fetchUserModules();
    }
  }, [auth]);

  return (
    <UserContext.Provider value={{ userModules, loading }}>
      {children}
    </UserContext.Provider>
  );
};

export const useUser = () => {
  const context = useContext(UserContext);
  if (context === undefined) {
    throw new Error("useUser must be used within a UserProvider");
  }
  return context;
};

export enum InsightSessionStatus {
  THINKING = "THINKING",
  COMPLETED = "COMPLETED",
  ERROR = "ERROR",
  CANCELLED = "CANCELLED",
}

export enum InsightMessageRunStatus {
  THINKING = "THINKING",
  COMPLETED = "COMPLETED",
  ERROR = "ERROR",
  CANCELLED = "CANCELLED",
}
export interface KnowledgeBankDataEntry {
  id: string;
  content: string;
  sourceId: string;
}

export interface KnowledgeBankDataSource {
  id: string;
  name: string;
}

export interface InsightMessage {
  id: string;
  msg: string;
  date: string;
  isAIMessage: boolean;
  contentUsed?: string[];
  sources?: string[];
  isRated: boolean;
}

export interface InsightSessionAction {
  actionId: string;
  type: string;
  title: string;
  result: string;
  feedbackGiven: boolean;
  isComplete: boolean;
}

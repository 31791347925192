import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { AgentAction } from "src/models/agent";
import { AppThunk } from "src/store";

import type { PayloadAction } from "@reduxjs/toolkit";

interface IAutomatedNegotiationState {
  actions: AgentAction[];
  showActionTimeline: boolean;
}

const initialState: IAutomatedNegotiationState = {
  actions: [],
  showActionTimeline: false,
};

const negotiationSlice = createSlice({
  name: "aani-automated-negotiation",
  initialState,
  reducers: {
    loadActions(state, action: PayloadAction<AgentAction[]>) {
      return {
        ...state,
        actions: action.payload,
      };
    },
    setShowActionTimeline(state, action: PayloadAction<boolean>) {
      return {
        ...state,
        showActionTimeline: action.payload,
      };
    },
  },
});

export const loadMessageActions =
  (negotiationId: string, messageId: string): AppThunk =>
  async (dispatch) => {
    try {
      const response = await axios({
        headers: {
          "Content-Type": "application/json",
          "Cache-Control": "no-cache",
        },
        method: "GET",
        url: `${process.env.REACT_APP_API_BASE_URL}api/aani/negotiations/automated/${negotiationId}/${messageId}/actions`,
      });

      const { actions } = response.data;
      dispatch(negotiationSlice.actions.loadActions(actions));
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

export const toggleShowActionTimeline =
  (): AppThunk => (dispatch, getState) => {
    const currentState = getState().negotiation.showActionTimeline;
    dispatch(negotiationSlice.actions.setShowActionTimeline(!currentState));
  };

export default negotiationSlice;

import { createSlice } from "@reduxjs/toolkit";
import axios, { AxiosResponse } from "axios";
import { AppThunk } from "src/store";

import type { PayloadAction } from "@reduxjs/toolkit";

export enum UserStatus {
  ACTIVE = "ACTIVE",
  INVITED = "INVITED",
  SUSPENDED = "SUSPENDED",
  DELETED = "DELETED",
}

export interface AccountUser {
  id: string;
  name: string;
  email: string;
  status: UserStatus;
  createdOn: string;
  invitationExpiresOn?: string;
}

interface UserSettingsState {
  users: AccountUser[];
  loading: boolean;
}

const initialState: UserSettingsState = {
  users: [],
  loading: false,
};
const API_PREFIX = `${process.env.REACT_APP_API_BASE_URL}api/accounts`;

const userSettingsSlice = createSlice({
  name: "user-settings",
  initialState,
  reducers: {
    loadUsersStart(state) {
      return {
        ...state,
        loading: true,
      };
    },
    loadUsersSuccess(state, action: PayloadAction<AccountUser[]>) {
      return {
        ...state,
        users: action.payload,
        loading: false,
      };
    },
    loadUsersFailure(state) {
      return {
        ...state,
        loading: false,
      };
    },
  },
});

export const getUsers = (): AppThunk => async (dispatch) => {
  try {
    dispatch(userSettingsSlice.actions.loadUsersStart());

    const response: AxiosResponse<{ users: AccountUser[] }> = await axios.get(
      `${API_PREFIX}/current/users`,
    );
    const { users } = response.data;
    dispatch(userSettingsSlice.actions.loadUsersSuccess(users));
  } catch (error) {
    console.error(error);
    dispatch(userSettingsSlice.actions.loadUsersFailure());
    throw error;
  }
};

export default userSettingsSlice;

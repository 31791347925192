import AddIcon from "@mui/icons-material/Add";
import { ListItemButton, ListItemText, Stack, styled } from "@mui/material";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { useNavigate, useParams } from "react-router-dom";
import { TrainingNegotiationSession } from "src/slices/training/trainingNegotiation";

const ListItemWrapper = styled(ListItemButton)(
  ({ theme }) => `
        &.MuiButtonBase-root {
            margin: ${theme.spacing(1)} 0;
            background-image: none;
            overflow: hidden;
            border: 1px solid rgb(229, 234, 242);
            border-radius: 8px;
        },

        &.Mui-selected {
          color: ${theme.colors.alpha.black[1]};
        }
  `,
);

const SessionList = ({
  sessions,
}: {
  sessions: TrainingNegotiationSession[];
}) => {
  const navigate = useNavigate();
  const { id: sessionId } = useParams<{ id: string }>();

  const handleSessionClick = (id: string) => {
    navigate(`/training/negotiation/${id}`);
  };

  const handleNewSessionClick = () => {
    navigate("/training/negotiation/create");
  };

  return (
    <>
      <Stack padding={0}>
        <Button
          variant="contained"
          startIcon={<AddIcon />}
          onClick={handleNewSessionClick}
          sx={{ marginBottom: "2rem" }}
        >
          New Session
        </Button>
      </Stack>
      <Typography
        variant="subtitle2"
        color="secondary"
        sx={{ textTransform: "uppercase" }}
      >
        Training Sessions
      </Typography>
      <Stack spacing={0.5}>
        {sessions.map((session) => (
          <ListItemWrapper
            key={session.id}
            onClick={() => handleSessionClick(session.id)}
            selected={session.id === sessionId}
          >
            <ListItemText
              sx={{
                mr: 1,
              }}
              primaryTypographyProps={{
                color: "textPrimary",
                variant: "h5",
                noWrap: true,
              }}
              secondaryTypographyProps={{
                color: "textSecondary",
                noWrap: true,
              }}
              primary={session.name === null ? "Untitled" : session.name}
            />
          </ListItemWrapper>
        ))}
      </Stack>
    </>
  );
};

export default SessionList;

import { useState } from "react";

import AddTwoToneIcon from "@mui/icons-material/AddTwoTone";
import { Grid, Typography, Button } from "@mui/material";
import { useTranslation } from "react-i18next";

import InviteUserDialog from "./InviteUserDialog";

const PageHeader = () => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);

  const handleCreateUserOpen = () => {
    setOpen(true);
  };

  return (
    <>
      <Grid container justifyContent="space-between" alignItems="center">
        <Grid item>
          <Typography variant="h3" component="h3" gutterBottom>
            {t("Users Management")}
          </Typography>
          <Typography variant="subtitle2">
            {t(
              "All aspects related to the organisation users can be managed from this page",
            )}
          </Typography>
        </Grid>
        <Grid item>
          <Button
            sx={{
              mt: { xs: 2, sm: 0 },
            }}
            onClick={handleCreateUserOpen}
            variant="contained"
            startIcon={<AddTwoToneIcon fontSize="small" />}
          >
            {t("Invite user")}
          </Button>
        </Grid>
      </Grid>

      <InviteUserDialog open={open} setOpen={setOpen} />
    </>
  );
};

export default PageHeader;

import { styled } from "@mui/material";
import { Link } from "react-router-dom";

const LogoWrapper = styled(Link)(
  ({ theme }) => `
        color: ${theme.palette.text.primary};
        display: flex;
        text-decoration: none;
        width: 150px;
        margin: 0 auto;
        font-weight: ${theme.typography.fontWeightBold};
`,
);

const ImgWrapper = styled("img")(
  ({ theme }) => `
    margin-right: ${theme.spacing(1)};
    width:150px;
`,
);

function Logo() {
  return (
    <LogoWrapper to="/">
      <ImgWrapper alt="logo" src="/static/images/logo/uncapt-logo.svg" />
    </LogoWrapper>
  );
}

export default Logo;

import React, { useState } from "react";

import { Helmet } from "react-helmet-async";
import { Outlet } from "react-router-dom";

import NavigationBar from "./NavigationBar";

import ModuleMenu from "../shared/ModuleMenu";
import "./index.css";

const InsightLayout = () => {
  const [moduleMenuOpen, setModuleMenuOpen] = useState(false);

  const toggleDrawer = () => {
    setModuleMenuOpen(!moduleMenuOpen);
  };

  return (
    <>
      <Helmet title="Insight" />
      <div className={`container ${moduleMenuOpen ? "drawer-open" : ""}`}>
        {/* Module Menu */}
        <aside className={`sidenav ${moduleMenuOpen ? "open" : ""}`}>
          <ModuleMenu />
        </aside>

        {/* Main content */}
        <div id="main">
          <header>
            <NavigationBar toggleDrawer={toggleDrawer} />
          </header>
          <section>
            <Outlet />
          </section>
        </div>
      </div>
    </>
  );
};

export default InsightLayout;

import React from "react";

import Label from "src/components/Label";
import { UserStatus } from "src/slices/settings/user";

const statusMap = [
  {
    status: UserStatus.ACTIVE,
    color: "success",
    label: "Active",
  },
  {
    status: UserStatus.SUSPENDED,
    color: "error",
    label: "Suspended",
  },
  {
    status: UserStatus.INVITED,
    color: "info",
    label: "Invited",
  },
];

interface StatusLabelProps {
  status: UserStatus;
}

const StatusLabel: React.FC<StatusLabelProps> = ({ status }) => {
  const statusInfo = statusMap.find((s) => s.status === status);

  if (!statusInfo) return <>{status}</>;

  return (
    <Label color={statusInfo.color} sx={{ fontWeight: 500 }}>
      {statusInfo.label}
    </Label>
  );
};

export default StatusLabel;

import React, { useState } from "react";

import CloseIcon from "@mui/icons-material/Close";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import { LoadingButton } from "@mui/lab";
import {
  IconButton,
  Tooltip,
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  Box,
  Avatar,
} from "@mui/material";
import axios from "axios";
import { useTranslation } from "react-i18next";
import { useSnackbarService } from "src/hooks/useSnackbarService";
import { AccountUser } from "src/slices/settings/user";

interface CancelInviteButtonProps {
  user: AccountUser;
  onInviteCanceled: () => void;
}

const CancelInviteButton: React.FC<CancelInviteButtonProps> = ({
  user,
  onInviteCanceled,
}) => {
  const [open, setOpen] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const { showSuccessSnackbar, showErrorSnackbar } = useSnackbarService();
  const { t } = useTranslation();

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleCancelInvite = async () => {
    setIsDeleting(true);
    try {
      const response = await axios.delete(
        `${process.env.REACT_APP_API_BASE_URL}api/settings/users/${user.id}/invite`,
      );
      if (response.data.isSuccess) {
        showSuccessSnackbar(response.data.message);
        onInviteCanceled();
      } else {
        showErrorSnackbar(response.data.message);
      }
    } catch (error) {
      showErrorSnackbar(error.message);
    } finally {
      setIsDeleting(false);
      handleClose();
    }
  };

  return (
    <>
      <Tooltip title={t("Cancel Invite")}>
        <IconButton
          aria-label="cancel invite"
          onClick={handleOpen}
          color="error"
        >
          <DeleteOutlineOutlinedIcon />
        </IconButton>
      </Tooltip>
      <Dialog
        open={open}
        onClose={!isDeleting ? handleClose : undefined}
        fullWidth
        maxWidth="xs"
      >
        <Box sx={{ p: 4 }}>
          <IconButton
            onClick={handleClose}
            sx={{ position: "absolute", top: 16, right: 16 }}
            size="small"
            disabled={isDeleting}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
          <DialogContent
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              textAlign: "center",
              padding: 0,
            }}
          >
            <Avatar
              aria-label="Delete icon"
              sx={{
                bgcolor: "rgba(255, 0, 0, 0.1)",
              }}
              variant="circular"
            >
              <DeleteOutlineOutlinedIcon sx={{ color: "error.main" }} />
            </Avatar>
            <Box aria-label="Ask confirmation" sx={{ mt: 2 }}>
              <Typography variant="h4" mb={1}>
                {t("Are you sure?")}
              </Typography>
              <Typography>
                {t(
                  "You want to cancel this invitation? This action cannot be undone.",
                )}
              </Typography>
            </Box>
          </DialogContent>
          <DialogActions sx={{ justifyContent: "center", mt: 2 }}>
            <Button
              onClick={handleClose}
              variant="outlined"
              color="primary"
              sx={{ width: 150, mr: 1 }}
              disabled={isDeleting}
            >
              {t("No, keep invite")}
            </Button>
            <LoadingButton
              onClick={handleCancelInvite}
              variant="contained"
              color="error"
              sx={{ width: 150 }}
              loading={isDeleting}
            >
              {t("Yes, cancel invite")}
            </LoadingButton>
          </DialogActions>
        </Box>
      </Dialog>
    </>
  );
};

export default CancelInviteButton;

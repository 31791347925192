import MenuIcon from "@mui/icons-material/Menu";
import { Grid, IconButton, Typography, useTheme } from "@mui/material";
import NewClientButton from "src/content/mia/TopSection/NewClientButton";
import ProgressIndicator from "src/content/mia/TopSection/ProgressIndicator";
import HeaderUserbox from "src/layouts/shared/Header/Userbox";
import { ClientSessionStage, ClientSessionStatus } from "src/slices/mia/mia";
import { useSelector } from "src/store";

const MiaNavigationBar = ({ toggleDrawer }: { toggleDrawer: () => void }) => {
  const theme = useTheme();
  const { clientSession } = useSelector((state) => state.mia);

  const shouldShowProgress =
    clientSession?.sessionStage === ClientSessionStage.INTAKE &&
    clientSession.status !== ClientSessionStatus.COMPLETED;

  return (
    <Grid
      container
      direction="row"
      height="100%"
      paddingX="16px"
      color={theme.palette.common.white}
      paddingY={0}
      sx={{
        justifyContent: "space-between",
        alignItems: "center",
        backgroundColor: theme.palette.common.white,
      }}
    >
      {/* Left Section: Module Name & Client Name */}
      <Grid item xs={5} display="flex" alignItems="center" columnGap="16px">
        <IconButton size="medium" onClick={toggleDrawer} color="primary">
          <MenuIcon
            fontSize="small"
            sx={{ color: theme.colors.primary.main }}
          />
        </IconButton>
        <Typography variant="h5" sx={{ color: theme.colors.secondary.main }}>
          MIA
        </Typography>
        <Typography
          variant="subtitle1"
          color={theme.colors.primary.main}
          sx={{
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          {clientSession?.clientFirstname && clientSession?.clientLastname
            ? `${clientSession.clientFirstname} ${clientSession.clientLastname}`
            : clientSession?.clientFirstname}
        </Typography>
      </Grid>

      {/* Middle Section: Progress Indicator */}
      <Grid item xs={4}>
        {shouldShowProgress && <ProgressIndicator session={clientSession} />}
      </Grid>

      {/* Right Section: New Client Button & User Header Box */}
      <Grid
        item
        xs={3}
        display="flex"
        alignItems="center"
        justifyContent="flex-end"
        columnGap="8px"
      >
        <NewClientButton />
        <HeaderUserbox />
      </Grid>
    </Grid>
  );
};

export default MiaNavigationBar;

import { useEffect, useState } from "react";

import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import {
  CssBaseline,
  Zoom,
  Typography,
  createTheme,
  ThemeProvider,
} from "@mui/material";
import axios from "axios";
import { useSnackbar } from "notistack";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import { useTranslation } from "react-i18next";
import { useRoutes, useLocation } from "react-router-dom";

import AppInit from "./components/AppInit";
import SessionExpiredModal from "./components/SessionExpiredModal";
import { UserProvider } from "./contexts/UserContext";
import useAuth from "./hooks/useAuth";
import router from "./router";
import { GreenTheme } from "./theme/schemes/GreenTheme";
import { GreyGooseTheme } from "./theme/schemes/GreyGooseTheme";
import { OrangeTheme } from "./theme/schemes/OrangeTheme";

function App() {
  const { t } = useTranslation();
  const { login } = useAuth();
  const { enqueueSnackbar } = useSnackbar();
  const [isSessionExpired, setIsSessionExpired] = useState(false);
  const location = useLocation();

  async function setGlobalExceptionHandles() {
    axios.interceptors.response.use(
      (response) => response,
      (error) => {
        if (axios.isCancel(error)) {
          // Handle the case when error was caused by the request being canceled
          // (e.g. using an AbortController).
          return Promise.reject(error);
        } else if (error.response.status === 401) {
          setIsSessionExpired(true);
          return Promise.reject(error);
        } else if (error.response.status === 500) {
          enqueueSnackbar(
            <Typography>
              {t("An error has occurred")}
              <br />
              {t(`Error ID: ${error.response.data.request_id}`)}
            </Typography>,
            {
              variant: "error",
              anchorOrigin: {
                vertical: "top",
                horizontal: "right",
              },
              TransitionComponent: Zoom,
              autoHideDuration: 3000,
            },
          );
        }
        return Promise.reject(error);
      },
    );
  }

  useEffect(() => {
    const handleGlobalExceptions = async () => {
      await setGlobalExceptionHandles();
    };
    handleGlobalExceptions();
  }, []);

  const content = useRoutes(router);
  const auth = useAuth();

  // Dynamically select theme based on the current path
  const getTheme = (pathname: string) => {
    if (
      pathname.startsWith("/assist") ||
      pathname.startsWith("/deal") ||
      pathname.startsWith("/insight") ||
      pathname.startsWith("/ila")
    ) {
      return createTheme(OrangeTheme);
    }

    if (pathname.startsWith("/mia")) {
      return createTheme(GreenTheme);
    }

    return createTheme(GreyGooseTheme);
  };

  const theme = getTheme(location.pathname);
  const recaptchaSiteKey = "6Lc0fHUqAAAAAP4-k43ue0scjAjq7nGnmu-ZZwwW";

  return (
    <GoogleReCaptchaProvider reCaptchaKey={recaptchaSiteKey}>
      <ThemeProvider theme={theme}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <CssBaseline />
          {auth.isInitialized ? (
            <UserProvider>{content}</UserProvider>
          ) : (
            <AppInit />
          )}
          <SessionExpiredModal open={isSessionExpired} login={login} />
        </LocalizationProvider>
      </ThemeProvider>
    </GoogleReCaptchaProvider>
  );
}

export default App;

import { useEffect, useState } from "react";

import {
  ListSubheader,
  Box,
  List,
  styled,
  CircularProgress,
} from "@mui/material";
import axios from "axios";
import { matchPath, useLocation } from "react-router-dom";
import Logo from "src/components/LogoSign";
import { Supplier } from "src/models/supplier";

import SidebarMenuItem from "./item";

const MenuWrapper = styled(Box)(
  ({ theme }) => `
    .MuiList-root {
      margin-bottom: ${theme.spacing(1.5)};
      padding: 0;

      & > .MuiList-root {
        padding: 0 ${theme.spacing(0)} ${theme.spacing(0)};
      }
    }

    .MuiListSubheader-root {
      text-transform: uppercase;
      font-weight: bold;
      font-size: ${theme.typography.pxToRem(12)};
      color: ${theme.sidebar.menuItemIconColor};
      padding: ${theme.spacing(1, 3.5)};
      line-height: 1.4;
    }
`,
);

const SubMenuWrapper = styled(Box)(
  ({ theme }) => `
    .MuiList-root {

      .MuiListItem-root {
        padding: 2px 0;
        padding-left: ${theme.spacing(0)};
        padding-right: ${theme.spacing(0)};

        .MuiBadge-root {
          position: absolute;
          right: ${theme.spacing(3.5)};

          .MuiBadge-standard {
            background: ${theme.colors.primary.main};
            font-size: ${theme.typography.pxToRem(10)};
            font-weight: bold;
            text-transform: uppercase;
            color: ${theme.palette.primary.contrastText};
          }
        }
    
        .MuiButton-root {
          display: flex;
          color: ${theme.sidebar.menuItemColor};
          background-color: ${theme.sidebar.menuItemBg};
          width: 100%;
          justify-content: flex-start;
          padding: ${theme.spacing(1, 3, 1, 4)};
          border-bottom-left-radius: 0;
          border-top-left-radius: 0;
          border-bottom-right-radius: 50px;
          border-top-right-radius: 50px;
    
          .MuiButton-startIcon,
          .MuiButton-endIcon {
            transition: ${theme.transitions.create(["color"])};

            .MuiSvgIcon-root {
              font-size: inherit;
              transition: none;
            }
          }

          .MuiButton-startIcon {
            font-size: ${theme.typography.pxToRem(20)};
            margin-right: ${theme.spacing(1)};
            color: ${theme.sidebar.menuItemIconColor};
          }
          
          .MuiButton-endIcon {
            margin-left: auto;
            opacity: .8;
            font-size: ${theme.typography.pxToRem(20)};
          }

          &.Mui-active,
          &:hover {
            background-color: ${theme.sidebar.menuItemBgActive};
            color: ${theme.sidebar.menuItemColorActive};

            .MuiButton-startIcon,
            .MuiButton-endIcon {
                color: ${theme.sidebar.menuItemIconColorActive};
            }
          }
        }

        &.Mui-children {
          flex-direction: column;

          .MuiBadge-root {
            position: absolute;
            right: ${theme.spacing(7.5)};
          }
        }

        .MuiCollapse-root {
          width: 100%;

          .MuiList-root {
            padding: ${theme.spacing(1, 0)};
          }

          .MuiListItem-root {
            padding: 0;
            padding-left: ${theme.spacing(1.2)};

            .MuiListItem-root {
              padding: 0;
            }

            .MuiButton-root {
              .MuiBadge-root {
                right: ${theme.spacing(3.5)};
              }
            }

            .MuiButton-root {
              padding: ${theme.spacing(0.7, 3, 0.7, 5.5)};

              &.Mui-active,
              &:hover {
                background-color: ${theme.sidebar.menuItemBg};
              }
            }
          }
        }
      }
    }
`,
);
interface RenderSidebarMenuItemsProps {
  items: SidebarMenuItemProps[];
  path: string;
}

interface SidebarMenuItemProps {
  name: string;
  active: boolean;
  icon?: React.ElementType;
  link?: string;
  badge: string;
  badgeTooltip: string;
  isChild: boolean;
  open?: boolean;
  items?: SidebarMenuItemProps[];
}

function renderSidebarMenuItems({ items, path }: RenderSidebarMenuItemsProps) {
  function reduceChildRoutes(ev: JSX.Element[], item: SidebarMenuItemProps) {
    const key = item.name;

    if (item.items) {
      const partialMatch = item.link
        ? !!matchPath(
            {
              path: item.link,
              end: false,
            },
            path,
          )
        : false;
      ev.push(
        <SidebarMenuItem
          key={key}
          active={partialMatch}
          open
          name={item.name}
          icon={item.icon}
          link={item.link}
          badge={item.badge}
          badgeTooltip={item.badgeTooltip}
          isChild={item.isChild}
        >
          {renderSidebarMenuItems({
            path,
            items: item.items,
          })}
        </SidebarMenuItem>,
      );
    } else {
      const partialMatch = item.link
        ? !!matchPath(
            {
              path: item.link,
              end: false,
            },
            path,
          )
        : false;
      ev.push(
        <SidebarMenuItem
          key={key}
          active={partialMatch}
          name={item.name}
          link={item.link}
          badge={item.badge}
          badgeTooltip={item.badgeTooltip}
          icon={item.icon}
          isChild={item.isChild}
        />,
      );
    }

    return ev;
  }

  return (
    <SubMenuWrapper>
      <List component="div">
        {items.reduce((ev, item) => reduceChildRoutes(ev, item), [])}
      </List>
    </SubMenuWrapper>
  );
}

const createMenuItems = (suppliers: Supplier[], path: string) => {
  const items = suppliers.map((supplier) => ({
    name: supplier.name,
    link: `/range-review/supplier/${supplier.id}`,
    active: path === `/range-review/supplier/${supplier.id}`,
    icon: null,
    badge: null,
    badgeTooltip: null,
    isChild: false,
  }));

  const allItems = [
    {
      name: "Dashboard",
      link: "/range-review/dashboard",
      active: path === "/range-review/dashboard",
      icon: null,
      badge: null,
      badgeTooltip: null,
      isChild: false,
    },
    ...items,
  ];

  const menu = [
    {
      heading: "Indian Foods",
      items: allItems,
    },
  ];

  return menu;
};

function SidebarMenu() {
  const [isLoading, setIsLoading] = useState(false);
  const location = useLocation();

  const [menuItems, setMenuItems] = useState([]);

  const fetchSuppliers = async () => {
    try {
      const subcategory = "Indian Food";
      setIsLoading(true);
      const response = await axios({
        method: "GET",
        url: `${process.env.REACT_APP_API_BASE_URL}api/aani/range-review/categories/grocery/${subcategory}/suppliers`,
      });
      const { suppliers } = response.data;
      const menu = createMenuItems(suppliers, location.pathname);
      setMenuItems(menu);
      setIsLoading(false);
    } catch (error) {
      console.error(error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchSuppliers();
  }, []);

  if (isLoading) {
    return (
      <Box sx={{ m: 2 }}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
      height="100%"
    >
      {menuItems.map((section) => (
        <MenuWrapper key={section.heading}>
          <List
            component="div"
            subheader={
              <ListSubheader component="div" disableSticky>
                {section.heading}
              </ListSubheader>
            }
          >
            {renderSidebarMenuItems({
              items: section.items,
              path: location.pathname,
            })}
          </List>
        </MenuWrapper>
      ))}
      <Box
        sx={{
          paddingBottom: 2,
        }}
      >
        <Logo />
      </Box>
    </Box>
  );
}

export default SidebarMenu;

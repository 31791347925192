import axios, { AxiosResponse } from "axios";

const API_PREFIX = `${process.env.REACT_APP_API_BASE_URL}api/insight`;

export const generateExplanationStreamUrl = (messageId: string) =>
  `${API_PREFIX}/${messageId}/explanation`;

export const getEmbeddings = async (knowledgebankId: string) => {
  try {
    // Send a POST request to the server to invite a new user
    // The server will send an email to the user with an invitation link
    // with the fields set as parameters
    const response: AxiosResponse<ArrayBuffer> = await axios.get(
      `${API_PREFIX}/knowledgebanks/${knowledgebankId}/embeddings`,
      {
        responseType: "arraybuffer",
      },
    );
    const buffer = response.data;

    return buffer;
  } catch (error) {
    throw new Error(error);
  }
};

export const getClusterDetails = async (bankId: string, clusterId: string) => {
  try {
    const response = await axios.get(
      `${API_PREFIX}/knowledgebanks/${bankId}/clusters/${clusterId}`,
    );
    return response.data;
  } catch (error) {
    throw new Error(error);
  }
};

export const setResponseFeedback = async (
  message_id: string,
  feedback: string,
) => {
  try {
    await axios.put(`${API_PREFIX}/${message_id}/feedback`, { feedback });
  } catch (error) {
    throw new Error(error);
  }
};

export const getHoverContent = async (
  bankId: string,
  id: string,
  options = {},
) => {
  try {
    const response = await axios.get(
      `${API_PREFIX}/knowledgebanks/${bankId}/content/${id}`,
      options,
    );
    return response.data.content;
  } catch (error) {
    throw new Error(error);
  }
};

import axios, { AxiosResponse } from "axios";
import { TrainingType } from "src/slices/training/trainingNegotiation";

import { Catalogue } from "../shared/interface";

const API_PREFIX = `${process.env.REACT_APP_API_BASE_URL}api/training`;

export interface TrainingCataloguesResponse {
  catalogues: Catalogue[];
}

export const getCatalogues = async (
  trainingType: TrainingType = TrainingType.GENERAL,
) => {
  try {
    const response: AxiosResponse<TrainingCataloguesResponse> = await axios.get(
      `${API_PREFIX}/catalogues`,
      {
        params: {
          trainingType,
        },
      },
    );
    return response.data.catalogues as Catalogue[];
  } catch (err) {
    console.error(err);
    throw new Error("Failed to get catalogues");
  }
};

export const createNewSession = async (
  catalogueId: string,
  input: string,
  provider: string,
  modelName: string,
) => {
  try {
    const response = await axios.post(`${API_PREFIX}/sessions`, {
      trainingCatalogueId: catalogueId,
      input,
      provider,
      modelName,
    });
    return response.data;
  } catch (error) {
    console.error(error);
    throw new Error("Failed to create new training session");
  }
};

export const getSessions = async () => {
  try {
    const response = await axios.get(`${API_PREFIX}/sessions`);
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const getSessionsByTrainingType = async (
  trainingType: TrainingType = TrainingType.GENERAL,
) => {
  try {
    const response = await axios.get(
      `${API_PREFIX}/sessions/type/${trainingType}`,
    );
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const getActions = async (sessionId: string, signal?: AbortSignal) => {
  const source = axios.CancelToken.source();
  const config = {
    cancelToken: source.token,
    signal,
  };

  try {
    const response = await axios.get(
      `${API_PREFIX}/sessions/${sessionId}/actions`,
      config,
    );
    return response.data.actions;
  } catch (error) {
    console.error(error);
    throw error;
  } finally {
    if (signal) {
      source.cancel();
    }
  }
};

export const getSession = async (id: string, signal?: AbortSignal) => {
  const source = axios.CancelToken.source();
  const config = {
    cancelToken: source.token,
    signal,
  };

  try {
    const response = await axios.get(`${API_PREFIX}/sessions/${id}`, config);
    return response.data;
  } catch (error) {
    console.log(error);
    throw error;
  } finally {
    if (signal) {
      source.cancel();
    }
  }
};

export const postToolResponse = async (sessionId: string, input: string) => {
  try {
    const url = `${API_PREFIX}/sessions/${sessionId}/response`;
    const requestData = {
      response: input,
    };
    await axios.post(url, requestData);
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export interface Action {
  result: string;
  type: string;
  title: string;
  actionId: number;
  inputData: string;
  completedOn: string;
  feedbackGiven: boolean;
  group: string;
  sessionId: string;
  runId: string;
  startedOn: string;
  resultType: string;
}

export const editToolResult = async (action: Action) => {
  try {
    const { sessionId, runId } = action;
    const url = `${API_PREFIX}/sessions/${sessionId}/${runId}/actions/${action.actionId}`;
    const requestData = {
      action: {
        newResult: action.result,
      },
    };
    await axios.put(url, requestData);
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const editFinalOutput = async (action: Action) => {
  try {
    const { sessionId, runId } = action;
    const url = `${API_PREFIX}/sessions/${sessionId}/${runId}/output/${action.actionId}`;
    const requestData = {
      action: {
        newResult: action.result,
      },
    };
    await axios.put(url, requestData);
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const editTrainingNegotiationOutput = async (
  messageId: string,
  msg: string,
) => {
  try {
    const url = `${API_PREFIX}/negotiation/${messageId}/output`;
    const requestData = {
      msg,
    };
    await axios.put(url, requestData);
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const setUserFeedbackOnAction = async (
  action: Action,
  feedbackType: string,
) => {
  try {
    const { sessionId, runId, actionId } = action;
    const url = `${API_PREFIX}/sessions/${sessionId}/${runId}/actions/${actionId}/feedback`;
    const requestData = {
      feedbackType,
    };
    await axios.post(url, requestData);
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const setUserFeedbackOnAgentRun = async (
  runId: string,
  feedbackType: string,
) => {
  try {
    const url = `${API_PREFIX}/message_id/${runId}}/feedback`;
    const requestData = {
      feedbackType,
    };
    await axios.post(url, requestData);
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const setUserFeedbackOnOutput = async (
  sessionId: string,
  feedbackType: string,
) => {
  try {
    const url = `${API_PREFIX}/sessions/${sessionId}/feedback`;
    const requestData = {
      feedbackType,
    };
    await axios.post(url, requestData);
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const cancelSession = async (sessionId: string) => {
  try {
    const url = `${API_PREFIX}/sessions/${sessionId}/cancel`;
    await axios.post(url);
  } catch (error) {
    console.error(error);
    throw error;
  }
};

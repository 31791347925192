import React, { useState } from "react";

import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import {
  IconButton,
  Tooltip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  CircularProgress,
  Box,
} from "@mui/material";
import { AccountUser, getUsers } from "src/slices/settings/user";
import { useDispatch } from "src/store";

import { UnsuspendUserResponse, unsuspendUser } from "../../api/user";
import { useDialog } from "../hooks/useDialog";
import { useSnackbarService } from "../hooks/useSnackbarService";

interface UnsuspendButtonProps {
  user: AccountUser;
}

const UnsuspendButton: React.FC<UnsuspendButtonProps> = ({ user }) => {
  const { showErrorSnackbar, showSuccessSnackbar } = useSnackbarService();
  const { open, handleOpen, handleClose } = useDialog();
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const handleUnsuspendUser = async () => {
    try {
      setLoading(true);
      const response: UnsuspendUserResponse = await unsuspendUser(user.id);
      if (response.isSuccess) {
        showSuccessSnackbar(response.message);
        // Update the users state in the Redux store
        // after the user has been unsuspended
        dispatch(getUsers());
      } else {
        showErrorSnackbar(response.message);
      }
    } catch (error) {
      showErrorSnackbar(error.message);
    } finally {
      setLoading(false);
      handleClose();
    }
  };

  return (
    <>
      <Tooltip title="Unsuspend">
        <IconButton aria-label="unsuspend" onClick={handleOpen}>
          <CheckCircleOutlineIcon />
        </IconButton>
      </Tooltip>
      <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
        <DialogTitle>Unsuspend User</DialogTitle>
        {loading ? (
          <Box display="flex" justifyContent="center" pb={4}>
            <CircularProgress />
          </Box>
        ) : (
          <>
            <DialogContent>
              <DialogContentText>
                Are you sure you want to unsuspend{" "}
                <b>&quot;{user.name}&quot;</b>?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose} color="primary">
                Cancel
              </Button>
              <Button onClick={handleUnsuspendUser} color="primary">
                Unsuspend
              </Button>
            </DialogActions>
          </>
        )}
      </Dialog>
    </>
  );
};

export default UnsuspendButton;

import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { Action } from "src/content/training/api/api";
import { Session } from "src/content/training/components/SessionManager/SessionManager";
import { DealChatMessage, loadNegotiation } from "src/slices/deal";
import { AppThunk } from "src/store";

import type { PayloadAction } from "@reduxjs/toolkit";

const API_PREFIX = `${process.env.REACT_APP_API_BASE_URL}api/training`;

export interface TrainingNegotiationSession {
  id: string;
  name: string;
}

export enum TrainingType {
  GENERAL = "GENERAL",
  NEGOTIATION = "NEGOTIATION",
}

interface TrainingNegotiationState {
  session: Session | null;
  actions: Action[];
  isLoadingActions: boolean;

  automatedNegotiationId: string | null;
  runId: string | null;
}

const initialState: TrainingNegotiationState = {
  session: null,
  actions: [],
  isLoadingActions: false,
  automatedNegotiationId: null,
  runId: null,
};
const slice = createSlice({
  name: "training-negotiation",
  initialState,
  reducers: {
    loadSession(state, action: PayloadAction<Session>) {
      return {
        ...state,
        session: action.payload,
        actions: [],
      };
    },
    loadTrainingNegotiationActions(state, action: PayloadAction<Action[]>) {
      return {
        ...state,
        actions: action.payload,
        runId:
          action && action.payload && action.payload.length > 0
            ? action.payload[0].runId
            : null,
      };
    },
    setLoadingActions(state, action: PayloadAction<boolean>) {
      return {
        ...state,
        isLoadingActions: action.payload,
      };
    },
    loadAutomatedNegotiationId(state, action: PayloadAction<string>) {
      return {
        ...state,
        automatedNegotiationId: action.payload,
      };
    },
    loadCurrentAgentRun(state, action: PayloadAction<string>) {
      return {
        ...state,
        runId: action.payload,
      };
    },
  },
});

export const getTrainingNegotiationSession =
  (sessionId: string): AppThunk =>
  async (dispatch) => {
    const controller = new AbortController();
    const config = {
      signal: controller.signal,
    };

    try {
      const response = await axios.get(
        `${API_PREFIX}/sessions/${sessionId}`,
        config,
      );
      const session: Session = response.data;
      dispatch(slice.actions.loadSession(session));
    } catch (error) {
      console.log(error);
      throw error;
    }

    controller.abort();
  };

export const getTrainingNegotiationActions =
  (negotiationId: string | null, messageId: string | null): AppThunk =>
  async (dispatch) => {
    const controller = new AbortController();
    const config = {
      signal: controller.signal,
    };

    if (negotiationId == null || messageId == null) {
      dispatch(slice.actions.loadTrainingNegotiationActions([]));
      return;
    }

    try {
      dispatch(slice.actions.setLoadingActions(true));
      const response = await axios.get(
        `${API_PREFIX}/negotiation/${negotiationId}/${messageId}/actions`,
        config,
      );
      const { actions }: { actions: Action[] } = response.data;

      dispatch(slice.actions.loadTrainingNegotiationActions(actions));
    } catch (error) {
      console.error(error);
      throw error;
    } finally {
      dispatch(slice.actions.setLoadingActions(false));
      controller.abort();
    }
  };

export const editAction =
  (action: Action): AppThunk =>
  async () => {
    try {
      const { sessionId, runId } = action;
      const url = `${API_PREFIX}/negotiation/${sessionId}/${runId}/actions/${action.actionId}`;
      const requestData = {
        action: {
          newResult: action.result,
        },
      };
      await axios.put(url, requestData);
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

export const createTrainingNegotiationSession = async (
  catalogueId: string,
  catalogueName: string,
  sessionInputs: Record<string, string | number>,
) => {
  try {
    const response = await axios.post(`${API_PREFIX}/sessions/negotiation`, {
      trainingCatalogueId: catalogueId,
      trainingCatalogueName: catalogueName,
      sessionInputs,
    });
    return response.data;
  } catch (error) {
    console.error(error);
    throw new Error("Failed to create new training negotiation session");
  }
};

export const getAutomatedNegotiationId =
  (sessionId: string | null): AppThunk =>
  async (dispatch) => {
    const controller = new AbortController();
    const config = {
      signal: controller.signal,
    };

    if (sessionId == null) {
      dispatch(slice.actions.loadAutomatedNegotiationId(null));
      return null;
    }

    try {
      const response = await axios.get(
        `${API_PREFIX}/negotiation/${sessionId}/automated_negotiation_id`,
        config,
      );
      const { automatedNegotiationId } = response.data;
      dispatch(
        slice.actions.loadAutomatedNegotiationId(automatedNegotiationId),
      );
      return automatedNegotiationId;
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

export const loadTrainingNegotiationSessionData =
  (sessionId: string): AppThunk =>
  async (dispatch, getState) => {
    if (sessionId && sessionId !== "") {
      await dispatch(getTrainingNegotiationSession(sessionId));
    }

    // Get automated negotiation ID of current training negotiation session
    // Load automated negotiation data associated with
    // current training negotiation session
    await dispatch(getAutomatedNegotiationId(sessionId));
    const { automatedNegotiationId } = getState().trainingNegotiation;
    if (automatedNegotiationId == null) return;
    await dispatch(loadNegotiation(automatedNegotiationId));

    // Get last user message ID which is agent run ID
    const { messages } = getState().deal;

    if (messages && messages.length > 0) {
      let lastMessage: DealChatMessage = messages[messages.length - 1];

      if (lastMessage.is_aani_message && messages.length >= 2) {
        lastMessage = messages[messages.length - 2];
      }
      dispatch(slice.actions.loadCurrentAgentRun(lastMessage.id));
    } else {
      dispatch(slice.actions.loadCurrentAgentRun(null));
    }

    const { runId } = getState().trainingNegotiation;

    // Load actions on page load
    if (runId !== null)
      await dispatch(
        getTrainingNegotiationActions(automatedNegotiationId, runId),
      );
    else await dispatch(slice.actions.loadTrainingNegotiationActions([]));
  };

export default slice;

import { featureIcons } from "src/layouts/aani/AccentHeaderLayout/featureIcons";
import { MenuSection } from "src/models/menu";

const { DealIcon, StorefrontIcon } = featureIcons();

const menuItems: MenuSection[] = [
  {
    items: [
      {
        name: "Negotiations",
        link: "/aani/negotiations/automated",
        icon: DealIcon,
        textColor: (theme) => theme.colors.primary.dark,
      },
      {
        name: "Suppliers",
        link: "/aani/suppliers",
        icon: StorefrontIcon,
        textColor: (theme) => theme.colors.primary.dark,
      },
    ],
  },
];

export default menuItems;

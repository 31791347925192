import React, { useState, useEffect, useRef } from "react";

import MicIcon from "@mui/icons-material/Mic";
import {
  IconButton,
  Box,
  Dialog,
  DialogContent,
  Typography,
  useTheme,
  Tooltip,
  CircularProgress,
  DialogTitle,
  Button,
  DialogActions,
} from "@mui/material";
import axios, { AxiosResponse } from "axios";
import { useSnackbarService } from "src/hooks/useSnackbarService";

import "./VoiceRecorder.css";
import LoadingDots from "../LoadingDots";

interface TranscribeAudioResponse {
  transcript: string;
}

interface VoiceRecorderProps {
  onTranscriptionComplete: (transcript: string) => void;
  tooltipTitle?: string;
  transcribeAudioEndpoint: string;
  isProcessingMessage?: boolean;
}

const VoiceRecorder: React.FC<VoiceRecorderProps> = ({
  onTranscriptionComplete,
  tooltipTitle,
  transcribeAudioEndpoint,
  isProcessingMessage,
}) => {
  const [recording, setRecording] = useState<boolean>(false);
  const [open, setOpen] = useState<boolean>(false);
  const [processing, setProcessing] = useState<boolean>(false);
  const mediaRecorderRef = useRef<MediaRecorder | null>(null);
  const streamRef = useRef<MediaStream | null>(null);
  const audioChunksRef = useRef<Blob[]>([]);
  const intentionalStopRef = useRef<boolean>(false);
  const theme = useTheme();
  const { showErrorSnackbar } = useSnackbarService();

  const countdownTime = 300; // 5 minutes in seconds
  const dialogWidth = 350;
  const [countdown, setCountdown] = useState<number>(countdownTime);

  const stopStreamTracks = () => {
    if (streamRef.current) {
      streamRef.current.getTracks().forEach((track) => track.stop());
      streamRef.current = null;
    }
  };

  const sendAudioToBackend = async (audioBlob: Blob) => {
    const formData = new FormData();
    formData.append("audio", audioBlob, "audio.mp3");

    try {
      setProcessing(true);
      const response: AxiosResponse<TranscribeAudioResponse> = await axios.post(
        transcribeAudioEndpoint,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        },
      );
      const { transcript } = response.data;
      onTranscriptionComplete(transcript);
      setOpen(false);
    } catch (error) {
      showErrorSnackbar(`Transcribing failed! Please try again later.`);
    } finally {
      setProcessing(false);
    }
  };

  const stopRecording = () => {
    if (
      mediaRecorderRef.current &&
      mediaRecorderRef.current.state !== "inactive"
    ) {
      mediaRecorderRef.current.stop();
    }
    setRecording(false);
  };

  const startRecording = () => {
    navigator.mediaDevices
      .getUserMedia({ audio: true })
      .then((stream) => {
        streamRef.current = stream;
        mediaRecorderRef.current = new MediaRecorder(stream);
        mediaRecorderRef.current.ondataavailable = (event) => {
          if (event.data.size > 0) {
            audioChunksRef.current.push(event.data);
          }
        };
        mediaRecorderRef.current.onstop = () => {
          if (intentionalStopRef.current) {
            const audioBlob = new Blob(audioChunksRef.current, {
              type: "audio/mp3",
            });
            audioChunksRef.current = [];
            sendAudioToBackend(audioBlob);
          }
          audioChunksRef.current = [];
          stopStreamTracks();
        };
        mediaRecorderRef.current.start();
        setOpen(true);
      })
      .catch((error) => console.error("Error accessing microphone", error));
  };

  const handleClose = () => {
    intentionalStopRef.current = false;
    setRecording(false);
    setOpen(false);
    stopStreamTracks();
  };

  const handleFinishRecording = () => {
    intentionalStopRef.current = true;
    if (recording) {
      stopRecording();
    }
  };

  useEffect(() => {
    if (recording) {
      startRecording();
    } else {
      stopRecording();
    }
  }, [recording]);

  // Countdown timer
  useEffect(() => {
    if (open) {
      setCountdown(countdownTime);
      const interval = setInterval(() => {
        setCountdown((prev) => {
          if (prev <= 0) {
            intentionalStopRef.current = true;
            if (recording) {
              stopRecording();
            }
          }
          return prev - 1;
        });
      }, 1000);

      return () => clearInterval(interval);
    }
    return () => {};
  }, [open]);

  return (
    <Box>
      <Tooltip title={tooltipTitle} placement="left">
        <IconButton
          color="primary"
          onClick={() => setRecording(!recording)}
          disabled={processing || isProcessingMessage}
          size="medium"
          sx={{
            padding: 1,
          }}
          disableTouchRipple
        >
          <MicIcon fontSize="inherit" />
        </IconButton>
      </Tooltip>

      <Dialog open={open} onClose={handleClose}>
        {/* Recording Dialog */}
        {recording && !processing && (
          <>
            <DialogTitle>
              <Typography variant="subtitle1" textAlign="center">
                Speak now, I am listening...
              </Typography>
            </DialogTitle>

            <DialogContent
              dividers
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "flex-start",
                minWidth: dialogWidth,
                position: "relative",
                gap: 2,
              }}
            >
              {/* Timer */}
              <Box>
                <Box position="relative" display="inline-flex">
                  <CircularProgress
                    variant="determinate"
                    value={(countdown / 300) * 100}
                    size={175}
                    thickness={3}
                  />
                  <Box
                    top={0}
                    left={0}
                    bottom={0}
                    right={0}
                    position="absolute"
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <Typography variant="h4">
                      {`${Math.floor(countdown / 60)}:${(countdown % 60)
                        .toString()
                        .padStart(2, "0")}`}
                    </Typography>
                  </Box>
                </Box>
              </Box>
              {/* Mic Icon */}
              <Box
                sx={{
                  position: "relative",
                  display: "flex",
                  justifyContent: "center",
                  width: "100%",
                  marginY: 2,
                }}
              >
                <Box
                  className="pulsing-circle"
                  sx={{
                    position: "absolute",
                    bottom: -15,
                    backgroundColor: theme.colors.secondary.light,
                  }}
                />
                <IconButton
                  sx={{
                    backgroundColor: theme.colors.secondary.main,
                    color: "white",
                    "&:hover": {
                      backgroundColor: theme.colors.secondary.main,
                      cursor: "default",
                    },
                    borderRadius: "50%",
                    padding: 2,
                  }}
                  size="large"
                  disabled={processing}
                >
                  <MicIcon sx={{ fontSize: 28 }} />
                </IconButton>
              </Box>
            </DialogContent>

            <DialogActions
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                width: "100%",
              }}
            >
              <Button
                onClick={handleClose}
                color="error"
                sx={{ width: "100%", borderRadius: theme.general.borderRadius }}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                color="secondary"
                sx={{ width: "100%", borderRadius: theme.general.borderRadius }}
                onClick={handleFinishRecording}
              >
                Finish
              </Button>
            </DialogActions>
          </>
        )}

        {/* Processing Dialog */}
        {processing && (
          <>
            <DialogTitle>
              <Typography variant="subtitle1" textAlign="center">
                Processing audio...
              </Typography>
            </DialogTitle>
            <DialogContent
              dividers
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                gap: 2,
                minWidth: dialogWidth,
              }}
            >
              <LoadingDots />
            </DialogContent>
            <DialogActions
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Typography variant="subtitle2" color="secondary">
                Please wait
              </Typography>
            </DialogActions>
          </>
        )}
      </Dialog>
    </Box>
  );
};

VoiceRecorder.defaultProps = {
  tooltipTitle: "Speak my summary",
  isProcessingMessage: false,
};

export default VoiceRecorder;

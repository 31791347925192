export interface SessionChatMessage {
  id?: string;
  msg: string;
  date: string;
  isAIMessage: boolean;
  isRated: boolean;
  replyToUserMessageId?: string;
  metadata?: Record<string, string>;
}

export enum SessionChatRunStatus {
  THINKING = "THINKING",
  COMPLETED = "COMPLETED",
  ERROR = "ERROR",
  CANCELLED = "CANCELLED",
}

export enum SessionEventStatus {
  SUGGESTED = "SUGGESTED",
  CONFIRMED = "CONFIRMED",
}

export type SessionEvent = {
  id?: string;
  title: string;
  details: string;
  status: SessionEventStatus;
  date?: string; // Date format "YYYY-MM-DD"
};

export enum SessionMode {
  NORMAL = "Normal",
  TRAINING = "Training",
}

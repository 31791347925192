import React, { useState } from "react";

import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { IconButton, Tooltip } from "@mui/material";

import ClusterInformationDrawer from "./ClusterInformationDrawer";

const ClusterInformationButton = () => {
  const [showClusterInformation, setShowClusterInformation] = useState(false);

  return (
    <>
      <Tooltip title="Cluster Information">
        <IconButton
          color="primary"
          onClick={() => setShowClusterInformation(true)}
        >
          <InfoOutlinedIcon />
        </IconButton>
      </Tooltip>
      <ClusterInformationDrawer
        showClusterInformation={showClusterInformation}
        setShowClusterInformation={setShowClusterInformation}
      />
    </>
  );
};

export default ClusterInformationButton;

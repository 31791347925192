import React from "react";

import {
  Box,
  Grid,
  LinearProgress,
  LinearProgressProps,
  Typography,
  useTheme,
} from "@mui/material";
import { ClientSession } from "src/slices/mia/mia";

function LinearProgressWithLabel(
  props: LinearProgressProps & { value: number },
) {
  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <Box sx={{ minWidth: 35 }}>
        <Typography variant="body2">{`${Math.round(props.value)}%`}</Typography>
      </Box>
      <Box sx={{ width: "100%" }}>
        <LinearProgress color="secondary" variant="determinate" {...props} />
      </Box>
    </Box>
  );
}

const ProgressIndicator = ({ session }: { session: ClientSession }) => {
  const theme = useTheme();

  return (
    <Grid
      container
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      borderRadius={theme.general.borderRadius}
      bgcolor={theme.colors.primary.dark}
      color="white"
      paddingX={1.5}
      paddingY={1}
      minWidth={250}
    >
      <Grid
        item
        container
        justifyContent="space-between"
        alignItems="center"
        xs={12}
      >
        <Typography variant="body2">Intake</Typography>
        <Typography variant="body2">Ready to help</Typography>
      </Grid>
      <Grid item xs={12}>
        <LinearProgressWithLabel value={session.intakeProgressPerc ?? 0} />
      </Grid>
    </Grid>
  );
};

export default ProgressIndicator;

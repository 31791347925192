import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import { Box, Drawer, IconButton, Typography } from "@mui/material";

import Chart from "./Chart";

const ChartDrawer = ({
  showChart,
  setShowChart,
}: {
  showChart: boolean;
  setShowChart: (show: boolean) => void;
}) => (
  <Drawer
    variant="temporary"
    anchor="right"
    open={showChart}
    onClose={() => setShowChart(false)}
    ModalProps={{
      keepMounted: true,
    }}
    sx={{
      display: { xs: "block", md: "none" },
      "& .MuiDrawer-paper": {
        height: "100%",
        width: "100%",
        maxWidth: "600px",
      },
    }}
  >
    <IconButton
      color="primary"
      onClick={() => setShowChart(false)}
      sx={{
        position: "absolute",
        right: 12,
        top: 12,
      }}
    >
      <CloseOutlinedIcon />
    </IconButton>
    <Box
      height="100%"
      padding={2}
      display="flex"
      flexDirection="column"
      gap={2}
    >
      <Typography variant="h5" textAlign="center">
        Generated Chart
      </Typography>
      <Chart />
    </Box>
  </Drawer>
);

export default ChartDrawer;

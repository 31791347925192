import { featureIcons } from "../../featureIcons";

const { UsersIcon, BillingIcon } = featureIcons();

const menuItems = (theme, path) => [
  {
    heading: "Settings",
    items: [
      {
        name: "Users",
        link: "/settings/users",
        active: path === "/settings/users",
        icon: UsersIcon,
        textColor: theme.colors.features.edge,
        badge: null,
        badgeTooltip: null,
        isChild: false,
      },
      {
        name: "Billing",
        link: "/settings/billing",
        active: path === "/settings/billing",
        icon: BillingIcon,
        textColor: theme.colors.features.edge,
        badge: null,
        badgeTooltip: null,
        isChild: false,
      },
    ],
  },
];

const getMenuItemsForUser = (user, theme, path) => menuItems(theme, path);

export default getMenuItemsForUser;

import { useRef, useState } from "react";

import LockOpenTwoToneIcon from "@mui/icons-material/LockOpenTwoTone";
import SettingsIcon from "@mui/icons-material/Settings";
import {
  Avatar,
  Box,
  Divider,
  IconButton,
  MenuItem,
  Popover,
  Typography,
  styled,
  useTheme,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import useAuth from "src/hooks/useAuth";

const StyledMenuItem = styled(MenuItem)(
  ({ theme }) => `
        color: ${theme.colors.alpha.black[70]};
        display: flex;
        gap: ${theme.spacing(1)};
`,
);

const MenuItemLink = styled(Link)(
  ({ theme }) => `
        color: ${theme.palette.text.primary};
        text-decoration: none;
`,
);

function HeaderUserbox() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const theme = useTheme();

  const { user, logout } = useAuth();

  const ref = useRef(null);
  const [isOpen, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleLogout = async () => {
    try {
      handleClose();
      await logout();
      navigate("/");
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <Box
      component="span"
      sx={{
        display: { xs: "none", sm: "inline-block" },
      }}
    >
      <IconButton
        color="secondary"
        ref={ref}
        onClick={handleOpen}
        sx={{
          padding: 0.5,
          borderRadius: "50%",
          "&:hover": {
            backgroundColor: theme.colors.alpha.black[10],
          },
        }}
      >
        <Avatar
          alt={user.name}
          src={user.avatar}
          sx={{ width: 36, height: 36, bgcolor: theme.palette.primary.dark }}
        />
      </IconButton>
      <Popover
        disableScrollLock
        anchorEl={ref.current}
        onClose={handleClose}
        open={isOpen}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <Box display="flex" flexDirection="column" margin={2}>
          <Box
            display="flex"
            gap={1}
            sx={{
              minWidth: 200,
            }}
          >
            <Avatar variant="circular" alt={user.name} src={user.avatar} />
            <Typography variant="body1">{user.name}</Typography>
          </Box>
        </Box>
        {user.isAccountAdmin && (
          <>
            <Divider />
            <Box padding={1}>
              <MenuItemLink to="/settings">
                <StyledMenuItem onClick={handleClose}>
                  <SettingsIcon />
                  {t("Settings")}
                </StyledMenuItem>
              </MenuItemLink>
            </Box>
          </>
        )}
        <Divider />
        <Box padding={1}>
          <MenuItemLink to="/logout">
            <StyledMenuItem onClick={handleLogout}>
              <LockOpenTwoToneIcon />
              {t("Sign out")}
            </StyledMenuItem>
          </MenuItemLink>
        </Box>
      </Popover>
    </Box>
  );
}

export default HeaderUserbox;

import React, { memo } from "react";

import { keyframes } from "@emotion/react";
import { Box, styled, useTheme } from "@mui/material";

const LoadingDots = () => {
  const theme = useTheme();

  const toggleAnimation = keyframes`
    0% { background-color: transparent; }
    50% { background-color: transparent; }
    100% { background-color: ${theme.colors.primary.main}; }
  `;

  const Dot = styled(Box)(() => ({
    width: theme.spacing(1),
    height: theme.spacing(1),
    borderRadius: "50%",
    display: "inline-block",
    border: `2px solid ${theme.colors.primary.main}`,
    animation: `${toggleAnimation} 0.75s infinite linear`,
    backgroundColor: "transparent",
    "&:nth-of-type(1)": {
      animationDelay: "0s",
    },
    "&:nth-of-type(2)": {
      animationDelay: "0.25s",
    },
    "&:nth-of-type(3)": {
      animationDelay: "0.5s",
    },
  }));

  return (
    <Box
      display="inline-flex"
      paddingX={2}
      paddingY={1.5}
      gap={1.25}
      bgcolor={theme.colors.primary.lighter}
      borderRadius={theme.general.borderRadiusLg}
    >
      <Dot />
      <Dot />
      <Dot />
    </Box>
  );
};

// Memoize the component to avoid unnecessary re-renders
export default memo(LoadingDots);

import React, { useEffect, useState } from "react";

import {
  Card,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableContainer,
  TableRow,
  Typography,
  CircularProgress,
  Box,
  TablePagination,
  TableSortLabel,
} from "@mui/material";
import { format } from "date-fns";
import { useTranslation } from "react-i18next";
import { AccountUser, UserStatus, getUsers } from "src/slices/settings/user";
import { useDispatch, useSelector } from "src/store";

import CancelInviteButton from "./UserTable/CancelInviteButton";
import ManageAccessButton from "./UserTable/ManageAccessButton";
import ManageRolesButton from "./UserTable/ManageRolesButton";
import ReinviteUserButton from "./UserTable/ReinviteUserButton";
import StatusLabel from "./UserTable/StatusLabel";
import SuspendButton from "./UserTable/SuspendButton";
import UnsuspendButton from "./UserTable/UnsuspendButton";

interface Filters {
  role: string | null;
}

const CustomTypography = ({ children }: { children: string }) => (
  <Typography variant="body1" sx={{ fontSize: "13px" }}>
    {children}
  </Typography>
);

const applyFilters = (users: AccountUser[], query: string, filters: Filters) =>
  users.filter((user) => {
    let matches = true;

    if (query) {
      const properties = ["email", "name", "username"];
      let containsQuery = false;

      properties.forEach((property) => {
        if (user[property].toLowerCase().includes(query.toLowerCase())) {
          containsQuery = true;
        }
      });

      if (!containsQuery) {
        matches = false;
      }
    }

    Object.keys(filters).forEach((key) => {
      const value = filters[key];

      if (value && user[key] !== value) {
        matches = false;
      }
    });

    return matches;
  });

const descendingComparator = (
  a: AccountUser,
  b: AccountUser,
  orderBy: keyof AccountUser,
) => {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
};

const getComparator = <Key extends keyof AccountUser>(
  order: "asc" | "desc",
  orderBy: Key,
): ((a: AccountUser, b: AccountUser) => number) =>
  order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);

const applySorting = (
  users: AccountUser[],
  order: "asc" | "desc",
  orderBy: keyof AccountUser,
) => [...users].sort(getComparator(order, orderBy));

const applyPagination = (users: AccountUser[], page: number, limit: number) =>
  users.slice(page * limit, page * limit + limit);

const UserTable: React.FC = () => {
  const { users, loading } = useSelector((state) => state.userSettings);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [selectedItems] = useState<string[]>([]);
  const [page, setPage] = useState<number>(0);
  const [limit, setLimit] = useState<number>(20);
  const [order, setOrder] = useState<"asc" | "desc">("asc");
  const [orderBy, setOrderBy] = useState<keyof AccountUser>("name");
  const [query] = useState<string>("");
  const [filters] = useState<Filters>({
    role: null,
  });

  const filteredUsers = applyFilters(users, query, filters);
  const sortedUsers = applySorting(filteredUsers, order, orderBy);
  const paginatedUsers = applyPagination(sortedUsers, page, limit);

  useEffect(() => {
    dispatch(getUsers());
  }, []);

  const handleInviteCanceled = () => {
    dispatch(getUsers());
  };

  const handlePageChange = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleLimitChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setLimit(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof AccountUser,
  ) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  return (
    <Card>
      <Divider />
      {loading && (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          padding={8}
        >
          <CircularProgress size={80} />
        </Box>
      )}
      {!loading && paginatedUsers.length === 0 && (
        <Typography
          sx={{ p: 10 }}
          variant="h3"
          fontWeight="normal"
          color="text.secondary"
          align="center"
        >
          {t("We couldn't find any users matching your search criteria")}
        </Typography>
      )}
      {!loading && paginatedUsers.length > 0 && (
        <>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>
                    <TableSortLabel
                      active={orderBy === "name"}
                      direction={orderBy === "name" ? order : "asc"}
                      onClick={(event) => handleRequestSort(event, "name")}
                    >
                      {t("Name")}
                    </TableSortLabel>
                  </TableCell>
                  <TableCell>{t("Email")}</TableCell>
                  <TableCell>{t("Status")}</TableCell>
                  <TableCell>{t("Created On")}</TableCell>
                  <TableCell>{t("Invitation Expires")}</TableCell>
                  <TableCell>{t("Actions")}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {paginatedUsers.map((user) => {
                  const isUserSelected = selectedItems.includes(user.id);
                  const isActiveUser = user.status === UserStatus.ACTIVE;
                  const isSuspendedUser = user.status === UserStatus.SUSPENDED;
                  const isInvitedUser = user.status === UserStatus.INVITED;
                  return (
                    <TableRow hover key={user.id} selected={isUserSelected}>
                      <TableCell>
                        <Typography variant="h5">{user.name}</Typography>
                      </TableCell>
                      <TableCell>
                        <CustomTypography>{user.email}</CustomTypography>
                      </TableCell>
                      <TableCell>
                        <StatusLabel status={user.status} />
                      </TableCell>
                      <TableCell>
                        <CustomTypography>
                          {format(new Date(user.createdOn), "MMM dd, yyyy")}
                        </CustomTypography>
                      </TableCell>
                      <TableCell>
                        <CustomTypography>
                          {isInvitedUser && user.invitationExpiresOn
                            ? format(
                                new Date(user.invitationExpiresOn),
                                "MMM dd, yyyy",
                              )
                            : ""}
                        </CustomTypography>
                      </TableCell>
                      <TableCell>
                        {isActiveUser && (
                          <>
                            <ManageRolesButton user={user} />
                            <ManageAccessButton user={user} />
                            <SuspendButton user={user} />
                          </>
                        )}
                        {isSuspendedUser && (
                          <>
                            <UnsuspendButton user={user} />
                          </>
                        )}
                        {isInvitedUser && (
                          <>
                            <ReinviteUserButton user={user} />
                            <CancelInviteButton
                              user={user}
                              onInviteCanceled={handleInviteCanceled}
                            />
                          </>
                        )}
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
          <Box p={2}>
            <TablePagination
              component="div"
              count={filteredUsers.length}
              onPageChange={handlePageChange}
              onRowsPerPageChange={handleLimitChange}
              page={page}
              rowsPerPage={limit}
              rowsPerPageOptions={[5, 20, 30]}
            />
          </Box>
        </>
      )}
    </Card>
  );
};

export default UserTable;

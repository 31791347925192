import PaymentsIcon from "@mui/icons-material/Payments";
import PeopleIcon from "@mui/icons-material/People";
import { styled } from "@mui/material";

export function featureIcons() {
  const UsersIcon = styled(PeopleIcon)(
    ({ theme }) => `
        color: ${theme.colors.primary.main};
`,
  );

  const BillingIcon = styled(PaymentsIcon)(
    ({ theme }) => `
        color: ${theme.colors.primary.main};
`,
  );

  return {
    UsersIcon,
    BillingIcon,
  };
}

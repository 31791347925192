export enum ResponseType {
  TEXT = "TEXT",
  THINKING = "THINKING",
  CANCELLED = "CANCELLED",
  ERROR = "ERROR",
}

export interface IsSuccessResponse {
  isSuccess: boolean;
  message: string;
}

import { Grid, Typography } from "@mui/material";

const PageHeader = () => (
  <>
    <Grid container justifyContent="space-between" alignItems="center">
      <Grid item>
        <Typography variant="h3" component="h3" gutterBottom>
          Billing Management
        </Typography>
        <Typography variant="subtitle2">
          All aspects related to the organisation billing details can be managed
          from this page.
        </Typography>
      </Grid>
    </Grid>
  </>
);

export default PageHeader;

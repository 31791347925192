import { ReactNode } from "react";

import { Box, useMediaQuery, useTheme } from "@mui/material";
import Scrollbars from "react-custom-scrollbars-2";
import { Outlet } from "react-router-dom";
import { useUser } from "src/contexts/UserContext";
import UkoHeader from "src/layouts/shared/Header/UkoHeader";
import { IHeaderMenu } from "src/models/menu";

import headerMenu from "../Header/HeaderMenu/items";

const ModuleLayout = ({
  SidebarComponent,
}: {
  SidebarComponent?: ReactNode;
}) => {
  const { userModules } = useUser();
  const theme = useTheme();
  const ukoHeaderHeight: string = theme.header.height;
  const sidebarWidth: string = theme.sidebar.width;
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("lg"));

  // Filter menu items based on user module access
  const filteredMenuItems = headerMenu.items.filter(
    (item) => userModules[item.name] && userModules[item.name].length > 0,
  );

  const updatedMenu: IHeaderMenu = {
    items: filteredMenuItems,
  };

  return (
    <Box sx={{ height: "100vh" }} display="flex" flexDirection="column">
      {/* UkoHeader takes the whole space on top */}
      <Box
        sx={{
          position: "sticky",
          top: 0,
          zIndex: 1,
          height: ukoHeaderHeight,
        }}
      >
        <UkoHeader
          height={ukoHeaderHeight}
          menuItems={updatedMenu}
          showSidebarToggle={SidebarComponent != null}
        />
      </Box>

      {/* Page Content */}
      <Box
        sx={{
          display: "flex",
          width: "100%",
          height: `calc(100vh - ${ukoHeaderHeight})`,
        }}
      >
        {/* Sidebar takes the left */}
        {SidebarComponent && (
          <Box
            sx={{
              width: isSmallScreen ? 0 : sidebarWidth,
              position: "sticky",
              top: ukoHeaderHeight,
              transition: "width 0.3s ease",
            }}
          >
            {SidebarComponent}
          </Box>
        )}

        {/* Outlet takes the remaining space on the right */}
        <Box flex={1} sx={{ overflow: "hidden", position: "relative" }}>
          <Scrollbars
            autoHide
            renderThumbVertical={() => (
              <Box
                sx={{
                  width: 10,
                  background: `${theme.palette.primary.dark}`,
                  "&:hover": {
                    background: `${theme.palette.primary.dark}`,
                  },
                }}
              />
            )}
            style={{
              height: `calc(100vh - ${ukoHeaderHeight})`,
            }}
          >
            <Box paddingX={2}>
              <Outlet />
            </Box>
          </Scrollbars>
        </Box>
      </Box>
    </Box>
  );
};

ModuleLayout.defaultProps = {
  SidebarComponent: null,
};

export default ModuleLayout;

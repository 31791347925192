import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { Supplier } from "src/models/supplier";
import { AppThunk } from "src/store";

import type { PayloadAction } from "@reduxjs/toolkit";

interface SupplierState {
  suppliers: Supplier[];
  totalSupplierCount: number;
}

const initialState: SupplierState = {
  suppliers: [],
  totalSupplierCount: 0,
};

const slice = createSlice({
  name: "aani-suppliers",
  initialState,
  reducers: {
    loadSupplier(state, suppliers: PayloadAction<Supplier[]>) {
      return {
        ...state,
        suppliers: suppliers.payload,
      };
    },
    loadTotalSupplierCount(state, suppliers: PayloadAction<number>) {
      return {
        ...state,
        totalSupplierCount: suppliers.payload,
      };
    },
  },
});

export const loadSuppliersApi =
  (query: string, page: number, limit: number): AppThunk =>
  async (dispatch) => {
    const response = await axios({
      headers: {
        "Content-Type": "application/json",
        "Cache-Control": "no-cache",
      },
      method: "GET",
      url: `${process.env.REACT_APP_API_BASE_URL}api/aani/suppliers`,
      params: {
        page: page + 1,
        page_size: limit,
        query,
      },
    });
    const suppliers = response.data.suppliers as Supplier[];
    const totalSupplierCount = response.data.totalSupplierCount as number;
    dispatch(slice.actions.loadSupplier(suppliers));
    dispatch(slice.actions.loadTotalSupplierCount(totalSupplierCount));
  };

export const deleteSupplier = async (deleteId: string) => {
  await axios({
    headers: {
      "Content-Type": "application/json",
      "Cache-Control": "no-cache",
    },
    method: "POST",
    url: `${process.env.REACT_APP_API_BASE_URL}api/aani/suppliers/delete/${deleteId}`,
  });
};

export default slice;

import { useEffect, useState } from "react";

import { Alert, Box, CircularProgress, Grid, styled } from "@mui/material";
import { Outlet, useNavigate } from "react-router-dom";
import { useUser } from "src/contexts/UserContext";
import headerMenu from "src/layouts/shared/Header/HeaderMenu/items";

const CardWrapper = styled(Box)(
  () => `
  `,
);

function AppSelection() {
  const { userModules, loading } = useUser();
  const navigate = useNavigate();
  const [showNoAppSelection, setNoShowAppSelection] = useState(false);

  useEffect(() => {
    if (!loading) {
      const moduleNames = Object.keys(userModules);
      if (moduleNames.length > 0) {
        const firstModule = moduleNames[0];
        const moduleItem = headerMenu.items.find(
          (item) => item.name === firstModule,
        );
        if (moduleItem) {
          navigate(moduleItem.link);
        } else {
          setNoShowAppSelection(true);
        }
      } else {
        setNoShowAppSelection(true);
      }
    }
  }, [userModules, navigate, loading]);

  if (loading) {
    return (
      <Box
        sx={{
          margin: "auto",
        }}
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box
      sx={{
        margin: "auto",
      }}
      display="flex"
      alignItems="center"
      justifyContent="center"
    >
      {showNoAppSelection && (
        <CardWrapper p={2}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Alert severity="error">
                You do not have sufficient access to any application. Please
                contact your administrator to request access.
              </Alert>
            </Grid>
          </Grid>
        </CardWrapper>
      )}
      {!showNoAppSelection && <CircularProgress />}
      <Outlet />
    </Box>
  );
}

export default AppSelection;

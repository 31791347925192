import { useState } from "react";

import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { Box, Typography, Menu, MenuItem, Button } from "@mui/material";
import { loadKnowledgeBank, KnowledgeBank } from "src/slices/insight/insight";
import { useDispatch, useSelector } from "src/store";

const KnowledgeBankMenu = () => {
  const dispatch = useDispatch();

  // State for controlling menu anchor and Knowledge Bank selection
  const [anchorEl, setAnchorEl] = useState(null);
  const { selectedKnowledgeBank, knowledgeBanks } = useSelector(
    (state) => state.insight,
  );

  // Open or close the Knowledge Bank selection menu
  const handleClick = () => {
    setAnchorEl(
      anchorEl ? null : document.getElementById("kb-selection-menu-button"),
    );
  };

  // Handle Knowledge Bank selection
  const handleKBMenuClick = (bank: KnowledgeBank) => {
    setAnchorEl(null);
    dispatch(loadKnowledgeBank(bank.id));
  };

  // Close the Knowledge Bank selection menu
  const handleKBMenuClose = () => {
    setAnchorEl(null);
  };

  if (knowledgeBanks?.length === 0) {
    return <></>;
  }

  return (
    <Box>
      {/* Knowledge Bank Selection Button */}
      {knowledgeBanks?.length > 0 && selectedKnowledgeBank && (
        <Button
          id="kb-selection-menu-button"
          aria-haspopup="true"
          onClick={handleClick}
          variant="text"
          endIcon={<KeyboardArrowDownIcon />}
          size="small"
          sx={{
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          {selectedKnowledgeBank?.name.length > 20
            ? `${selectedKnowledgeBank?.name.slice(0, 20)}...`
            : selectedKnowledgeBank?.name}
        </Button>
      )}

      {/* Knowledge Bank Selection Menu */}
      {knowledgeBanks?.length > 0 && (
        <Menu
          id="header-menu"
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleKBMenuClose}
          MenuListProps={{
            "aria-labelledby": "kb-selection-menu-button",
          }}
        >
          {knowledgeBanks.map((bank) => (
            <MenuItem key={bank.name} onClick={() => handleKBMenuClick(bank)}>
              <Typography>{bank.name}</Typography>
            </MenuItem>
          ))}
        </Menu>
      )}
    </Box>
  );
};

export default KnowledgeBankMenu;

import { ReactNode, createContext, useEffect, useReducer } from "react";

import axios from "axios";

export interface AuthUser {
  id: string;
  avatar: string;
  email: string;
  name: string;
  roles: string[];
  isAccountAdmin: boolean;
}

export interface AuthState {
  isAuthenticated: boolean;
  isInitialized: boolean;
  showSessionExpiredModal: boolean;
  user: AuthUser | null;
}

type AuthAction = {
  type: string;
  payload?: {
    isAuthenticated: boolean;
    user?: AuthUser;
  };
};

const initialAuthState: AuthState = {
  isAuthenticated: false,
  isInitialized: false,
  showSessionExpiredModal: false,
  user: null,
};

const handlers: Record<
  string,
  (state: AuthState, action: AuthAction) => AuthState
> = {
  INITIALIZE: (state, action) => {
    const { isAuthenticated, user } = action.payload;
    return {
      ...state,
      isAuthenticated,
      isInitialized: true,
      user,
    };
  },
  LOGOUT: (state) => ({
    ...state,
    isAuthenticated: false,
    user: null,
  }),
  SHOW_SESSION_EXPIRED_MODAL: (state) => ({
    ...state,
    isAuthenticated: false,
    showSessionExpiredModal: true,
    user: null,
  }),
};

const reducer = (state: AuthState, action: AuthAction): AuthState =>
  handlers[action.type] ? handlers[action.type](state, action) : state;

type AuthContextType = AuthState & {
  method: string;
  login: () => void;
  logout: () => void;
};

const AuthContext = createContext<AuthContextType>({
  ...initialAuthState,
  method: "Auth0",
  login: async () => {},
  logout: () => {},
});

export const AuthProvider = ({ children }: { children: ReactNode }) => {
  const [state, dispatch] = useReducer(reducer, initialAuthState);

  const initialize = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}auth/status`,
      );
      const { authenticated, user } = response.data;

      if (authenticated) {
        dispatch({
          type: "INITIALIZE",
          payload: {
            isAuthenticated: true,
            user: {
              id: user.sub,
              avatar: user.picture,
              email: user.email,
              name: user.name,
              roles: user.roles,
              isAccountAdmin: user.roles.includes("Account Admin"),
            },
          },
        });
      } else {
        dispatch({
          type: "INITIALIZE",
          payload: { isAuthenticated: false, user: null },
        });
      }
    } catch (error) {
      console.error("Failed to initialize authentication:", error);
      dispatch({
        type: "INITIALIZE",
        payload: { isAuthenticated: false, user: null },
      });
    }
  };

  useEffect(() => {
    initialize();
  }, []);

  const login = () => {
    window.location.href = `${process.env.REACT_APP_API_BASE_URL}login`;
  };

  const logout = () => {
    window.location.href = `${process.env.REACT_APP_API_BASE_URL}logout`;
    dispatch({
      type: "LOGOUT",
    });
  };

  const checkSession = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}auth/status`,
      );
      const currentPath = window.location.pathname;
      if (!response.data.authenticated && currentPath !== "/") {
        dispatch({
          type: "SHOW_SESSION_EXPIRED_MODAL",
        });
      }
    } catch (error) {
      console.error("Failed to check session status:", error);
    }
  };

  useEffect(() => {
    initialize();
    const interval = setInterval(checkSession, 10000);
    return () => clearInterval(interval);
  }, []);

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: "Auth0",
        login,
        logout,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;

import React, { useState } from "react";

import { LoadingButton } from "@mui/lab";
import { Dialog, Box, Typography } from "@mui/material";

const SessionExpiredModal = ({
  open,
  login,
}: {
  open: boolean;
  login: () => void;
}) => {
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleLogin = async () => {
    try {
      setIsSubmitting(true);
      await login();
    } catch (err) {
      setIsSubmitting(false);
      console.error(err);
    }
  };

  return (
    <Dialog open={open} onClose={() => {}} maxWidth="sm">
      <Box textAlign="center" sx={{ paddingX: 8, paddingY: 4 }}>
        <Typography variant="h6" mb={2}>
          Session Expired
        </Typography>
        <Box mb={4}>
          <Typography mb={1}>Your session has expired</Typography>
          <Typography>Please sign in again to continue.</Typography>
        </Box>
        <LoadingButton
          loading={isSubmitting}
          onClick={handleLogin}
          variant="contained"
          color="primary"
          size="medium"
        >
          Sign in
        </LoadingButton>
      </Box>
    </Dialog>
  );
};

export default SessionExpiredModal;

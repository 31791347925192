import { useState } from "react";

import { Helmet } from "react-helmet-async";
import { Outlet } from "react-router-dom";
import ModuleMenu from "src/layouts/shared/ModuleMenu";

import IlaNavigationBar from "./NavigationBar";

import "./index.css";

const IlaLayout = () => {
  const [moduleMenuOpen, setModuleMenuOpen] = useState(false);

  const toggleDrawer = () => {
    setModuleMenuOpen(!moduleMenuOpen);
  };

  return (
    <>
      <Helmet title="ILA" />
      <div className={`container ${moduleMenuOpen ? "drawer-open" : ""}`}>
        {/* Module Menu */}
        <aside className={`sidenav ${moduleMenuOpen ? "open" : ""}`}>
          <ModuleMenu />
        </aside>

        {/* Main content */}
        <div id="main">
          <header>
            <IlaNavigationBar toggleDrawer={toggleDrawer} />
          </header>
          <section>
            <Outlet />
          </section>
        </div>
      </div>
    </>
  );
};

export default IlaLayout;

import { Box, Switch, FormControlLabel } from "@mui/material";
import { useDispatch } from "react-redux";
import slice from "src/slices/ila/ila";
import { SessionMode } from "src/slices/ila/model";
import { useSelector } from "src/store";

const ModeSwitch = () => {
  const dispatch = useDispatch();
  const { isProcessingMessage } = useSelector((state) => state.ila);

  const handleToggleMode = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newMode = event.target.checked
      ? SessionMode.TRAINING
      : SessionMode.NORMAL;
    dispatch(slice.actions.setSessionMode(newMode));
  };

  return (
    <Box display="flex" alignItems="center" gap={2}>
      <FormControlLabel
        control={
          <Switch
            defaultChecked
            disabled={isProcessingMessage}
            onChange={handleToggleMode}
            name="modeSwitch"
            color="secondary"
          />
        }
        label="Training"
      />
    </Box>
  );
};

export default ModeSwitch;

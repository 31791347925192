import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { ChatMessage } from "src/models/message";

interface TrainingState {
    messages: ChatMessage[];
    appName: string;
    prompt: string;
    isSubmittingCorrection: boolean;
    isCorrectionSubmitted: boolean;
    extraMessages: ChatMessage[];
    isRegeneratingResponse: boolean;
    isEditContextDialogOpen: boolean;
}

const initialState: TrainingState = {
    messages: [],
    appName: '',
    prompt: '',
    isSubmittingCorrection: false,
    isCorrectionSubmitted: false,
    extraMessages: [],
    isRegeneratingResponse: false,
    isEditContextDialogOpen: false,
};

export const getTrainingApiUrl = (appName: string) => `${process.env.REACT_APP_API_BASE_URL}api/${appName.toLowerCase()}/training`;
const getApiName = (thunkApi: any) => {
    return (thunkApi.getState().training as TrainingState).appName;
};

export const loadTrainingConversationApi = createAsyncThunk(
    '/training/conversation',
    async (user: any, thunkApi) => {
        const response = await axios({
            method: 'POST',
            url: `${getTrainingApiUrl(getApiName(thunkApi))}/conversation`,
            data: { user },
        });

        return response.data;
    }
);

const submitTrainingChatApi = createAsyncThunk(
    '/training/chat',
    async (message: ChatMessage, thunkApi) => {
        const { appName, prompt } = (thunkApi.getState() as any).training as TrainingState;
        const response = await axios({
            method: 'POST',
            url: `${getTrainingApiUrl(appName)}`,
            data: {
                message: message.msg,
                prompt
            }
        });

        const { msg, steps } = response.data;
        return { data: { msg, appName, steps } };
    }
);

export const correctTrainingResponseApi = createAsyncThunk(
    '/training/train',
    async ({ user, correctedMessage }: { user: any, correctedMessage: string }, thunkApi) => {
        const response = await axios({
            method: 'POST',
            url: `${getTrainingApiUrl(getApiName(thunkApi))}/train`,
            data: { user, correctedMsg: correctedMessage },
        });

        return response.data.message;
    }
);

export const submitContextPromptApi = createAsyncThunk(
    '/training/submit_prompt',
    async ({ user, prompt }: { user: any, prompt: string }, thunkApi) => {
        await axios({
            method: 'POST',
            url: `${getTrainingApiUrl(getApiName(thunkApi))}/submit_prompt`,
            data: { user, prompt },
        });

        return { prompt };
    }
);

export const archiveConversationApi = createAsyncThunk(
    '/training/archive',
    async (user: any, thunkApi) => {
        await axios({
            method: 'POST',
            url: `${getTrainingApiUrl(getApiName(thunkApi))}/archive`,
            data: { user },
        });

        return true;
    }
);

export const regenerateResponseApi = createAsyncThunk(
    '/training/regenerate',
    async (message: string, thunkApi) => {
        const response = await axios({
            method: 'POST',
            url: `${getTrainingApiUrl(getApiName(thunkApi))}/regenerate`,
            data: { message },
        });

        return response.data;
    }
);

const slice = createSlice({
    name: 'training',
    initialState,
    reducers: {
        initializeConversation(state, action) {
            state.appName = action.payload;
        },

        submitTrainingChat(state, action) {
            state.messages = [...state.messages, action.payload.message];
            state.extraMessages = [];
        },

        loadTrainingConversation(state, action) {
            state.messages = action.payload.messages;
            state.prompt = action.payload.prompt;
        },
        toggleEditContextDialog(state, action) {
            state.isEditContextDialogOpen = action.payload;
        }
    },

    extraReducers: (builder) => {
        builder.addCase(loadTrainingConversationApi.fulfilled, (state, action) => {
            const { messages, prompt } = action.payload;
            state.messages = messages;
            state.prompt = prompt;
        }),
            builder.addCase(submitTrainingChatApi.pending, (state) => {
                state.isCorrectionSubmitted = false;
            }),

            builder.addCase(archiveConversationApi.fulfilled, (state, _) => {
                state.messages = [];
                state.isCorrectionSubmitted = false;
            }),

            builder.addCase(submitContextPromptApi.fulfilled, (state, action) => {
                state.prompt = action.payload.prompt;
                state.isEditContextDialogOpen = false;
            }),

            builder.addCase(correctTrainingResponseApi.pending, (state) => {
                state.isSubmittingCorrection = true;
            }),

            builder.addCase(correctTrainingResponseApi.rejected, (state) => {
                state.isSubmittingCorrection = false;
            }),

            builder.addCase(correctTrainingResponseApi.fulfilled, (state, action) => {
                state.isSubmittingCorrection = false;
                state.isCorrectionSubmitted = true;
                state.extraMessages = [];

                const lastMessage = state.messages.pop();
                lastMessage.msg = action.payload;
                state.messages.push(lastMessage);
            }),

            builder.addCase(regenerateResponseApi.pending, (state) => {
                state.isRegeneratingResponse = true;
            }),
            builder.addCase(regenerateResponseApi.rejected, (state) => {
                state.isRegeneratingResponse = false;
            }),
            builder.addCase(regenerateResponseApi.fulfilled, (state, action) => {
                state.isRegeneratingResponse = false;
                const { msg, steps } = action.payload;

                state.extraMessages = [...state.extraMessages, {
                    id: '',
                    msg: msg,
                    steps: steps,
                    date: '',
                    from: {name: '', id: ''},
                    to: {name: '', id: ''},
                }];
            })
    }
});

export const submitTrainingChat = (message: ChatMessage) => async (dispatch) => {
    const { id, name } = message.from;
    const userDetails = { id, name };
    dispatch(slice.actions.submitTrainingChat({ message: { ...message, from: userDetails } }));
    const response = await dispatch(submitTrainingChatApi(message));
    const { msg, appName, steps } = response.payload.data;

    dispatch(slice.actions.submitTrainingChat({
        message: {
            date: new Date().toISOString(),
            msg,
            from: { name: appName, id: `${appName}-id` },
            to: userDetails,
            steps,
        }
    }));
}

export const initializeConversation = (name: string) => (dispatch) => {
    dispatch(slice.actions.initializeConversation(name));
}

export const toggleEditContextDialog = (status: boolean) => (dispatch) => {
    dispatch(slice.actions.toggleEditContextDialog(status));
}

export const reducer = slice.reducer;

export default slice;

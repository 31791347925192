import {
  SelectChangeEvent,
  MenuItem,
  FormControl,
  Select,
  InputLabel,
  Checkbox,
  ListItemText,
} from "@mui/material";

export const availableRoles = ["Account Admin"];

const RolesSelection = ({
  selectedRoles,
  setSelectedRoles,
}: {
  selectedRoles: string[];
  setSelectedRoles: React.Dispatch<React.SetStateAction<string[]>>;
}) => {
  const handleRoleChange = (event: SelectChangeEvent<typeof selectedRoles>) => {
    setSelectedRoles(event.target.value as string[]);
  };
  return (
    <FormControl fullWidth sx={{ minWidth: 120 }}>
      <InputLabel htmlFor="max-width">Roles</InputLabel>
      <Select
        multiple
        value={selectedRoles}
        onChange={handleRoleChange}
        label="Roles"
        renderValue={(selected) => selected.join(", ")}
      >
        {availableRoles.map((role) => (
          <MenuItem key={role} value={role}>
            <Checkbox checked={selectedRoles.indexOf(role) > -1} />
            <ListItemText primary={role} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default RolesSelection;

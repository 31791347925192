import { IHeaderMenu } from "src/models/menu";

import { featureIcons } from "../../../aani/AccentHeaderLayout/featureIcons";

const {
  DealIcon,
  RangeReviewIcon,
  InsightIcon,
  MiaIcon,
  CopilotIcon,
  TrainingIcon,
} = featureIcons();

const headerMenu: IHeaderMenu = {
  items: [
    {
      name: "Deal",
      link: "/aani/negotiations/automated",
      icon: DealIcon,
      textColor: (theme) => theme.palette.primary.main,
    },
    {
      name: "AANI Copilot",
      link: "/assist/workspace",
      icon: CopilotIcon,
      textColor: (theme) => theme.palette.primary.main,
    },
    {
      name: "MIA",
      link: "/mia",
      icon: MiaIcon,
      textColor: (theme) => theme.palette.primary.main,
    },
    {
      name: "Insight",
      link: "/insight",
      icon: InsightIcon,
      textColor: (theme) => theme.palette.primary.main,
    },
    {
      name: "ILA",
      link: "/ila",
      icon: MiaIcon,
      textColor: (theme) => theme.palette.primary.main,
    },
    {
      name: "Range Review",
      link: "/range-review",
      icon: RangeReviewIcon,
      textColor: (theme) => theme.palette.primary.main,
    },
    {
      name: "Buying",
      link: "/buying",
      icon: DealIcon,
      textColor: (theme) => theme.palette.primary.main,
    },
    {
      name: "General Training",
      link: "/training/train",
      icon: TrainingIcon,
      textColor: (theme) => theme.palette.primary.main,
    },
    {
      name: "Negotiation Training",
      link: "/training/negotiation",
      icon: TrainingIcon,
      textColor: (theme) => theme.palette.primary.main,
    },
  ],
};

export default headerMenu;

import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { AppThunk } from "src/store";

import { Negotiation, NegotiationStatus } from "./model";

export enum ActionType {
  TOOL = "TOOL",
  THOUGHT = "THOUGHT",
}

export interface Action {
  actionId: number;
  title: string;
  startedOn: string;
  completedOn: string;
  type: ActionType;
  result: string;
  group: string;
  inputData: string;
  feedbackGiven: boolean;
  sessionId: string;
  runId: string;
  resultType: string;
}

interface WorkspaceState {
  action: string;
  selectedStage: NegotiationStage;
  showWorkspace: boolean;
  isSubmitting: boolean;
  negotiation: Negotiation;
  status: NegotiationStatus;
  actions: Action[];
  stages: NegotiationStage[];
  waitingForRerun: boolean;
}

interface ActionPayload {
  actionName: string;
}

export enum NegotiationStageStatus {
  PENDING = "PENDING",
  IN_PROGRESS = "IN_PROGRESS",
  COMPLETED = "COMPLETED",
}

export interface NegotiationStage {
  stage: string;
  status: NegotiationStageStatus;
  result: string;
  currentRunId: string;
}

const initialState: WorkspaceState = {
  action: "",
  showWorkspace: false,
  isSubmitting: false,
  negotiation: null,
  status: NegotiationStatus.INITIALISING,
  selectedStage: null,
  actions: [],
  stages: [],
  waitingForRerun: false, // Use to keep track of range review rerun state after making any changes
};

const slice = createSlice({
  name: "aani-range-review",
  initialState,
  reducers: {
    loadActions(state, action: PayloadAction<Action[]>) {
      return {
        ...state,
        actions: action.payload,
      };
    },
    loadNegotiation(state, action: PayloadAction<Negotiation>) {
      const negotiation = action.payload;

      if (state.negotiation?.id === negotiation.id) {
        return {
          ...state,
          negotiation,
          waitingForRerun: state.negotiation.status !== negotiation.status,
        };
      }
      // new negotiation so reset the state
      return {
        ...state,
        negotiation: action.payload,
        selectedStage: null,
        actions: [],
        waitingForRerun: false,
      };
    },
    loadStages(state, action: PayloadAction<NegotiationStage[]>) {
      return {
        ...state,
        stages: action.payload,
      };
    },
    stageSelected(state, action: PayloadAction<string>) {
      const stage = state.stages.find((s) => s.stage === action.payload);

      return {
        ...state,
        selectedStage: stage,
      };
    },
    showSummary(state) {
      return {
        ...state,
        selectedStage: null,
      };
    },
    showAction(state, action) {
      const { actionName } = action.payload;
      return {
        ...state,
        action: actionName,
        showWorkspace: true,
      };
    },
    actionCancelled(state, action: PayloadAction<ActionPayload>) {
      const { actionName } = action.payload;
      console.log("action cancelled", actionName);
      return {
        ...state,
        showWorkspace: false,
      };
    },
    actionCompleted(state, action: PayloadAction<ActionPayload>) {
      const { actionName } = action.payload;
      console.log("action completed", actionName);
      return {
        ...state,
        showWorkspace: false,
      };
    },
    setWaitingForRerun(state, action: PayloadAction<boolean>) {
      return {
        ...state,
        waitingForRerun: action.payload,
      };
    },
  },
});

export const loadStagesApi =
  (negotiationId: string): AppThunk =>
  async (dispatch, getState) => {
    const response = await axios({
      method: "GET",
      url: `${process.env.REACT_APP_API_BASE_URL}api/aani/negotiations/assisted/${negotiationId}/stages`,
    });
    const payload = response.data.stages as NegotiationStage[];
    dispatch(slice.actions.loadStages(payload));

    // Set selected stage to be the first stage if it is not already set
    const { selectedStage } = getState().rangeReview;
    if (!selectedStage && payload.length > 0) {
      const stage = payload[0];
      dispatch(slice.actions.stageSelected(stage.stage));
    }

    // If the selected stage status is completed,
    // load the selected stage again to show the current selected stage result
    const updatedSelectedStage = payload.find(
      (s) => s.stage === selectedStage?.stage,
    );

    if (updatedSelectedStage?.status === NegotiationStageStatus.COMPLETED) {
      dispatch(slice.actions.stageSelected(selectedStage?.stage));
    }
  };

export const loadNegotiationApi =
  (negotiationId: string): AppThunk =>
  async (dispatch) => {
    const response = await axios({
      method: "GET",
      url: `${process.env.REACT_APP_API_BASE_URL}api/aani/negotiations/assisted/${negotiationId}`,
    });
    const payload = response.data as Negotiation;
    dispatch(slice.actions.loadNegotiation(payload));
    dispatch(loadStagesApi(negotiationId));
  };

export const loadActionsApi =
  (negotiationId: string, stage: string): AppThunk =>
  async (dispatch) => {
    const response = await axios({
      method: "GET",
      url: `${process.env.REACT_APP_API_BASE_URL}api/aani/negotiations/assisted/${negotiationId}/stages/${stage}/actions`,
    });
    const payload = response.data.actions as Action[];
    dispatch(slice.actions.loadActions(payload));
  };

export const sendResponseApi =
  (negotiationId: string, userResponse: string): AppThunk =>
  async (dispatch) => {
    await axios({
      method: "POST",
      url: `${process.env.REACT_APP_API_BASE_URL}api/aani/negotiations/assisted/${negotiationId}/response`,
      data: {
        response: userResponse,
      },
    });

    dispatch(loadNegotiationApi(negotiationId));
  };

export const editActionAndRewindApi =
  (
    negotiationId: string,
    stage: string,
    actionId: number,
    result: string,
  ): AppThunk =>
  async (dispatch) => {
    console.log("edit action and rewind", negotiationId, stage, actionId);
    await axios({
      method: "Put",
      url: `${process.env.REACT_APP_API_BASE_URL}api/aani/negotiations/assisted/${negotiationId}/stages/${stage}/actions/${actionId}`,
      data: {
        newResult: result,
      },
    });

    setTimeout(() => {
      dispatch(loadNegotiationApi(negotiationId));
      dispatch(loadStagesApi(negotiationId));
    }, 2000);
  };

export const cancelRunApi =
  (negotiationId: string): AppThunk =>
  async (dispatch) => {
    await axios({
      method: "POST",
      url: `${process.env.REACT_APP_API_BASE_URL}api/aani/negotiations/assisted/${negotiationId}/cancel`,
    });

    dispatch(loadNegotiationApi(negotiationId));
  };

export const AddTableOfTermsAction = "add_table_of_terms";
export const ProjectSetupAction = "project_setup";
export const ShowPlan = "show_plan";
export const EmailFeedbackAction = "email_feedback";
export const FileUploadAction = "file_upload";
export const CommandAction = "command";
export const ChatAction = "chat";
export const InsightAction = "insight";
export const NewSupplierReplyAction = "new_supplier_reply";
export const NewInformationAction = "new_information";
export const ActivityLogAction = "show_activity_log";
export const UploadDealSheetAction = "upload_deal_sheet";

export const {
  showAction,
  actionCancelled,
  actionCompleted,
  stageSelected,
  showSummary,
  setWaitingForRerun,
} = slice.actions;

export const { reducer } = slice;

export default slice;

import {
  Action,
  PayloadAction,
  ThunkAction,
  createSlice,
} from "@reduxjs/toolkit";
import axios, { AxiosResponse } from "axios";
import { NewClientData } from "src/content/ila/TopSection/NewClientButton";
import { AppThunk, RootState } from "src/store";

import {
  SessionMode,
  SessionChatMessage,
  SessionChatRunStatus,
  SessionEvent,
} from "./model";

const API_PREFIX = `${process.env.REACT_APP_API_BASE_URL}api/ila`;

export interface CreateClientResponse {
  clientId: string;
  sessionId: string;
}
export enum ClientSessionStatus {
  ERROR = "ERROR",
  THINKING = "THINKING",
  WAITING_FOR_USER_INPUT = "WAITING_FOR_USER_INPUT",
  INITIALISING = "INITIALISING",
  COMPLETED = "COMPLETED",
}

export enum ClientSessionStage {
  INTAKE = "INTAKE",
  CHAT = "CHAT",
}

export interface ClientSessionDetails {
  intakeNotes?: string;
  summary?: string;
  reason?: string;
  missingInfo?: string[];
  assumptions?: string[];
  risk?: string;
  riskScore?: number;
  escalationRequired?: string;
  escalationReason?: string;
  clientGoals?: string;
  clientGoalsAssessmentScore?: number;
  clientGoalsAssessmentScoreReason?: string;
  clientGoalsAssessmentNiceToHave?: string;
  functionalAbility?: string;
  functionalAssessmentScore?: number;
  functionalAssessmentScoreReason?: string;
  functionalAssessmentNiceToHave?: string;
  cognitiveFunction?: string;
  cognitiveAssessmentScore?: number;
  cognitiveAssessmentScoreReason?: string;
  cognitiveAssessmentNiceToHave?: string;
  environmentalSafety?: string;
  environmentalAssessmentScore?: number;
  environmentalAssessmentScoreReason?: string;
  environmentalAssessmentNiceToHave?: string;
  socialSupport?: string;
  socialAssessmentScore?: number;
  socialAssessmentScoreReason?: string;
  socialAssessmentNiceToHave?: string;
  motivation?: string;
  motivationAssessmentScore?: number;
  motivationAssessmentScoreReason?: string;
  motivationAssessmentNiceToHave?: string;
  inferences?: string;
  intervention?: string;
  differentialDiagnosis?: string;
  rootCause?: string;
  likelyTreatmentGoals?: string[];
  blocksTreatmentGoals?: string[];
  firstLineTreatment?: string[];
  secondLineTreatment?: string[];
  timelineMilestones?: string[];
  preMortemAssessment?: string[];
  blackSwan?: string;
  bias?: string[];
  monitoring?: string[];
  prognosis?: string;
}

export interface ClientSession {
  sessionId: string;
  clientId: string;
  clientFirstname: string;
  clientLastname: string;
  status: ClientSessionStatus;
  currentStage: string;
  sessionStage: ClientSessionStage;
  currentRunId: string;
  details?: ClientSessionDetails | null;
  question?: string | null;
  intakeProgressPerc: number;
  intakeNotes?: string;
}

export interface ClientSessionAction {
  actionId: number;
  sessionId: string;
  runId: string;
  type: string;
  title: string;
  group: string;
  inputData: string;
  result: string;
  resultType: string;
  actionName: string;
  feedbackGiven: boolean;
  isComplete: boolean;
}

export interface ClientSessionActionsResponse {
  actions: ClientSessionAction[];
  pageSize: number;
  pageNumber: number;
}

export interface ClientSearchRequest {
  searchTerm: string;
  maxResults?: number;
}

export interface ClientBasicInfo {
  clientId: string;
  firstName: string;
  lastName: string;
  preferredName?: string | null;
  dateOfBirth: string;
}

export interface ClientSearchResponse {
  clients: ClientBasicInfo[];
  totalResults: number;
}

export interface SessionChatResponse {
  messages: SessionChatMessage[];
  runStatus: SessionChatRunStatus;
}

export enum MessageResponseType {
  TEXT = "TEXT",
}

export interface MessageResponseResult {
  id?: string;
  msg: string;
  status: SessionChatRunStatus;
  responseType: MessageResponseType;
  metadata?: Record<string, string | number>;
  replyToUserMessageId: string;
}

export interface SessionEventsResponse {
  events: SessionEvent[];
}

export interface ClientSessionSummary {
  sessionId: string;
  clientId: string;
  status: string;
  createdOnUtc: string;
  title: string;
}

export interface MagicEditResponse {
  updatedAction: string;
}

export interface IlaState {
  recentSessions: ClientSessionSummary[];
  clientId: string;
  sessionId: string;
  clientSession: ClientSession;
  messages: SessionChatMessage[];
  actions: ClientSessionAction[];
  lastMessageId: string;
  isProcessingMessage: boolean;
  hasLoadedPage: boolean;
  selectedMode: SessionMode;
}

const initialState: IlaState = {
  recentSessions: [],
  clientId: null,
  sessionId: null,
  clientSession: null,
  messages: [],
  actions: [],
  lastMessageId: null,
  isProcessingMessage: false,
  hasLoadedPage: false,
  selectedMode: SessionMode.TRAINING,
};

const slice = createSlice({
  name: "ila",
  initialState,
  reducers: {
    loadRecentSessions(state, action: PayloadAction<ClientSessionSummary[]>) {
      return {
        ...state,
        recentSessions: action.payload,
      };
    },
    loadNewClient(state, action: PayloadAction<CreateClientResponse>) {
      const { clientId, sessionId } = action.payload;
      return {
        ...state,
        clientId,
        sessionId,
      };
    },
    loadClientId(state, action: PayloadAction<string>) {
      return {
        ...state,
        clientId: action.payload,
      };
    },
    loadSessionId(state, action: PayloadAction<string>) {
      return {
        ...state,
        sessionId: action.payload,
      };
    },
    loadClientSession(state, action: PayloadAction<ClientSession>) {
      return {
        ...state,
        clientSession: action.payload,
      };
    },
    loadMessages(state, action: PayloadAction<SessionChatMessage[]>) {
      return {
        ...state,
        messages: action.payload,
      };
    },
    loadNewMessage(state, action: PayloadAction<SessionChatMessage>) {
      return {
        ...state,
        messages: [...state.messages, action.payload],
      };
    },
    loadActions(state, action: PayloadAction<ClientSessionAction[]>) {
      return {
        ...state,
        actions: action.payload,
      };
    },
    loadLastMessageId(state, action: PayloadAction<string>) {
      return {
        ...state,
        lastMessageId: action.payload,
      };
    },
    loadNewMessageWithId(
      state,
      action: PayloadAction<{ message: SessionChatMessage; messageId: string }>,
    ) {
      return {
        ...state,
        messages: [...state.messages, action.payload.message],
        lastMessageId: action.payload.messageId,
      };
    },
    setProcessingState(state, action: PayloadAction<boolean>) {
      return {
        ...state,
        isProcessingMessage: action.payload,
      };
    },
    setHasLoadedPage(state, action: PayloadAction<boolean>) {
      return {
        ...state,
        hasLoadedPage: action.payload,
      };
    },
    setSessionMode(state, action: PayloadAction<SessionMode>) {
      return {
        ...state,
        selectedMode: action.payload,
      };
    },
    updateMessagesAfterEdit(
      state,
      action: PayloadAction<{ messageId: string; newMessage: string }>,
    ) {
      const { messageId, newMessage } = action.payload;

      // Find the message in the messages array
      const messageIndex = state.messages.findIndex(
        (msg) => msg.id === messageId,
      );

      if (messageIndex !== -1) {
        // Update the message's content
        state.messages[messageIndex].msg = newMessage;
      }
    },
    updateMessagesAfterCancel(state) {
      const { lastMessageId } = state;
      const indexOflastMessageId = state.messages.findIndex(
        (element) => element.id === lastMessageId,
      );

      if (indexOflastMessageId !== -1) {
        const updatedMessages = state.messages.slice(0, indexOflastMessageId);
        const newLastMessageId =
          updatedMessages.length > 0
            ? updatedMessages[updatedMessages.length - 1].id
            : null;
        return {
          ...state,
          lastMessageId: newLastMessageId,
          actions: [],
          messages: updatedMessages,
        };
      }
      return state;
    },
    clearSession(state) {
      return {
        ...state,
        clientId: null,
        sessionId: null,
        clientSession: null,
        messages: [],
        actions: [],
        lastMessageId: null,
        isProcessingMessage: false,
        hasLoadedPage: false,
      };
    },
  },
});

// ----------------------------------------------------- //
// ------------------- HELPER FUNCTIONS ---------------- //
const loadUserMessage =
  (message: string, messageId: string): AppThunk =>
  async (dispatch) => {
    const userMessage: SessionChatMessage = {
      id: messageId,
      msg: message,
      date: new Date().toISOString(),
      isAIMessage: false,
      isRated: false,
    };
    dispatch(
      slice.actions.loadNewMessageWithId({ message: userMessage, messageId }),
    );
  };

// ---------------------------------------------------- //
// ------------------- GET REQUESTS ------------------- //
export const fetchRecentSessions =
  (): AppThunk<Promise<void>> => async (dispatch) => {
    try {
      const response = await axios.get(`${API_PREFIX}/clients/sessions/recent`);
      dispatch(slice.actions.loadRecentSessions(response.data.sessions)); // Load sessions
    } catch (error) {
      console.error("Error fetching client sessions for sidebar:", error);
    }
  };

export const getClientLatestSession =
  (clientId: string): AppThunk =>
  async (dispatch) => {
    try {
      const response: AxiosResponse<ClientSession> = await axios.get(
        `${API_PREFIX}/clients/${clientId}/sessions/latest`,
      );
      dispatch(slice.actions.loadClientSession(response.data));
      dispatch(slice.actions.loadSessionId(response.data.sessionId));
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

export const getClientSessionActions =
  (
    clientId: string,
    sessionId: string,
  ): AppThunk<Promise<ClientSessionAction[]>> =>
  async (dispatch) => {
    try {
      const response: AxiosResponse<ClientSessionActionsResponse> =
        await axios.get(
          `${API_PREFIX}/clients/${clientId}/sessions/${sessionId}/actions`,
        );
      const { actions } = response.data;
      dispatch(slice.actions.loadActions(actions));

      return actions;
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

export const getClientSessionMessages =
  (
    clientId: string,
    sessionId: string,
  ): AppThunk<Promise<SessionChatMessage[]>> =>
  async (dispatch) => {
    try {
      const response: AxiosResponse<SessionChatResponse> = await axios.get(
        `${API_PREFIX}/clients/${clientId}/sessions/${sessionId}/messages`,
      );
      const { messages } = response.data;
      dispatch(slice.actions.loadMessages(messages));

      if (messages && messages.length > 0) {
        const lastMessage = messages[messages.length - 1];
        // Case when the last message is a user message
        if (!lastMessage.isAIMessage) {
          const lastMessageId = lastMessage.id;
          dispatch(slice.actions.loadLastMessageId(lastMessageId));
        }
        // Case when the last message is an AI message
        else if (messages.length >= 2) {
          const lastUserMessage = messages[messages.length - 2];
          const lastMessageId = lastUserMessage.id;
          dispatch(slice.actions.loadLastMessageId(lastMessageId));
        }
      }

      return messages;
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

export const getMessageActions =
  (
    clientId: string,
    sessionId: string,
    messageId: string,
  ): AppThunk<Promise<ClientSessionAction[]>> =>
  async (dispatch) => {
    try {
      const response: AxiosResponse<ClientSessionActionsResponse> =
        await axios.get(
          `${API_PREFIX}/clients/${clientId}/sessions/${sessionId}/messages/${messageId}/actions`,
        );
      const { actions } = response.data;

      // Only load actions if there are any for the message
      if (actions.length > 0) dispatch(slice.actions.loadActions(actions));

      return actions;
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

export const getResponseToUserMessage =
  (clientId: string, sessionId: string, messageId: string): AppThunk =>
  async (dispatch) => {
    try {
      const response: AxiosResponse<MessageResponseResult> = await axios.get(
        `${API_PREFIX}/clients/${clientId}/sessions/${sessionId}/messages/${messageId}/response`,
      );

      const { id, msg, status } = response.data;

      if (status === SessionChatRunStatus.CANCELLED) {
        dispatch(slice.actions.setProcessingState(false));
      }
      if (status === SessionChatRunStatus.ERROR) {
        dispatch(slice.actions.setProcessingState(false));
        // TODO: Show error message to user
        console.error("Error processing user message");
      }
      if (status === SessionChatRunStatus.COMPLETED) {
        const responseMessage: SessionChatMessage = {
          id,
          msg,
          date: new Date().toISOString(),
          isAIMessage: true,
          isRated: false,
        };
        dispatch(slice.actions.loadNewMessage(responseMessage));
        dispatch(slice.actions.setProcessingState(false));
      }
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

export const getSessionEvents = async (
  clientId: string,
  sessionId: string,
): Promise<SessionEvent[]> => {
  try {
    const response: AxiosResponse<SessionEventsResponse> = await axios.get(
      `${API_PREFIX}/clients/${clientId}/sessions/${sessionId}/events`,
    );
    return response.data.events;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

// ----------------------------------------------------- //
// ------------------- POST REQUESTS ------------------- //

// Helper function to read file as a Base64 string
const readFileAsBase64 = (file: File): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onloadend = () => {
      // Cast `reader.result` to `string` as we know `readAsDataURL` returns a string
      const base64String = (reader.result as string).split(",")[1];
      resolve(base64String);
    };
    reader.onerror = reject;
    reader.readAsDataURL(file);
  });

export const createClient =
  (
    client: NewClientData,
    files: File[],
  ): ThunkAction<
    Promise<{ clientId: string; sessionId: string }>,
    RootState,
    null,
    Action<string>
  > =>
  async (dispatch) => {
    try {
      // Clear old session data before creating a new client
      dispatch(slice.actions.clearSession());

      // Convert each file to base64
      const filesToUpload = await Promise.all(
        files.map(async (file) => {
          const content = await readFileAsBase64(file);
          return {
            name: file.name,
            content,
          };
        }),
      );

      // Send client data with files to the backend
      const response: AxiosResponse<CreateClientResponse> = await axios.post(
        `${API_PREFIX}/clients`,
        {
          ...client,
          dateOfBirth: client.dateOfBirth.format("YYYY-MM-DD"),
          files: filesToUpload,
        },
      );

      const { clientId, sessionId } = response.data;
      dispatch(slice.actions.loadNewClient({ clientId, sessionId }));
      dispatch(slice.actions.setProcessingState(true));

      dispatch(fetchRecentSessions()); // Refetch recent sessions after creating a new client

      return { clientId, sessionId };
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

export const processUserMessage =
  (clientId: string, sessionId: string, message: string): AppThunk =>
  async (dispatch) => {
    // Process user message from the chat box in Chat View
    try {
      const response: AxiosResponse<{ messageId: string }> = await axios.post(
        `${API_PREFIX}/clients/${clientId}/sessions/${sessionId}/messages`,
        {
          message,
        },
      );
      dispatch(loadUserMessage(message, response.data.messageId));
      dispatch(slice.actions.setProcessingState(true));
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

export const processUserResponse =
  (clientId: string, sessionId: string, input: string): AppThunk =>
  async (dispatch) => {
    // Process user response from user input box in Activity View
    try {
      const response: AxiosResponse<{ messageId: string }> = await axios.post(
        `${API_PREFIX}/clients/${clientId}/sessions/${sessionId}/response`,
        {
          response: input,
        },
      );
      dispatch(loadUserMessage(input, response.data.messageId));
      dispatch(slice.actions.setProcessingState(true));
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

export const searchClients = async (
  searchTerm: string,
): Promise<ClientSearchResponse> => {
  try {
    const searchRequest: ClientSearchRequest = { searchTerm };
    const response = await axios.post<ClientSearchResponse>(
      `${API_PREFIX}/clients/search`,
      searchRequest,
    );
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const cancelMessage =
  (clientId: string, sessionId: string, messageId: string): AppThunk =>
  async (dispatch) => {
    try {
      await axios.post(
        `${API_PREFIX}/clients/${clientId}/sessions/${sessionId}/messages/${messageId}/cancel`,
      );
      dispatch(slice.actions.updateMessagesAfterCancel());
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

export const createSessionEvent = async (
  clientId: string,
  sessionId: string,
  event: SessionEvent,
): Promise<void> => {
  try {
    await axios.post(
      `${API_PREFIX}/clients/${clientId}/sessions/${sessionId}/events`,
      event,
    );
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const updateSessionEvent = async (
  clientId: string,
  sessionId: string,
  eventId: string,
  event: SessionEvent,
): Promise<void> => {
  try {
    await axios.put(
      `${API_PREFIX}/clients/${clientId}/sessions/${sessionId}/events/${eventId}`,
      event,
    );
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const deleteClient =
  (clientId: string): AppThunk<Promise<void>> =>
  async (dispatch) => {
    try {
      await axios.delete(`${API_PREFIX}/clients/${clientId}`);
      dispatch(fetchRecentSessions());
    } catch (error) {
      console.error("Failed to delete client:", error);
    }
  };

export const deleteSessionEvent = async (
  clientId: string,
  sessionId: string,
  eventId: string,
): Promise<void> => {
  try {
    await axios.delete(
      `${API_PREFIX}/clients/${clientId}/sessions/${sessionId}/events/${eventId}`,
    );
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const editAIMessage =
  (
    clientId: string,
    sessionId: string,
    messageId: string,
    newMessage: string,
  ): AppThunk =>
  async (dispatch) => {
    try {
      await axios.put(
        `${API_PREFIX}/clients/${clientId}/sessions/${sessionId}/messages/${messageId}/output`,
        { message: newMessage },
      );

      // Dispatch the action to update the message in the Redux store
      dispatch(
        slice.actions.updateMessagesAfterEdit({ messageId, newMessage }),
      );
    } catch (error) {
      console.error("Failed to edit AI message:", error);
      throw error;
    }
  };

export const editActionAndRewindAgentRun = async (
  clientId: string,
  sessionId: string,
  actionId: number,
  runId: string,
  newResult: string,
) => {
  try {
    await axios.put(
      `${API_PREFIX}/clients/${clientId}/sessions/${sessionId}/actions/${actionId}/rewind`,
      {
        actionId,
        runId,
        newResult,
      },
    );
  } catch (error) {
    console.error("Failed to edit action and rewind agent run:", error);
    throw error;
  }
};

export const magicEditAction = async (
  clientId: string,
  sessionId: string,
  actionId: number,
  runId: string,
  userInput: string,
) => {
  try {
    const response: AxiosResponse<MagicEditResponse> = await axios.post(
      `${API_PREFIX}/clients/${clientId}/sessions/${sessionId}/actions/${actionId}/magic-edit`,
      {
        actionId,
        runId,
        userInput,
      },
    );
    const { updatedAction } = response.data;
    return updatedAction;
  } catch (error) {
    console.error("Failed to magic edit action:", error);
    throw error;
  }
};

export const { reducer } = slice;

export default slice;

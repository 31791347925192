import { useEffect, useMemo, useState } from "react";

import {
  Box,
  Select,
  MenuItem,
  useTheme,
  CircularProgress,
} from "@mui/material";
import { tableFromIPC } from "apache-arrow";
import Plot from "react-plotly.js";
import { useSelector } from "src/store";

import "./Chart.css";
import ContentPopover from "./ContentPopover";

import { getEmbeddings } from "../api"; // Assume getHoverContent is the API to fetch hover content

const Chart = () => {
  const theme = useTheme();
  const [data, setData] = useState([]);
  const [clustersData, setClustersData] = useState({});
  const [contentTypes, setContentTypes] = useState([]);
  const [contentTypeFilter, setContentTypeFilter] = useState(""); // Default value is empty string for "All"
  const [isLoaded, setIsLoaded] = useState(false);
  const [annotations, setAnnotations] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [hoveredContent, setHoveredContent] = useState(null); // State to hold the hovered content
  const contentUsed = useSelector((state) => state.insight.contentUsed);
  const selectedKnowledgeBank = useSelector(
    (state) => state.insight.selectedKnowledgeBank,
  );

  // Custom hover handler
  const handleHover = async (event) => {
    if (!event.points || event.points.length === 0 || !selectedKnowledgeBank) {
      setHoveredContent(null);
      setAnchorEl(null);
      return;
    }

    const { pointIndex } = event.points[0];

    const hoveredPointId = event.points[0].data.customData[pointIndex];

    setHoveredContent(hoveredPointId);
    setAnchorEl({ clientX: event.event.clientX, clientY: event.event.clientY });
  };

  const handleHoverLeave = () => {
    setHoveredContent(null);
    setAnchorEl(null);
  };

  const ChartPlot = useMemo(
    () => (
      <Plot
        className="plotly-chart"
        data={data}
        config={{
          responsive: true,
          scrollZoom: true,
          displaylogo: false,
          modeBarButtonsToRemove: ["resetScale2d"],
        }}
        layout={{
          showlegend: true,
          hovermode: "closest",
          legend: {
            orientation: "h",
            y: 0,
            x: 0.5,
            xanchor: "center",
            yanchor: "top",
            itemsizing: "trace",
            valign: "middle",
            font: {
              size: 12,
            },
          },
          autosize: true,
          margin: {
            l: 0,
            r: 0,
            b: 0,
            t: 0,
          },
          xaxis: {
            showgrid: false,
            visible: false,
          },
          yaxis: {
            showgrid: false,
            visible: false,
          },
          dragmode: "pan",
          annotations,
        }}
        useResizeHandler
        style={{ width: "100%", height: "100%" }}
        onHover={handleHover}
        onUnhover={handleHoverLeave}
      />
    ),
    [data, contentUsed, annotations],
  );

  useEffect(() => {
    const contentToHighlightIds = new Set(
      contentUsed.map((content) => content.id),
    );

    const plotData = [];
    const clusterAnnotations = [];
    const contentUsedXPlotData = [];
    const contentUsedYPlotData = [];

    Object.keys(clustersData).forEach((cluster) => {
      const { id, x, y, type } = clustersData[cluster];

      // filter x and y values based on content type
      const filteredX = x.filter(
        (_, index) =>
          contentTypeFilter === "" || type[index] === contentTypeFilter,
      );

      const filteredY = y.filter(
        (_, index) =>
          contentTypeFilter === "" || type[index] === contentTypeFilter,
      );

      const filteredIds = id.filter(
        (_, index) =>
          contentTypeFilter === "" || type[index] === contentTypeFilter,
      );

      if (filteredX.length === 0) {
        return;
      }

      const xMean = filteredX.reduce((a, b) => a + b, 0) / filteredX.length;
      const yMean = filteredY.reduce((a, b) => a + b, 0) / filteredY.length;

      clusterAnnotations.push({
        x: xMean,
        y: yMean,
        xref: "x",
        yref: "y",
        text: `${cluster}`,
        showarrow: false,
        font: {
          family: "Arial",
          size: 12,
          color: "black",
        },
        bgcolor: "rgba(255, 255, 255, 0.8)",
        bordercolor: "black",
        borderwidth: 1,
        borderpad: 4,
      });

      filteredX.forEach((xVal, index) => {
        if (contentToHighlightIds.has(filteredIds[index])) {
          contentUsedXPlotData.push(xVal);
          contentUsedYPlotData.push(filteredY[index]);
        }
      });

      const opacity = contentToHighlightIds.size === 0 ? 1 : 0.1;

      plotData.push({
        x: filteredX,
        y: filteredY,
        type: "scattergl",
        mode: "markers",
        marker: {
          size: 8,
          opacity,
        },
        name: `${cluster}`,
        customData: filteredIds,
      });
    });

    plotData.push({
      x: contentUsedXPlotData,
      y: contentUsedYPlotData,
      type: "scattergl",
      mode: "markers",
      marker: {
        symbol: "circle",
        size: 15,
        line: {
          width: 1,
        },
      },
      name: "Content Used",
    });

    const contentUsedXMean =
      contentUsedXPlotData.reduce((a, b) => a + b, 0) /
      contentUsedXPlotData.length;
    const contentUsedYMean =
      contentUsedYPlotData.reduce((a, b) => a + b, 0) /
      contentUsedYPlotData.length;

    plotData.push({
      x: [contentUsedXMean],
      y: [contentUsedYMean],
      type: "scattergl",
      mode: "markers",
      marker: {
        size: 30,
        symbol: "star",
        color: "red",
        line: {
          color: "rgb(231, 99, 250)",
          width: 2,
        },
      },
      name: "Query",
    });

    setData(plotData);
    setAnnotations(clusterAnnotations);
  }, [contentUsed, clustersData, contentTypeFilter]);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoaded(false);
      const table = await tableFromIPC(getEmbeddings(selectedKnowledgeBank.id));

      const clusters = {};
      const types = new Set();

      table.batches.forEach((batch) => {
        for (let i = 0; i < batch.numRows; i += 1) {
          const row = batch.get(i);
          const { cluster, x, y, id, type } = row;
          if (!clusters[cluster]) {
            clusters[cluster] = { x: [], y: [], id: [], type: [] };
          }
          types.add(type);

          clusters[cluster].x.push(x);
          clusters[cluster].y.push(y);
          clusters[cluster].type.push(type);
          clusters[cluster].id.push(id);
        }
      });

      setClustersData(clusters);
      setContentTypes(Array.from(types));
      setIsLoaded(true);
    };

    if (selectedKnowledgeBank) fetchData();
  }, [selectedKnowledgeBank]);

  if (!isLoaded) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100%"
        width="100%"
      >
        <CircularProgress color="primary" sx={{ fontSize: 40 }} />
      </Box>
    );
  }

  return (
    <Box height="100%" width="100%" position="relative">
      {/* Dropdown Filter in Top Left Corner */}
      <Box
        sx={{
          position: "absolute",
          left: 10,
          minWidth: 150,
          zIndex: 1000,
        }}
      >
        <Select
          labelId="content-type-select-label"
          value={contentTypeFilter}
          displayEmpty
          onChange={(e) => setContentTypeFilter(e.target.value)}
          size="small"
          sx={{
            width: 150,
            "& .MuiOutlinedInput-notchedOutline": {
              border: "none",
            },
            "&:hover .MuiOutlinedInput-notchedOutline": {
              backgroundColor: theme.colors.primary.lighter,
            },
          }}
        >
          <MenuItem value="">
            <em>All</em>
          </MenuItem>
          {contentTypes.map((type) => (
            <MenuItem key={type} value={type}>
              {type}
            </MenuItem>
          ))}
        </Select>
      </Box>

      {/* Chart */}
      {ChartPlot}

      {anchorEl && hoveredContent && (
        <ContentPopover
          bankId={selectedKnowledgeBank.id}
          anchorEl={anchorEl}
          contentId={hoveredContent}
        />
      )}
    </Box>
  );
};

export default Chart;

import React, { useState } from "react";

import ScatterPlotIcon from "@mui/icons-material/ScatterPlot";
import { IconButton, Tooltip } from "@mui/material";

import ChartDrawer from "./ChartDrawer";

const ChartButton = () => {
  const [showChart, setShowChart] = useState(false);

  return (
    <>
      <Tooltip title="Scatter Plot">
        <IconButton color="primary" onClick={() => setShowChart(true)}>
          <ScatterPlotIcon />
        </IconButton>
      </Tooltip>
      <ChartDrawer showChart={showChart} setShowChart={setShowChart} />
    </>
  );
};

export default ChartButton;

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { ChatMessage } from "src/models/message";
import { AppThunk } from "src/store";

import type { PayloadAction } from "@reduxjs/toolkit";

export interface EdgeChatMessage {
  id: string;
  sessionId?: string;
  msg: string;
  isAaniMessage: boolean;
  date: string;
  isRated?: boolean;
  replyToUserMessageId?: string;
  from?: { name: string; id: string };
  to?: { name: string; id: string };
  responseType?: string;
  feedback?: boolean;
}

export interface EdgeState {
  sessionId: string;
  messages: EdgeChatMessage[];
  actions: Action[];
  action: string;
  actionInput: Record<string, string>;
  runId: string;
  showWorkspace: boolean;
  isSubmitting: boolean;
  isProcessing: boolean;
}

export interface WorkspaceSession {
  sessionId: string;
  messages: EdgeChatMessage[];
}

export enum ActionType {
  TOOL = "TOOL",
  THOUGHT = "THOUGHT",
}

export enum AgentRunStatus {
  INITIALISING = "INITIALISING",
  THINKING = "THINKING",
  ERROR = "ERROR",
}

export enum UserActionFeedbackType {
  POSITIVE = "POSITIVE",
  NEGATIVE = "NEGATIVE",
  NEUTRAL = "NEUTRAL",
}

export interface Action {
  actionId: string;
  title: string;
  startedOn: string;
  completedOn: string;
  inputData: string;
  type: ActionType;
  result: string;
  group: string;
  userFeedbackType: UserActionFeedbackType;
}

const initialState: EdgeState = {
  sessionId: null,
  messages: [],
  actions: [],
  action: "",
  actionInput: {},
  showWorkspace: false,
  runId: null,
  isSubmitting: false,
  isProcessing: false,
};

export const newSessionApi = createAsyncThunk(`edge/new`, async () => {
  const response = await axios({
    method: "POST",
    url: `${process.env.REACT_APP_API_BASE_URL}api/aani/edge/new`,
    data: {},
  });

  return response.data;
});

const slice = createSlice({
  name: "aani-edge",
  initialState,
  reducers: {
    loadSession(state, action: PayloadAction<WorkspaceSession>) {
      return {
        ...state,
        sessionId: action.payload.sessionId,
        messages: action.payload.messages,
      };
    },
    loadNewMessages(state, action: PayloadAction<EdgeChatMessage[]>) {
      const { messages } = state;
      const newMessages = [...messages, ...action.payload];
      return {
        ...state,
        messages: newMessages,
      };
    },
    loadActions(state, action: PayloadAction<Action[]>) {
      return {
        ...state,
        actions: action.payload,
      };
    },
    loadCurrentAgentRun(state, action: PayloadAction<string>) {
      return {
        ...state,
        runId: action.payload,
      };
    },
    setIsSubmitting(state, action: PayloadAction<boolean>) {
      return {
        ...state,
        isSubmitting: action.payload,
      };
    },
    setIsProcessing(state, action: PayloadAction<boolean>) {
      return {
        ...state,
        isProcessing: action.payload,
      };
    },
    showAction(state, action) {
      const { actionName } = action.payload;
      return {
        ...state,
        action: actionName,
        showWorkspace: true,
      };
    },
    actionCancelled(state, action) {
      const { actionName } = action.payload;
      return {
        ...state,
        showWorkspace: false,
        actionName,
      };
    },
    actionCompleted(state, action) {
      const { actionName } = action.payload;
      return {
        ...state,
        showWorkspace: false,
        actionName,
      };
    },
    displayWorkspace(state) {
      return {
        ...state,
        showWorkspace: true,
      };
    },
    hideWorkspace(state) {
      return {
        ...state,
        showWorkspace: false,
      };
    },
    updateMessagesAfterCancel(state) {
      const { runId } = state;
      const indexOfRunId = state.messages.findIndex(
        (element) => element.id === runId,
      );

      if (indexOfRunId !== -1) {
        const updatedMessages = state.messages.slice(0, indexOfRunId);
        return {
          ...state,
          messages: updatedMessages,
        };
      }
      return state;
    },
  },

  extraReducers: (builder) => {
    builder.addCase(newSessionApi.fulfilled, (state, { payload }) => ({
      ...state,
      isSubmitting: false,
      sessionId: payload.sessionId,
      messages: [],
    }));

    builder.addCase(newSessionApi.pending, (state) => ({
      ...state,
      isSubmitting: true,
    }));

    builder.addCase(newSessionApi.rejected, (state) => ({
      ...state,
      isSubmitting: false,
    }));
  },
});

export const sendMessage =
  (message: ChatMessage, sessionId: string): AppThunk =>
  async (dispatch) => {
    dispatch(slice.actions.setIsSubmitting(true));
    const response = await axios({
      method: "POST",
      url: `${process.env.REACT_APP_API_BASE_URL}api/aani/workspace`,
      data: {
        message: message.msg,
        sessionId,
      },
    });
    const runId = response.data.run_id;
    const userMessage: EdgeChatMessage = {
      id: runId,
      date: new Date().toISOString(),
      msg: message.msg,
      isAaniMessage: false,
    };

    dispatch(slice.actions.loadNewMessages([userMessage]));
    dispatch(slice.actions.loadCurrentAgentRun(runId));

    dispatch(slice.actions.setIsProcessing(true));
    dispatch(slice.actions.setIsSubmitting(false));

    // Reset the SidePanel actions
    dispatch(slice.actions.loadActions([]));
    dispatch(slice.actions.showAction({ actionName: "" }));

    return runId;
  };

export const loadSession = (): AppThunk => async (dispatch) => {
  const response = await axios({
    method: "GET",
    url: `${process.env.REACT_APP_API_BASE_URL}api/aani/workspace/current`,
  });
  const sessionId = response.data.session_id;
  const { messages } = response.data;
  const agentRunStatus: AgentRunStatus = response.data.agent_run_status;

  dispatch(slice.actions.loadSession({ sessionId, messages }));

  // Dispatch runId if there is user message in session
  // and runId should be the id of the last user message
  if (messages && messages.length > 0) {
    const lastMessage = messages[messages.length - 1];
    if (!lastMessage.isAaniMessage) {
      const runId = lastMessage.id;
      dispatch(slice.actions.loadCurrentAgentRun(runId));
    } else if (messages.length >= 2) {
      const lastUserMessage = messages[messages.length - 2];
      const runId = lastUserMessage.id;
      dispatch(slice.actions.loadCurrentAgentRun(runId));
    }
  }

  // Dispatch showAction for the action of the last message
  if (messages && messages.length > 0) {
    const lastMessage = messages[messages.length - 1];
    if (lastMessage.action) {
      dispatch(slice.actions.showAction({ actionName: lastMessage.action }));
    }
  }

  // Handle the case where the user refresh the page
  // We dispatch isProcessing to continue polling
  // for agent actions and response
  if (
    agentRunStatus === AgentRunStatus.THINKING ||
    agentRunStatus === AgentRunStatus.INITIALISING
  )
    dispatch(slice.actions.setIsProcessing(true));
};

export const loadSpecificSession =
  (sessionId: string): AppThunk =>
  async (dispatch) => {
    const response = await axios({
      method: "GET",
      url: `${process.env.REACT_APP_API_BASE_URL}api/aani/edge/sessions/${sessionId}`,
    });
    const { messages } = response.data;

    dispatch(slice.actions.loadSession({ sessionId, messages }));
  };

export const loadResponse =
  (sessionId: string, runId: string): AppThunk =>
  async (dispatch) => {
    try {
      const response = await axios({
        headers: {
          "Content-Type": "application/json",
          "Cache-Control": "no-cache",
        },
        method: "GET",
        url: `${process.env.REACT_APP_API_BASE_URL}api/aani/workspace/response?sessionId=${sessionId}&runId=${runId}`,
      });

      const { id, msg, responseType, replyToUserMessageId, action } =
        response.data;

      if (responseType === "CANCELLED") {
        // skip if cancelled
        dispatch(slice.actions.setIsProcessing(false));
        return response.data;
      }
      const responseMessage: EdgeChatMessage = {
        id,
        msg,
        date: new Date().toString(),
        isAaniMessage: true,
        isRated: false,
        replyToUserMessageId,
        sessionId,
      };
      if (responseType !== "THINKING") {
        dispatch(slice.actions.loadNewMessages([responseMessage]));
        dispatch(slice.actions.showAction({ actionName: action }));
        dispatch(slice.actions.setIsProcessing(false));
      } else {
        dispatch(slice.actions.setIsProcessing(true));
      }

      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

export const loadActions =
  (sessionId: string, runId: string): AppThunk =>
  async (dispatch) => {
    try {
      if (runId === null) {
        dispatch(slice.actions.loadActions([]));
        return;
      }

      const response = await axios({
        headers: {
          "Content-Type": "application/json",
          "Cache-Control": "no-cache",
        },
        method: "GET",
        url: `${process.env.REACT_APP_API_BASE_URL}api/aani/workspace/actions?sessionId=${sessionId}&runId=${runId}`,
      });
      const { actions } = response.data;

      // Convert the received actions to match Action interface
      const payload: Action[] = actions.map((action) => ({
        actionId: action.actionId,
        title: action.title,
        startedOn: action.startedOn,
        completedOn: action.completedOn,
        type: action.type,
        result: action.result,
        inputData: action.inputData,
        group: action.group,
        userFeedbackType: action.userFeedbackType,
      }));
      dispatch(slice.actions.loadActions(payload));
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

export const archiveSession = (): AppThunk => async (dispatch) => {
  await axios({
    method: "POST",
    url: `${process.env.REACT_APP_API_BASE_URL}api/aani/workspace/archive`,
    data: {},
  });

  const response = await axios({
    method: "GET",
    url: `${process.env.REACT_APP_API_BASE_URL}api/aani/workspace/current`,
  });

  const sessionId = response.data.session_id;
  const { messages } = response.data;

  dispatch(slice.actions.loadSession({ sessionId, messages }));

  // Reset runId and actions state
  dispatch(slice.actions.loadCurrentAgentRun(null));
  dispatch(loadActions(null, null));
};

export const cancelMsgAction =
  (runId: string): AppThunk =>
  async (dispatch) => {
    try {
      await axios({
        headers: {
          "Content-Type": "application/json",
          "Cache-Control": "no-cache",
        },
        method: "POST",
        url: `${process.env.REACT_APP_API_BASE_URL}api/aani/workspace/cancel`,
        data: { runId },
      });
      dispatch(slice.actions.updateMessagesAfterCancel());
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

export const sendFeedback =
  (
    feedback: UserActionFeedbackType,
    runId: string,
    actionId?: string,
  ): AppThunk =>
  async () => {
    try {
      const response = await axios({
        method: "post",
        url: `${process.env.REACT_APP_API_BASE_URL}api/aani/workspace/feedback`,
        data: {
          runId,
          feedback,
          actionId, // optional as it is only required for AgentRunAction
        },
      });
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

export const { displayWorkspace, hideWorkspace } = slice.actions;

export const { reducer } = slice;

export default slice;

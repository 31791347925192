import React from "react";

import ArrowBackTwoToneIcon from "@mui/icons-material/ArrowBackTwoTone";
import {
  Tooltip,
  IconButton,
  Grid,
  Box,
  Zoom,
  Typography,
  TextField,
  CircularProgress,
  Button,
  useTheme,
  useMediaQuery,
  MenuItem,
  Divider,
} from "@mui/material";
import axios from "axios";
import { Formik } from "formik";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { useNavigate, useLocation } from "react-router-dom";
import * as Yup from "yup";

const AddNewSupplier = () => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const handleSuccessfulCreation = () => {
    enqueueSnackbar(t("Supplier Created Successfully"), {
      variant: "success",
      anchorOrigin: {
        vertical: "top",
        horizontal: "right",
      },
      TransitionComponent: Zoom,
    });
  };
  const createSupplier = async (eventWithSubmit) => {
    try {
      const { submit, ...supplierDetails } = eventWithSubmit;
      console.log("supplierDetails", supplierDetails);
      const res = await axios({
        headers: {
          "Content-Type": "application/json",
          "Cache-Control": "no-cache",
        },
        method: "POST",
        url: `${process.env.REACT_APP_API_BASE_URL}api/aani/suppliers`,
        data: { ...supplierDetails },
      });
      return res;
    } catch (error) {
      console.error(error);
      throw error;
    }
  };
  const countries = [
    { label: "Australia", code: "AU" },
    // ... Add all the countries
  ];

  const getStates = (country_code: string) => {
    if (country_code === "AU") {
      return [
        { label: "New South Wales", code: "AU-NSW" },
        { label: "Queensland", code: "AU-QLD" },
        { label: "South Australia", code: "AU-SA" },
        { label: "Tasmania", code: "AU-TAS" },
        { label: "Victoria", code: "AU-VIC" },
        { label: "Western Australia", code: "AU-WA" },
        { label: "Australian Capital Territory", code: "AU-ACT" },
        { label: "Northern Territory", code: "AU-NT" },
      ];
    }
    // ... Add all the states
    return [];
  };
  const mobileCountryCodes = [
    {
      label: "Australia",
      code: "+61",
    },
    // ... Add all the mobile country codes
  ];
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down("sm"));
  const navigate = useNavigate();
  const location = useLocation();
  const handleBack = () =>
    navigate(`/${location.pathname.split("/")[1]}/suppliers`);

  return (
    <>
      <div style={{ marginTop: "20px" }}>
        <Grid item>
          <Box display="flex" alignItems="center" justifyContent="start">
            <Tooltip arrow placement="top" title={t("Go back")}>
              <IconButton
                onClick={handleBack}
                color="secondary"
                sx={{
                  p: 2,
                  mr: 2,
                }}
              >
                <ArrowBackTwoToneIcon />
              </IconButton>
            </Tooltip>
            <Box>
              <Typography variant="h3" component="h3" gutterBottom>
                Create Supplier
              </Typography>
            </Box>
          </Box>
        </Grid>
      </div>
      <Grid
        style={{ marginTop: "0", padding: "0 20px 0 20px" }}
        container
        spacing={3}
      >
        <Formik
          initialValues={{
            name: "",
            phone: "",
            line1: "",
            line2: "",
            line3: "",
            suburb: "",
            postal_code: "",
            state_code: "",
            country_code: countries[0].code,
            party_tax_id: "",
            first_name: "",
            middle_name: "",
            last_name: "",
            mobile_country_code: mobileCountryCodes[0].code,
            mobile_phone: "",
            email: "",
            title: "",
            submit: null,
          }}
          validationSchema={Yup.object().shape({
            name: Yup.string()
              .max(255)
              .required(t("The Supplier name field is required")),
            phone: Yup.string().required(t("Phone is required")),
            email: Yup.string()
              .email(t("Invalid email"))
              .required(t("Email is required")),
            line1: Yup.string().required(t("Address Line 1 is required")),
            line2: Yup.string(),
            line3: Yup.string(),
            postal_code: Yup.string().required(t("Postal Code is required")),
            state_code: Yup.string(),
            country_code: Yup.string().required(t("Country Code is required")),
            party_tax_id: Yup.string().required(t("Tax ID is required")),
            title: Yup.string().required(t("title is required")),
            first_name: Yup.string().required(
              t("Primary Contact First Name is required"),
            ),
            last_name: Yup.string().required(
              t("Primary Contact Last Name is required"),
            ),

            mobile_country_code: Yup.string(),
            mobile_phone: Yup.string(),
          })}
          onSubmit={async (
            _values,
            { resetForm, setErrors, setStatus, setSubmitting },
          ) => {
            try {
              await createSupplier(_values);
              resetForm();
              setStatus({ success: true });
              setSubmitting(false);
              handleSuccessfulCreation();
            } catch (err) {
              console.error(err);
              setStatus({ success: false });
              setErrors({ submit: err.message });
              setSubmitting(false);
            }
          }}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting,
            touched,
            values,
          }) => (
            <form onSubmit={handleSubmit}>
              <Grid
                sx={{
                  p: 3,
                }}
              >
                {/* Basic Information */}
                <Typography
                  style={{ fontWeight: "bold", margin: "20px 0px 10px 0" }}
                  variant="subtitle1"
                  gutterBottom
                >
                  Basic Information
                </Typography>
                <Divider style={{ margin: "10px 0px 15px 0" }} />
                <Grid container spacing={3}>
                  <Grid item xs={4}>
                    <TextField
                      label={t("Supplier Name")}
                      error={Boolean(touched.name && errors.name)}
                      fullWidth
                      helperText={touched.name && errors.name}
                      name="name"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.name}
                      variant="outlined"
                      inputProps={{ maxLength: 255 }}
                    />
                  </Grid>

                  <Grid item xs={4}>
                    <TextField
                      label={t("Phone")}
                      error={Boolean(touched.phone && errors.phone)}
                      fullWidth
                      helperText={touched.phone && errors.phone}
                      name="phone"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.phone}
                      variant="outlined"
                      inputProps={{
                        maxLength: 40,
                      }}
                    />
                  </Grid>
                </Grid>

                {/* Address */}
                <Typography
                  style={{ fontWeight: "bold", margin: "20px 0px 10px 0" }}
                  variant="subtitle1"
                  gutterBottom
                >
                  Address
                </Typography>
                <Divider style={{ margin: "10px 0px 15px 0" }} />
                <Grid container spacing={3}>
                  <Grid item xs={4}>
                    <TextField
                      label={t("Address Line 1")}
                      error={Boolean(touched.line1 && errors.line1)}
                      fullWidth
                      helperText={touched.line1 && errors.line1}
                      name="line1"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.line1}
                      variant="outlined"
                      inputProps={{ maxLength: 255 }}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      label={t("Address Line 2")}
                      error={Boolean(touched.line2 && errors.line2)}
                      fullWidth
                      helperText={touched.line2 && errors.line2}
                      name="line2"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.line2}
                      variant="outlined"
                      inputProps={{ maxLength: 255 }}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      label={t("Address Line 3")}
                      error={Boolean(touched.line3 && errors.line3)}
                      fullWidth
                      helperText={touched.line3 && errors.line3}
                      name="line3"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.line3}
                      variant="outlined"
                      inputProps={{ maxLength: 255 }}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      label={t("Suburb")}
                      error={Boolean(touched.suburb && errors.suburb)}
                      fullWidth
                      helperText={touched.suburb && errors.suburb}
                      name="suburb"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.suburb}
                      variant="outlined"
                      inputProps={{ maxLength: 10 }}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      label={t("Postal Code")}
                      error={Boolean(touched.postal_code && errors.postal_code)}
                      fullWidth
                      helperText={touched.postal_code && errors.postal_code}
                      name="postal_code"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.postal_code}
                      variant="outlined"
                      inputProps={{ maxLength: 10 }}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      select
                      label={t("Country Code")}
                      error={Boolean(
                        touched.country_code && errors.country_code,
                      )}
                      fullWidth
                      helperText={touched.country_code && errors.country_code}
                      name="country_code"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.country_code}
                      variant="outlined"
                      inputProps={{ maxLength: 3 }}
                    >
                      {countries.map((country) => (
                        <MenuItem key={country.code} value={country.code}>
                          {country.label} ({country.code})
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      select
                      label={t("State Code")}
                      error={Boolean(touched.state_code && errors.state_code)}
                      fullWidth
                      helperText={touched.state_code && errors.state_code}
                      name="state_code"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.state_code}
                      variant="outlined"
                      inputProps={{ maxLength: 255 }}
                    >
                      {getStates(values.country_code).map((country) => (
                        <MenuItem key={country.code} value={country.code}>
                          {country.label} ({country.code})
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                </Grid>

                {/* Tax Information */}
                <Typography
                  style={{ fontWeight: "bold", margin: "20px 0px 10px 0" }}
                  variant="subtitle1"
                  gutterBottom
                >
                  Tax Information
                </Typography>
                <Divider style={{ margin: "10px 0px 15px 0" }} />
                <Grid container spacing={3}>
                  <Grid item xs={4}>
                    <TextField
                      label={t("Party Tax ID")}
                      error={Boolean(
                        touched.party_tax_id && errors.party_tax_id,
                      )}
                      fullWidth
                      helperText={touched.party_tax_id && errors.party_tax_id}
                      name="party_tax_id"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.party_tax_id}
                      variant="outlined"
                      inputProps={{ maxLength: 255 }}
                    />
                  </Grid>
                </Grid>

                {/* Primary Contact Details */}
                <Typography
                  style={{ fontWeight: "bold", margin: "20px 0px 10px 0" }}
                  variant="subtitle1"
                  gutterBottom
                >
                  Primary Contact Details
                </Typography>
                <Divider style={{ margin: "10px 0px 15px 0" }} />
                <Grid container spacing={3}>
                  <Grid item xs={4}>
                    <TextField
                      select
                      label={t("Primary Contact Title")}
                      error={Boolean(touched.title && errors.title)}
                      fullWidth
                      helperText={touched.title && errors.title}
                      name="title"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.title}
                      variant="outlined"
                      inputProps={{ maxLength: 255 }}
                    >
                      <MenuItem value="Mr">{t("Mr.")}</MenuItem>
                      <MenuItem value="Mrs">{t("Mrs.")}</MenuItem>
                      <MenuItem value="Miss">{t("Miss.")}</MenuItem>
                      <MenuItem value="Dr">{t("Dr.")}</MenuItem>
                    </TextField>
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      label={t("Primary Contact First Name")}
                      error={Boolean(touched.first_name && errors.first_name)}
                      fullWidth
                      helperText={touched.first_name && errors.first_name}
                      name="first_name"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.first_name}
                      variant="outlined"
                      inputProps={{ maxLength: 255 }}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      label={t("Primary Contact Middle Name")}
                      error={Boolean(touched.middle_name && errors.middle_name)}
                      fullWidth
                      helperText={touched.middle_name && errors.middle_name}
                      name="middle_name"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.middle_name}
                      variant="outlined"
                      inputProps={{ maxLength: 255 }}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      label={t("Primary Contact Last Name")}
                      error={Boolean(touched.last_name && errors.last_name)}
                      fullWidth
                      helperText={touched.last_name && errors.last_name}
                      name="last_name"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.last_name}
                      variant="outlined"
                      inputProps={{ maxLength: 255 }}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      label={t("Primary Contact Email")}
                      error={Boolean(touched.email && errors.email)}
                      fullWidth
                      helperText={touched.email && errors.email}
                      name="email"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.email}
                      variant="outlined"
                      inputProps={{ maxLength: 255 }}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      select
                      label={t("Primary Contact Country Code")}
                      name="mobile_country_code"
                      value={values.mobile_country_code}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      helperText={
                        touched.mobile_country_code &&
                        errors.mobile_country_code
                      }
                      error={Boolean(
                        touched.mobile_country_code &&
                          errors.mobile_country_code,
                      )}
                      variant="outlined"
                      fullWidth
                      inputProps={{ maxLength: 3 }}
                    >
                      {mobileCountryCodes.map((country) => (
                        <MenuItem key={country.code} value={country.code}>
                          {country.label} ({country.code})
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      label={t("Primary Contact Phone")}
                      error={Boolean(
                        touched.mobile_phone && errors.mobile_phone,
                      )}
                      fullWidth
                      helperText={touched.mobile_phone && errors.mobile_phone}
                      name="mobile_phone"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.mobile_phone}
                      variant="outlined"
                      inputProps={{ maxLength: 40 }}
                    />
                  </Grid>
                </Grid>
              </Grid>

              <Box
                sx={{
                  display: { xs: "block", sm: "flex" },
                  alignItems: "center",
                  justifyContent: "space-between",
                  p: 3,
                }}
              >
                <Button
                  fullWidth={mobile}
                  variant="outlined"
                  onClick={handleBack}
                >
                  {t("Cancel")}
                </Button>
                <Button
                  fullWidth={mobile}
                  type="submit"
                  startIcon={
                    isSubmitting ? <CircularProgress size="1rem" /> : null
                  }
                  disabled={Boolean(errors.submit) || isSubmitting}
                  variant="contained"
                  size="large"
                >
                  {t("Create Supplier")}
                </Button>
              </Box>
            </form>
          )}
        </Formik>
      </Grid>
    </>
  );
};

export default AddNewSupplier;

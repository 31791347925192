import { useState, useContext, ReactNode } from "react";

import ExpandLessTwoToneIcon from "@mui/icons-material/ExpandLessTwoTone";
import ExpandMoreTwoToneIcon from "@mui/icons-material/ExpandMoreTwoTone";
import {
  Button,
  Tooltip,
  Badge,
  Collapse,
  ListItem,
  styled,
  tooltipClasses,
  Typography,
} from "@mui/material";
import clsx from "clsx";
import { useTranslation } from "react-i18next";
import { NavLink as RouterLink } from "react-router-dom";
import { SidebarContext } from "src/contexts/SidebarContext";

interface SidebarMenuItemProps {
  children?: ReactNode;
  active: boolean;
  link: string;
  icon: React.ElementType;
  badge: string;
  badgeTooltip: string;
  open?: boolean;
  name: string;
  isChild: boolean;
}

const TooltipWrapper = styled(Tooltip)(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.colors.alpha.black[100],
    color: theme.palette.getContrastText(theme.colors.alpha.black[100]),
    fontSize: theme.typography.pxToRem(12),
    fontWeight: "bold",
    borderRadius: theme.general.borderRadiusSm,
    boxShadow:
      "0 .2rem .8rem rgba(7,9,25,.18), 0 .08rem .15rem rgba(7,9,25,.15)",
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.colors.alpha.black[100],
  },
}));

const SidebarMenuItem = ({
  active,
  children,
  link,
  icon: Icon,
  badge,
  badgeTooltip,
  open: openParent,
  name,
  isChild,
}: SidebarMenuItemProps) => {
  const [menuToggle, setMenuToggle] = useState(openParent);
  const { t } = useTranslation();
  const { closeSidebar } = useContext(SidebarContext);

  const renderText = (text: string, isChildNavItem: boolean) =>
    isChildNavItem ? (
      <Typography variant="body1">{text}</Typography>
    ) : (
      <Typography variant="h5">{text}</Typography>
    );

  const toggleMenu = () => {
    setMenuToggle((Open) => !Open);
  };

  const linkTo = link || "#";

  if (children) {
    return (
      <ListItem component="div" className="Mui-children">
        <Button
          className={clsx({ "Mui-active": menuToggle })}
          startIcon={Icon && <Icon />}
          endIcon={
            menuToggle ? <ExpandLessTwoToneIcon /> : <ExpandMoreTwoToneIcon />
          }
          onClick={toggleMenu}
        >
          {badgeTooltip && (
            <TooltipWrapper title={badgeTooltip} arrow placement="right">
              {badge === "" ? (
                <Badge color="primary" variant="dot" />
              ) : (
                <Badge badgeContent={badge} />
              )}
            </TooltipWrapper>
          )}
          {!badgeTooltip &&
            (badge === "" ? (
              <Badge color="primary" variant="dot" />
            ) : (
              <Badge badgeContent={badge} />
            ))}

          <Typography variant="h5">{t(name)}</Typography>
        </Button>
        <Collapse in={menuToggle}>{children}</Collapse>
      </ListItem>
    );
  }

  return (
    <ListItem component="div">
      <Button
        disableRipple
        className={clsx({ "Mui-active": active })}
        component={RouterLink}
        onClick={closeSidebar}
        to={linkTo}
        startIcon={Icon && <Icon />}
      >
        {renderText(name, isChild)}
        {badgeTooltip && (
          <TooltipWrapper title={badgeTooltip} arrow placement="right">
            {badge === "" ? (
              <Badge color="primary" variant="dot" />
            ) : (
              <Badge badgeContent={badge} />
            )}
          </TooltipWrapper>
        )}
        {!badgeTooltip &&
          (badge === "" ? (
            <Badge color="primary" variant="dot" />
          ) : (
            <Badge badgeContent={badge} />
          ))}
      </Button>
    </ListItem>
  );
};

SidebarMenuItem.defaultProps = {
  children: null,
  open: false,
};

export default SidebarMenuItem;

import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import {
  AutomatedNegotiation,
  AutomatedNegotiationState,
} from "src/models/negotiation";
import { AppThunk } from "src/store";

import type { PayloadAction } from "@reduxjs/toolkit";

interface NegotiationsState {
  negotiations: AutomatedNegotiation[];
  negotiationsCount: number;
}

const initialState: NegotiationsState = {
  negotiations: [],
  negotiationsCount: 0,
};

const negotiationsSlice = createSlice({
  name: "aani-negotiations",
  initialState,
  reducers: {
    loadAutomatedNegotiations(
      state,
      action: PayloadAction<AutomatedNegotiation[]>,
    ) {
      return {
        ...state,
        negotiations: action.payload,
      };
    },
    loadNegotiationsCount(state, action: PayloadAction<number>) {
      return {
        ...state,
        negotiationsCount: action.payload,
      };
    },
  },
});

export const loadAutomatedNegotiations =
  (
    page: number,
    limit: number,
    stateList: AutomatedNegotiationState[] | null,
  ): AppThunk =>
  async (dispatch) => {
    try {
      // Axios will convert array param to this format
      // stateList[]=REQUESTED_PREFERENCES&stateList[]=MADE_FIRST_OFFER
      const response = await axios({
        method: "GET",
        url: `${process.env.REACT_APP_API_BASE_URL}api/aani/negotiations/automated`,
        params: {
          page: page + 1,
          pageSize: limit,
          stateList,
        },
      });

      const negotiations = response.data.negotiations as AutomatedNegotiation[];
      const negotiationsCount = response.data.negotiationsCount as number;

      dispatch(
        negotiationsSlice.actions.loadAutomatedNegotiations(negotiations),
      );
      dispatch(
        negotiationsSlice.actions.loadNegotiationsCount(negotiationsCount),
      );
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

export const loadChildAutomatedNegotiations =
  (
    page: number,
    limit: number,
    stateList: AutomatedNegotiationState[] | null,
    parentNegotiationId: string,
  ): AppThunk =>
  async (dispatch) => {
    try {
      // Axios will convert array param to this format
      // stateList[]=REQUESTED_PREFERENCES&stateList[]=MADE_FIRST_OFFER
      const response = await axios({
        method: "GET",
        url: `${process.env.REACT_APP_API_BASE_URL}api/aani/negotiations/automated`,
        params: {
          page: page + 1,
          pageSize: limit,
          stateList,
          parentId: parentNegotiationId,
        },
      });

      const negotiations = response.data.negotiations as AutomatedNegotiation[];
      const negotiationsCount = response.data.negotiationsCount as number;

      dispatch(
        negotiationsSlice.actions.loadAutomatedNegotiations(negotiations),
      );
      dispatch(
        negotiationsSlice.actions.loadNegotiationsCount(negotiationsCount),
      );
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

export default negotiationsSlice;

import React, { Dispatch, SetStateAction, useEffect, useState } from "react";

import {
  Grid,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  Zoom,
  Typography,
  TextField,
  CircularProgress,
  Button,
  InputLabel,
  Select,
  MenuItem,
  Divider,
  Skeleton,
} from "@mui/material";
import { Formik } from "formik";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { getUsers } from "src/slices/settings/user";
import { useDispatch } from "src/store";
import * as Yup from "yup";

import RolesSelection from "./shared/RolesSelection";

import {
  InviteUserResponse,
  inviteUser,
  UserModuleRoles,
  getAdminModuleRoles,
} from "../api/user";

interface InviteUserDialogProps {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
}

const InviteUserDialog: React.FC<InviteUserDialogProps> = ({
  open,
  setOpen,
}: InviteUserDialogProps) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const [selectedRoles, setSelectedRoles] = useState<string[]>([]);
  const [moduleRoles, setModuleRoles] = useState<UserModuleRoles[]>([]);
  const [selectedModuleRole, setSelectedModuleRole] = useState<
    Record<string, string>
  >({});
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    const fetchModulesRoles = async () => {
      setLoading(true);

      const adminModuleRoles = await getAdminModuleRoles();
      setModuleRoles(adminModuleRoles);

      setLoading(false);
    };

    if (open) fetchModulesRoles();
  }, [open]);

  const handleCreateUserClose = () => {
    setOpen(false);
  };

  const handleCreateUserSuccess = (message: string) => {
    enqueueSnackbar(message, {
      variant: "success",
      anchorOrigin: { vertical: "top", horizontal: "right" },
      TransitionComponent: Zoom,
    });

    setOpen(false);
  };

  const handleCreateUserError = (message: string) => {
    enqueueSnackbar(message, {
      variant: "error",
      anchorOrigin: { vertical: "top", horizontal: "right" },
      TransitionComponent: Zoom,
    });
  };

  const handleModuleRoleChange = (module: string, role: string) => {
    if (role === "") {
      // Handle when user remove module role for a module
      // Remove module and its role from selectedModuleRole
      const newSelectedModuleRole = { ...selectedModuleRole };
      delete newSelectedModuleRole[module];
      setSelectedModuleRole(newSelectedModuleRole);
    } else {
      // Update selectedModuleRole with new module role
      setSelectedModuleRole({
        ...selectedModuleRole,
        [module]: role,
      });
    }
  };

  return (
    <Dialog fullWidth maxWidth="md" open={open} onClose={handleCreateUserClose}>
      <DialogTitle sx={{ p: 3 }}>
        <Typography variant="h4" gutterBottom>
          {t("Invite user")}
        </Typography>
        <Typography variant="subtitle2">
          {t("Fill in the fields below to invite new user to the organisation")}
        </Typography>
      </DialogTitle>
      <Formik
        initialValues={{ firstName: "", lastName: "", email: "", submit: null }}
        validationSchema={Yup.object().shape({
          firstName: Yup.string()
            .max(255)
            .required(t("The first name field is required")),
          lastName: Yup.string()
            .max(255)
            .required(t("The last name field is required")),
          email: Yup.string()
            .email(t("The email provided should be a valid email address"))
            .max(255)
            .required(t("The email field is required")),
        })}
        onSubmit={async (
          values,
          { resetForm, setErrors, setStatus, setSubmitting },
        ) => {
          const { firstName, lastName, email } = values;
          try {
            const response: InviteUserResponse = await inviteUser(
              firstName,
              lastName,
              email,
              selectedRoles,
              selectedModuleRole,
            );
            if (response.isSuccess) {
              resetForm();
              setStatus({ success: true });
              setSubmitting(false);
              handleCreateUserSuccess(response.message);
              // Update users in redux store
              dispatch(getUsers());
            } else {
              setStatus({ success: false });
              setErrors({ submit: response.message });
              setSubmitting(false);
              handleCreateUserError(response.message);
            }
          } catch (err) {
            console.error(err);
            setStatus({ success: false });
            setErrors({ submit: err.message });
            setSubmitting(false);
            handleCreateUserError(err.message);
          }
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          touched,
          values,
        }) => (
          <form onSubmit={handleSubmit}>
            <DialogContent
              dividers
              sx={{ p: 3, maxHeight: "60vh", overflow: "auto" }}
            >
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Typography variant="h5">{t("User Information")}</Typography>
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    error={Boolean(touched.firstName && errors.firstName)}
                    fullWidth
                    helperText={touched.firstName && errors.firstName}
                    label={t("First Name")}
                    name="firstName"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.firstName}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    error={Boolean(touched.lastName && errors.lastName)}
                    fullWidth
                    helperText={touched.lastName && errors.lastName}
                    label={t("Last Name")}
                    name="lastName"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.lastName}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    error={Boolean(touched.email && errors.email)}
                    fullWidth
                    helperText={touched.email && errors.email}
                    label={t("Email address")}
                    name="email"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="email"
                    value={values.email}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12}>
                  <RolesSelection
                    selectedRoles={selectedRoles}
                    setSelectedRoles={setSelectedRoles}
                  />
                </Grid>
              </Grid>
              <Divider sx={{ marginY: 3 }} />
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Typography variant="h5">
                    {t("User Manage Access")}
                  </Typography>
                </Grid>
                {loading ? (
                  <Grid item xs={12}>
                    <Skeleton variant="rectangular" height={200} />
                  </Grid>
                ) : (
                  moduleRoles.map((moduleRole) => (
                    <React.Fragment key={moduleRole.module}>
                      <Grid item xs={6}>
                        <InputLabel>{moduleRole.module}</InputLabel>
                      </Grid>
                      <Grid item xs={6}>
                        <Select
                          size="small"
                          value={selectedModuleRole[moduleRole.module] || ""}
                          onChange={(e) =>
                            handleModuleRoleChange(
                              moduleRole.module,
                              e.target.value as string,
                            )
                          }
                          displayEmpty
                          fullWidth
                        >
                          <MenuItem value="">
                            <em>None</em>
                          </MenuItem>
                          {moduleRole.availableRoles.map((role) => (
                            <MenuItem key={role} value={role}>
                              {role}
                            </MenuItem>
                          ))}
                        </Select>
                      </Grid>
                    </React.Fragment>
                  ))
                )}
              </Grid>
            </DialogContent>
            <DialogActions sx={{ p: 3 }}>
              <Button color="secondary" onClick={handleCreateUserClose}>
                {t("Cancel")}
              </Button>
              <Button
                type="submit"
                startIcon={
                  isSubmitting ? <CircularProgress size="1rem" /> : null
                }
                disabled={Boolean(errors.submit) || isSubmitting}
                variant="contained"
              >
                {t("Invite user")}
              </Button>
            </DialogActions>
          </form>
        )}
      </Formik>
    </Dialog>
  );
};

export default InviteUserDialog;

import { lazy, Suspense } from "react";

import { Navigate } from "react-router-dom";
import AppSelection from "src/components/AppSelection";
import SuspenseLoader from "src/components/SuspenseLoader";
import Dashboard from "src/content/aani/dashboard";
import OTPAuthenticated from "src/content/aani/deal/OTPAuthenticated";
import AddNewSupplier from "src/content/aani/suppliers/add_new";
import BillingSettings from "src/content/settings/billing";
import UsersSettings from "src/content/settings/users";
import TrainingNegotiationCreate from "src/content/training/negotiation/create";
import { FeatureFlagProvider } from "src/contexts/FeatureFlagContext";
import InsightLayout from "src/layouts/insight";

import Authenticated from "../components/Authenticated";
import AaniLayout from "../layouts/aani/AccentHeaderLayout";
import IlaLayout from "../layouts/ila";
import MiaLayout from "../layouts/mia/";
import RangeReviewLayout from "../layouts/range-review/layout";
import SettingsLayout from "../layouts/settings/layout";
import TrainingLayout from "../layouts/training/layout";

const Loader = (Component) => (props) =>
  (
    <Suspense fallback={<SuspenseLoader />}>
      <Component {...props} />
    </Suspense>
  );

const AaniAutomatedNegotiations = Loader(
  lazy(() => import("../content/aani/negotiations/automated")),
);

const AaniAutomatedNegotiation = Loader(
  lazy(() => import("../content/aani/negotiations/automated/negotiation")),
);

const AaniAssistedNegotiationWorkspace = Loader(
  lazy(() => import("../content/aani/negotiations/assisted/Workspace")),
);

const AaniDealView = Loader(lazy(() => import("../content/aani/deal/single")));

const AaniDealSettingsGeneral = Loader(
  lazy(() => import("../content/aani/settings/General")),
);
const AaniDealSettingsDeal = Loader(
  lazy(() => import("../content/aani/settings/Deal")),
);

const AaniSuppliers = Loader(lazy(() => import("../content/aani/suppliers")));

const AaniSupplierView = Loader(
  lazy(() => import("../content/aani/suppliers/single")),
);

const AaniEdge = Loader(lazy(() => import("../content/aani/edge")));

const AaniAnalysisInsights = Loader(
  lazy(() => import("../content/aani/insights/analysis")),
);

const AaniAnalysisInsightsContracted = Loader(
  lazy(() => import("../content/aani/insights/contracted")),
);

const AaniAnalysisInsightsSpend = Loader(
  lazy(() => import("../content/aani/insights/uncontracted")),
);

const AaniDealExamples = Loader(
  lazy(() => import("../content/aani/negotiations/automated/examples")),
);

const AaniDealNewNegotiation = Loader(
  lazy(() => import("../content/aani/negotiations/automated/new")),
);

const RangeReview = Loader(
  lazy(() => import("../content/aani/negotiations/range-review")),
);

const RangeReviewDashboard = Loader(
  lazy(() => import("../content/aani/negotiations/range-review/Dashboard")),
);

const CreateRangeReview = Loader(
  lazy(() => import("../content/aani/negotiations/range-review/Create")),
);

const TrainingTrain = Loader(lazy(() => import("../content/training/train")));

const TrainingNegotiation = Loader(
  lazy(() => import("../content/training/negotiation")),
);

const BuyingLayout = Loader(lazy(() => import("../layouts/buying/layout")));

const BuyingDashboard = Loader(
  lazy(() => import("../content/aani/negotiations/buying/dashboard")),
);

const BuyingSettings = Loader(
  lazy(() => import("../content/aani/negotiations/buying/settings")),
);

const BuyingSupplierNegotiations = Loader(
  lazy(
    () => import("../content/aani/negotiations/buying/supplier/negotiations"),
  ),
);

const BuyingSupplierNewNegotiation = Loader(
  lazy(() => import("../content/aani/negotiations/buying/supplier/new")),
);

const BuyingSupplierNegotiation = Loader(
  lazy(() => import("../content/aani/negotiations/automated/negotiation")),
);

const MiaIndex = Loader(lazy(() => import("../content/mia/index")));

const AssistLayout = Loader(lazy(() => import("../layouts/assist")));

const InsightIndex = Loader(lazy(() => import("../content/insight")));

const IlaIndex = Loader(lazy(() => import("../content/ila/index")));

const router = [
  {
    path: "/",
    element: (
      <Authenticated>
        <FeatureFlagProvider>
          <AppSelection />
        </FeatureFlagProvider>
      </Authenticated>
    ),
  },
  {
    path: "assist",
    element: (
      <Authenticated>
        <FeatureFlagProvider>
          <AssistLayout />
        </FeatureFlagProvider>
      </Authenticated>
    ),
    children: [
      {
        path: "workspace",
        element: <AaniAssistedNegotiationWorkspace />,
      },
      {
        path: "workspace/:negotiationId",
        element: <AaniAssistedNegotiationWorkspace />,
      },
    ],
  },
  {
    path: "/aani",
    element: (
      <Authenticated>
        <FeatureFlagProvider>
          <AaniLayout />
        </FeatureFlagProvider>
      </Authenticated>
    ),
    children: [
      {
        path: "/aani",
        element: <Navigate to="negotiations/automated" replace />,
      },
      {
        path: "/aani/dashboard",
        element: <Dashboard />,
      },
      {
        path: "/aani/edge",
        element: <AaniEdge />,
      },
      {
        path: "/aani/suppliers/single/:supplierId",
        element: <AaniSupplierView />,
      },
      {
        path: "/aani/suppliers",
        element: <AaniSuppliers />,
      },
      {
        path: "/aani/suppliers/new",
        element: <AddNewSupplier />,
      },
      {
        path: "/aani/settings",
        children: [
          {
            path: "general",
            element: <AaniDealSettingsGeneral />,
          },
          {
            path: "deal",
            element: <AaniDealSettingsDeal />,
          },
        ],
      },
      {
        path: "/aani/negotiations",
        children: [
          {
            path: "rangereview",
            element: <RangeReview />,
          },
          {
            path: "automated/examples",
            element: <AaniDealExamples />,
          },
          {
            path: "automated/new",
            element: <AaniDealNewNegotiation />,
          },
          {
            path: "assisted/workspace",
            element: <AaniAssistedNegotiationWorkspace />,
          },
          {
            path: "assisted/workspace/:negotiationId",
            element: <AaniAssistedNegotiationWorkspace />,
          },
          {
            path: "automated",
            element: <AaniAutomatedNegotiations />,
          },
          {
            path: "automated/:negotiationId",
            element: <AaniAutomatedNegotiation />,
          },
        ],
      },
      {
        path: "/aani/insights",
        children: [
          {
            path: "analysis",
            element: <AaniAnalysisInsights />,
          },
          {
            path: "contracted",
            element: <AaniAnalysisInsightsContracted />,
          },
          {
            path: "uncontracted",
            element: <AaniAnalysisInsightsSpend />,
          },
        ],
      },
    ],
  },
  {
    path: "/training",
    element: (
      <Authenticated>
        <FeatureFlagProvider>
          <TrainingLayout />
        </FeatureFlagProvider>
      </Authenticated>
    ),
    children: [
      {
        path: "",
        element: <Navigate to="train" replace />,
      },
      {
        path: "/training/train",
        children: [
          {
            path: "",
            element: <TrainingTrain />,
          },
          {
            path: ":id",
            element: <TrainingTrain />,
          },
        ],
      },
      {
        path: "/training/negotiation",
        children: [
          {
            path: "",
            element: <TrainingNegotiation />,
          },
          {
            path: ":id",
            element: <TrainingNegotiation />,
          },
          {
            path: "create",
            element: <TrainingNegotiationCreate />,
          },
        ],
      },
    ],
  },
  {
    path: "/deal",
    children: [
      {
        path: "/deal/:negotiationId",
        element: (
          <OTPAuthenticated>
            <AaniDealView />
          </OTPAuthenticated>
        ),
      },
    ],
  },
  {
    path: "/settings",
    element: (
      <Authenticated>
        <FeatureFlagProvider>
          <SettingsLayout />
        </FeatureFlagProvider>
      </Authenticated>
    ),
    children: [
      {
        path: "",
        element: <Navigate to="users" replace />,
      },
      {
        path: "users",
        element: <UsersSettings />,
      },
      {
        path: "billing",
        element: <BillingSettings />,
      },
    ],
  },
  {
    path: "/range-review",
    element: (
      <Authenticated>
        <FeatureFlagProvider>
          <RangeReviewLayout />
        </FeatureFlagProvider>
      </Authenticated>
    ),
    children: [
      {
        path: "",
        element: <Navigate to="dashboard" replace />,
      },
      {
        path: "dashboard",
        element: <RangeReviewDashboard />,
      },
      {
        path: "create",
        element: <CreateRangeReview />,
      },
      {
        path: "supplier/:supplierId",
        element: <RangeReview />,
      },
      {
        path: "supplier/:supplierId/:negotiationId",
        element: <RangeReview />,
      },
    ],
  },
  {
    path: "/buying",
    element: (
      <Authenticated>
        <FeatureFlagProvider>
          <BuyingLayout />
        </FeatureFlagProvider>
      </Authenticated>
    ),
    children: [
      {
        path: "",
        element: <Navigate to="dashboard" replace />,
      },
      {
        path: "dashboard",
        element: <BuyingDashboard />,
      },
      {
        path: "settings",
        element: <BuyingSettings />,
      },
      {
        path: "suppliers/:supplierId",
        element: <BuyingSupplierNegotiations />,
      },
      {
        path: "suppliers/:supplierId/new",
        element: <BuyingSupplierNewNegotiation />,
      },
      {
        path: "suppliers/:supplierId/negotiations/:negotiationId",
        element: <BuyingSupplierNegotiation />,
      },
    ],
  },
  {
    path: "/mia",
    element: (
      <Authenticated>
        <FeatureFlagProvider>
          <MiaLayout />
        </FeatureFlagProvider>
      </Authenticated>
    ),
    children: [
      {
        path: "",
        element: <MiaIndex />,
      },
      {
        path: "clients/:clientId",
        element: <MiaIndex />,
      },
    ],
  },
  {
    path: "/insight",
    element: (
      <Authenticated>
        <FeatureFlagProvider>
          <InsightLayout />
        </FeatureFlagProvider>
      </Authenticated>
    ),
    children: [
      {
        path: "",
        element: <InsightIndex />,
      },
    ],
  },
  {
    path: "/ila",
    element: (
      <Authenticated>
        <FeatureFlagProvider>
          <IlaLayout />
        </FeatureFlagProvider>
      </Authenticated>
    ),
    children: [
      {
        path: "",
        element: <IlaIndex />,
      },
      {
        path: "clients/:clientId",
        element: <IlaIndex />,
      },
    ],
  },
];

export default router;

import {
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";

import axios from "axios";

export const FeatureFlagContext = createContext([]);

export function useFeatureFlag(name: string) {
  const features = useContext(FeatureFlagContext);
  if (!features) {
    return false;
  }
  if (!features[name]) {
    return false;
  }

  return features[name].isEnabled;
}

export const FeatureFlagProvider = ({ children }: { children: ReactNode }) => {
  const [featureFlags, setFeatureFlags] = useState();

  const getFeatureFlags = async () => {
    const response = await axios({
      method: "GET",
      url: `${process.env.REACT_APP_API_BASE_URL}api/accounts/current/featureflags/all/enabled`,
    });
    const { data } = response;
    setFeatureFlags(data.featureFlags);
  };

  useEffect(() => {
    getFeatureFlags();
  }, []);

  return (
    <FeatureFlagContext.Provider value={featureFlags}>
      {children}
    </FeatureFlagContext.Provider>
  );
};

import ModuleLayout from "src/layouts/shared/layout/ModuleLayout";

import Sidebar from "../Sidebar";

const RangeReviewLayout = () => (
  <>
    <ModuleLayout SidebarComponent={<Sidebar />} />
  </>
);

export default RangeReviewLayout;

import { useEffect, useState } from "react";

import { Box, CircularProgress } from "@mui/material";
import axios, { AxiosResponse } from "axios";
import { Helmet } from "react-helmet-async";
import { Outlet } from "react-router-dom";
import ModuleMenu from "src/layouts/shared/ModuleMenu";
import { Module } from "src/models/module";

import MiaNavigationBar from "./NavigationBar";
import Terms from "./Terms";

import "./index.css";

export interface TermsAcceptanceResponse {
  hasAcceptedTerms: boolean;
}

const MiaLayout = () => {
  const [hasAcceptedTerms, setHasAcceptedTerms] = useState(false);
  const [loading, setLoading] = useState(true);
  const [moduleMenuOpen, setModuleMenuOpen] = useState(false);

  const toggleDrawer = () => {
    setModuleMenuOpen(!moduleMenuOpen);
  };

  const fetchUserTerms = async () => {
    try {
      const termsResponse: AxiosResponse<TermsAcceptanceResponse> =
        await axios.get(
          ` ${process.env.REACT_APP_API_BASE_URL}api/accounts/current/terms/${Module.MIA}`,
        );
      setHasAcceptedTerms(termsResponse.data.hasAcceptedTerms);
    } catch (error) {
      console.error("Error fetching user terms:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchUserTerms();
  }, []);

  if (loading)
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        width="100%"
        height="100vh"
      >
        <CircularProgress size={60} />
      </Box>
    );

  if (!loading && !hasAcceptedTerms) {
    return <Terms setHasAcceptedTerms={setHasAcceptedTerms} />;
  }

  return (
    <>
      <Helmet title="MIA" />
      <div className={`container ${moduleMenuOpen ? "drawer-open" : ""}`}>
        {/* Module Menu */}
        <aside className={`sidenav ${moduleMenuOpen ? "open" : ""}`}>
          <ModuleMenu />
        </aside>

        {/* Main content */}
        <div id="main">
          <header>
            <MiaNavigationBar toggleDrawer={toggleDrawer} />
          </header>
          <section>
            <Outlet />
          </section>
        </div>
      </div>
    </>
  );
};

export default MiaLayout;

import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import axios, { AxiosResponse } from "axios";
import { TaskType } from "src/content/aani/negotiations/assisted/Workspace/RightSection/Tasks/Tasks";
import {
  EventStatus,
  EventType,
} from "src/content/aani/negotiations/assisted/Workspace/RightSection/Timeline/Timeline";
import { AppThunk } from "src/store";

import { Negotiation } from "./model";

const API_PREFIX = `${process.env.REACT_APP_API_BASE_URL}api/aani/assist`;

export interface NegotiationChatMessageMetadata {
  reason: string;
  exampleAnswer: string;
}

export interface NegotiationChatMessage {
  id?: string;
  msg: string;
  date: string;
  isAIMessage: boolean;
  isRated: boolean;
  metadata?: NegotiationChatMessageMetadata;
}

export enum NegotiationChatRunStatus {
  THINKING = "THINKING",
  COMPLETED = "COMPLETED",
  ERROR = "ERROR",
  CANCELLED = "CANCELLED",
}

export interface NegotiationAction {
  actionId: string;
  runId: string;
  type: string;
  title: string;
  group: string;
  inputData: string;
  result: string;
  resultType: string;
  feedbackGiven: boolean;
}

export enum NegotiationStageStatus {
  PENDING = "PENDING",
  IN_PROGRESS = "IN_PROGRESS",
  COMPLETED = "COMPLETED",
}

export interface NegotiationStage {
  stage: string;
  status: NegotiationStageStatus;
  result: string;
  currentRunId: string;
}

export interface NegotiationActionsResponse {
  actions: NegotiationAction[];
  pageSize: number;
  pageNumber: number;
}

export interface NegotiationChatResponse {
  messages: NegotiationChatMessage[];
  runStatus: NegotiationChatRunStatus;
}

export interface NegotiationEventResponse {
  events: EventType[];
}

export interface NegotiationTaskResponse {
  tasks: TaskType[];
}

export enum NegotiationMessageResponseType {
  TEXT = "TEXT",
}

export interface NegotiationMessageResponseResult {
  id?: string;
  msg: string;
  status: NegotiationChatRunStatus;
  responseType: NegotiationMessageResponseType;
  metadata?: Record<string, string | number>;
  replyToUserMessageId: string;
}

interface WorkspaceState {
  negotiationId: string;
  negotiation: Negotiation;
  messages: NegotiationChatMessage[];
  actions: NegotiationAction[];
  lastMessageId: string;
  isProcessingMessage: boolean;
  hasLoadedPage: boolean;
  runTour: boolean;
}

const initialState: WorkspaceState = {
  negotiationId: null,
  negotiation: null,
  messages: [],
  actions: [],
  lastMessageId: null,
  isProcessingMessage: false,
  hasLoadedPage: false,
  runTour: false,
};

const slice = createSlice({
  name: "aani-workspace",
  initialState,
  reducers: {
    loadNegotiation(state, action: PayloadAction<Negotiation>) {
      const negotiation = action.payload;
      return {
        ...state,
        negotiation,
        negotiationId: negotiation.id,
      };
    },
    showAction(state, action) {
      const { actionName } = action.payload;
      return {
        ...state,
        action: actionName,
        showWorkspace: true,
      };
    },
    loadMessages(state, action: PayloadAction<NegotiationChatMessage[]>) {
      return {
        ...state,
        messages: action.payload,
      };
    },
    loadNewMessage(state, action: PayloadAction<NegotiationChatMessage>) {
      return {
        ...state,
        messages: [...state.messages, action.payload],
      };
    },
    loadActions(state, action: PayloadAction<NegotiationAction[]>) {
      return {
        ...state,
        actions: action.payload,
      };
    },
    loadLastMessageId(state, action: PayloadAction<string>) {
      return {
        ...state,
        lastMessageId: action.payload,
      };
    },
    loadNewMessageWithId(
      state,
      action: PayloadAction<{
        message: NegotiationChatMessage;
        messageId: string;
      }>,
    ) {
      return {
        ...state,
        messages: [...state.messages, action.payload.message],
        lastMessageId: action.payload.messageId,
      };
    },
    setProcessingState(state, action: PayloadAction<boolean>) {
      return {
        ...state,
        isProcessingMessage: action.payload,
      };
    },
    setHasLoadedPage(state, action: PayloadAction<boolean>) {
      return {
        ...state,
        hasLoadedPage: action.payload,
      };
    },
    setRunTour(state, action: PayloadAction<boolean>) {
      state.runTour = action.payload;
    },

    updateMessagesAfterCancel(state) {
      const { lastMessageId } = state;
      const indexOflastMessageId = state.messages.findIndex(
        (element) => element.id === lastMessageId,
      );

      if (indexOflastMessageId !== -1) {
        const updatedMessages = state.messages.slice(0, indexOflastMessageId);
        return {
          ...state,
          messages: updatedMessages,
        };
      }
      return state;
    },
    clearNegotiation(state) {
      return {
        ...state,
        negotiationId: null,
        negotiation: null,
        messages: [],
        actions: [],
        lastMessageId: null,
        isProcessingMessage: false,
        hasLoadedPage: false,
      };
    },
  },
});

// ----------------------------------------------------- //
// ------------------- HELPER FUNCTIONS ---------------- //
const loadUserMessage =
  (message: string, messageId: string): AppThunk =>
  async (dispatch) => {
    const userMessage: NegotiationChatMessage = {
      id: messageId,
      msg: message,
      date: new Date().toISOString(),
      isAIMessage: false,
      isRated: false,
    };
    dispatch(
      slice.actions.loadNewMessageWithId({ message: userMessage, messageId }),
    );
  };

// ---------------------------------------------------- //
// ------------------- GET REQUESTS ------------------- //

export const loadNegotiationApi =
  (negotiationId: string): AppThunk =>
  async (dispatch) => {
    const response = await axios({
      method: "GET",
      url: `${API_PREFIX}/negotiations/${negotiationId}`,
    });
    const payload = response.data as Negotiation;
    dispatch(slice.actions.loadNegotiation(payload));
  };

export const getNegotiationActions =
  (negotiationId: string): AppThunk =>
  async (dispatch) => {
    try {
      const response: AxiosResponse<NegotiationActionsResponse> =
        await axios.get(`${API_PREFIX}/negotiations/${negotiationId}/actions`);
      const { actions } = response.data;
      dispatch(slice.actions.loadActions(actions));
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

export const getNegotiationMessages =
  (negotiationId: string): AppThunk =>
  async (dispatch) => {
    try {
      const response: AxiosResponse<NegotiationChatResponse> = await axios.get(
        `${API_PREFIX}/negotiations/${negotiationId}/messages`,
      );
      const { messages } = response.data;
      dispatch(slice.actions.loadMessages(messages));

      if (messages && messages.length > 0) {
        const lastMessage = messages[messages.length - 1];
        // Case when the last message is a user message
        if (!lastMessage.isAIMessage) {
          const lastMessageId = lastMessage.id;
          dispatch(slice.actions.loadLastMessageId(lastMessageId));
        }
        // Case when the last message is an AI message
        else if (messages.length >= 2) {
          const lastUserMessage = messages[messages.length - 2];
          const lastMessageId = lastUserMessage.id;
          dispatch(slice.actions.loadLastMessageId(lastMessageId));
        }
      }
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

export const getMessageActions =
  (negotiationId: string, messageId: string): AppThunk =>
  async (dispatch) => {
    try {
      const response: AxiosResponse<NegotiationActionsResponse> =
        await axios.get(
          `${API_PREFIX}/negotiations/${negotiationId}/messages/${messageId}/actions`,
        );
      const { actions } = response.data;
      dispatch(slice.actions.loadActions(actions));
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

export const getResponseToUserMessage =
  (negotiationId: string, messageId: string): AppThunk =>
  async (dispatch) => {
    try {
      const response: AxiosResponse<NegotiationMessageResponseResult> =
        await axios.get(
          `${API_PREFIX}/negotiations/${negotiationId}/messages/${messageId}/response`,
        );

      const { id, msg, status } = response.data;

      if (status === NegotiationChatRunStatus.CANCELLED) {
        dispatch(slice.actions.setProcessingState(false));
      }
      if (status === NegotiationChatRunStatus.ERROR) {
        dispatch(slice.actions.setProcessingState(false));
        // TODO: Show error message to user
        console.error("Error processing user message");
      }
      if (status === NegotiationChatRunStatus.COMPLETED) {
        const responseMessage: NegotiationChatMessage = {
          id,
          msg,
          date: new Date().toISOString(),
          isAIMessage: true,
          isRated: false,
        };
        dispatch(slice.actions.loadNewMessage(responseMessage));
        dispatch(slice.actions.setProcessingState(false));
      }
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

export const getNegotiationEvents = async (
  negotiationId: string,
): Promise<EventType[]> => {
  try {
    const response: AxiosResponse<NegotiationEventResponse> = await axios.get(
      `${API_PREFIX}/negotiations/${negotiationId}/events`,
    );
    return response.data.events;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const getNegotiationTasks = async (
  negotiationId: string,
): Promise<TaskType[]> => {
  try {
    const response: AxiosResponse<NegotiationTaskResponse> = await axios.get(
      `${API_PREFIX}/negotiations/${negotiationId}/tasks`,
    );
    return response.data.tasks;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

// ---------------------------------------------------- //
// ------------------- POST REQUESTS ------------------ //

export const processUserMessage =
  (negotiationId: string, message: string): AppThunk =>
  async (dispatch) => {
    // Process user message from the chat box in Chat View
    try {
      const response: AxiosResponse<{ messageId: string }> = await axios.post(
        `${API_PREFIX}/negotiations/${negotiationId}/messages`,
        {
          message,
        },
      );
      dispatch(loadUserMessage(message, response.data.messageId));
      dispatch(slice.actions.setProcessingState(true));
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

export const processUserResponse =
  (negotiationId: string, input: string): AppThunk =>
  async (dispatch) => {
    // Process user response from user input box in Activity View
    try {
      const response: AxiosResponse<{ messageId: string }> = await axios.post(
        `${API_PREFIX}/negotiations/${negotiationId}/response`,
        {
          response: input,
        },
      );
      dispatch(loadUserMessage(input, response.data.messageId));
      dispatch(slice.actions.setProcessingState(true));
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

export const createNegotiationEvent =
  (negotiationId: string, event: EventType) => async () => {
    try {
      const response: AxiosResponse<NegotiationEventResponse> =
        await axios.post(`${API_PREFIX}/negotiations/${negotiationId}/events`, {
          event,
        });
      return response.data.events;
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

export const updateNegotiationEvent = async (
  negotiationId: string,
  event: EventType,
) => {
  try {
    await axios.put(
      `${API_PREFIX}/negotiations/${negotiationId}/events/${event.id}`,
      {
        event,
      },
    );
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const updateNegotiationEventStatus = async (
  negotiationId: string,
  eventId: number,
  status: EventStatus,
) => {
  try {
    await axios.patch(
      `${API_PREFIX}/negotiations/${negotiationId}/events/${eventId}`,
      {
        status,
      },
    );
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const deleteNegotiation =
  (negotiationId: string): AppThunk<Promise<void>> =>
  async () => {
    try {
      await axios.delete(`${API_PREFIX}/negotiations/${negotiationId}`);
    } catch (error) {
      console.error("Failed to delete negotiation:", error);
    }
  };

export const deleteNegotiationEvent = async (
  negotiationId: string,
  eventId: number,
) => {
  try {
    await axios.delete(
      `${API_PREFIX}/negotiations/${negotiationId}/events/${eventId}`,
    );
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const createNegotiationTask = async (
  negotiationId: string,
  task: TaskType,
) => {
  try {
    const response: AxiosResponse<NegotiationTaskResponse> = await axios.post(
      `${API_PREFIX}/negotiations/${negotiationId}/tasks`,
      { task },
    );
    console.log("response", response.data.tasks);
    return response.data.tasks;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const updateNegotiationTask = async (
  negotiationId: string,
  task: TaskType,
) => {
  try {
    await axios.put(
      `${API_PREFIX}/negotiations/${negotiationId}/tasks/${task.id}`,
      { task },
    );
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const deleteNegotiationTask = async (
  negotiationId: string,
  taskId: string,
) => {
  try {
    await axios.delete(
      `${API_PREFIX}/negotiations/${negotiationId}/tasks/${taskId}`,
    );
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const cancelMessage =
  (negotiationId: string, messageId: string): AppThunk =>
  async (dispatch) => {
    try {
      await axios.post(
        `${API_PREFIX}/negotiations/${negotiationId}/messages/${messageId}/cancel`,
      );
      dispatch(slice.actions.updateMessagesAfterCancel());
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

export const resendMessage =
  (negotiationId: string, messageId: string): AppThunk =>
  async (dispatch) => {
    try {
      await axios.post(
        `${API_PREFIX}/negotiations/${negotiationId}/messages/${messageId}/resend`,
      );
      dispatch(slice.actions.setProcessingState(true));
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

export const { reducer } = slice;

export default slice;

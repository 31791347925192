import { ReactNode, useState } from "react";

import { Navigate, useLocation } from "react-router-dom";
import Login from "src/content/aani/pages/Auth/Login/Cover";
import useAuth from "src/hooks/useAuth";

import SessionExpiredModal from "../SessionExpiredModal";

const Authenticated = ({ children }: { children: ReactNode }) => {
  const auth = useAuth();
  const location = useLocation();
  const [requestedLocation, setRequestedLocation] = useState(null);

  if (!auth.isAuthenticated) {
    if (location.pathname !== requestedLocation) {
      setRequestedLocation(location.pathname);
    }
    if (auth.showSessionExpiredModal) {
      return (
        <SessionExpiredModal
          open={auth.showSessionExpiredModal}
          login={auth.login}
        />
      );
    }
    return <Login />;
  }

  if (requestedLocation && location.pathname !== requestedLocation) {
    setRequestedLocation(null);
    return <Navigate to={requestedLocation} />;
  }

  return <>{children}</>;
};

export default Authenticated;

import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { AgentRunStatus } from "src/models/agent";
import { ChatUser } from "src/models/chatuser";
import { AutomatedNegotiationState } from "src/models/negotiation";
import { Offer } from "src/models/offer";
import { RequestPreferences } from "src/models/requestpreferences";
import { AppThunk } from "src/store";

import type { PayloadAction } from "@reduxjs/toolkit";

export interface DealState {
  negotiation: Negotiation;
  messages: DealChatMessage[];
  currentOffer?: Offer;
  action?: NegotiationAction;
  variables: NegotiationVariable[];
  progressStatus: string;
  negotiationStatus: AutomatedNegotiationState;
  preferencesProvided: boolean;
  runId: string;
  isSubmitting: boolean;
  isProcessing: boolean;
}

export interface DealChatMessage {
  id: string;
  msg: string;
  is_aani_message?: boolean;
  reply_to_supplier_message_id?: string;
  is_rated?: boolean;
  date: string;
  offer?: Offer;
  requestPreferences?: RequestPreferences;
  from?: ChatUser;
  metadata?: {
    action: NegotiationAction;
    offer_id?: number;
    offer?: Offer;
  } | null;
}

export interface Negotiation {
  id: string;
  title: string;
  messages: DealChatMessage[];
  accountId: string;
  preferencesProvided: boolean;
  agentRunStatus: AgentRunStatus;
  negotiationStatus: AutomatedNegotiationState;
  createdAt: string;
  expiresAt: string;
  isParentNegotiation: boolean;
  isExpired: boolean;
}

export enum NegotiationAction {
  SHOW_PREFERENCES = "SHOW_PREFERENCES",
  SHOW_OFFER = "SHOW_OFFER",
  AGREEMENT_REACHED = "AGREEMENT_REACHED",
  REQUESTED_ESCALATION = "REQUESTED_ESCALATION",
}

export interface NegotiationVariable {
  identifier: string;
  name: string;
  unit: string;
  domain_min: number | null;
  domain_max: number | null;
  allow_preference_selection: boolean;
}

export interface AaniResponse {
  id?: string | null;
  msg: string;
  responseType: string;
  replyToSupplierMessageId: string;
  automatedNegotiationId: string;
  metadata?: {
    action: NegotiationAction;
    offer_id?: number;
    offer?: Offer;
  } | null;
}

const initialNegotiation: Negotiation = {
  id: "",
  title: "",
  messages: [],
  accountId: "",
  preferencesProvided: false,
  agentRunStatus: AgentRunStatus.INITIALISING,
  negotiationStatus: null,
  createdAt: null,
  expiresAt: null,
  isParentNegotiation: false,
  isExpired: false,
};

const initialState: DealState = {
  negotiation: initialNegotiation,
  messages: [],
  negotiationStatus: AutomatedNegotiationState.INITIALISED,
  action: null,
  variables: [],
  progressStatus: "preferences",
  preferencesProvided: false,
  runId: null,
  isSubmitting: false,
  isProcessing: false,
};

const slice = createSlice({
  name: "deal",
  initialState,
  reducers: {
    loadStates(state, action) {
      return {
        ...state,
        id: action.payload.id,
        messages: action.payload.messages,
        preferencesProvided: action.payload.preferencesProvided,
        negotiationStatus: action.payload.negotiationStatus,
      };
    },
    loadNegotiation(state, action: PayloadAction<Negotiation>) {
      return {
        ...state,
        negotiation: action.payload,
      };
    },
    loadNewMessages(state, action: PayloadAction<DealChatMessage[]>) {
      const { messages } = state;
      const newMessages = [...messages, ...action.payload];
      return {
        ...state,
        messages: newMessages,
      };
    },
    loadCurrentAgentRun(state, action: PayloadAction<string>) {
      return {
        ...state,
        runId: action.payload,
      };
    },
    loadCurrentOffer(state, action: PayloadAction<Offer>) {
      return {
        ...state,
        currentOffer: action.payload,
      };
    },
    loadSellerSelectedPreferencesVariableIds(
      state,
      action: PayloadAction<string[]>,
    ) {
      return {
        ...state,
        sellerSelectedPreferencesVariableIds: action.payload,
      };
    },
    loadProgressStatus(state, action: PayloadAction<string>) {
      return {
        ...state,
        progressStatus: action.payload,
      };
    },
    loadNegotiationAction(state, action: PayloadAction<NegotiationAction>) {
      return {
        ...state,
        action: action.payload,
      };
    },
    loadVariables(state, action: PayloadAction<NegotiationVariable[]>) {
      return {
        ...state,
        variables: action.payload,
      };
    },
    loadNegotiationStatus(
      state,
      action: PayloadAction<AutomatedNegotiationState>,
    ) {
      return {
        ...state,
        negotiationStatus: action.payload,
      };
    },
    setIsSubmitting(state, action: PayloadAction<boolean>) {
      return {
        ...state,
        isSubmitting: action.payload,
      };
    },
    setIsProcessing(state, action: PayloadAction<boolean>) {
      return {
        ...state,
        isProcessing: action.payload,
      };
    },
    updateMessagesAfterCancel(state) {
      const { runId } = state; // this will always be last message send
      const indexOfRunId = state.messages.findIndex(
        (element) => element.id === runId,
      );

      if (indexOfRunId !== -1) {
        const updatedMessages = state.messages.slice(0, indexOfRunId);
        return {
          ...state,
          messages: updatedMessages,
        };
      }
      return state;
    },
  },
});

export const loadOffer =
  (negotiationId: string): AppThunk =>
  async (dispatch) => {
    try {
      const response = await axios({
        headers: {
          "Content-Type": "application/json",
          "Cache-Control": "no-cache",
        },
        method: "GET",
        url: `${process.env.REACT_APP_API_BASE_URL}api/deal/negotiation/${negotiationId}/offer/`,
      });

      const { currentOffer }: { currentOffer: Offer } = response.data;

      if (currentOffer) {
        dispatch(slice.actions.loadCurrentOffer(currentOffer));
      }
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

export const loadNegotiationVariables =
  (negotiationId: string): AppThunk =>
  async (dispatch) => {
    try {
      const response = await axios({
        headers: {
          "Content-Type": "application/json",
          "Cache-Control": "no-cache",
        },
        method: "GET",
        url: `${process.env.REACT_APP_API_BASE_URL}api/deal/negotiation/${negotiationId}/variables`,
      });

      const { variables }: { variables: NegotiationVariable[] } = response.data;
      dispatch(slice.actions.loadVariables(variables));
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

export const loadNegotiation =
  (negotiationId: string): AppThunk =>
  async (dispatch) => {
    const response = await axios({
      headers: {
        "Content-Type": "application/json",
        "Cache-Control": "no-cache",
      },
      method: "GET",
      url: `${process.env.REACT_APP_API_BASE_URL}api/deal/negotiation/${negotiationId}`,
    });

    const {
      id,
      messages,
      preferencesProvided,
      agentRunStatus,
      negotiationStatus,
    } = response.data;
    const negotiation: Negotiation = response.data;

    dispatch(
      slice.actions.loadStates({
        id,
        messages,
        preferencesProvided,
        negotiationStatus,
      }),
    );
    dispatch(slice.actions.loadNegotiation(negotiation));

    // Handle loading latest offer if one exist in the negotiation
    dispatch(loadOffer(negotiationId));

    const lastMessage = messages[messages.length - 1];

    // Handle dispatch last message action if one exist
    dispatch(slice.actions.loadNegotiationAction(null));
    if (lastMessage?.metadata?.action) {
      dispatch(
        slice.actions.loadNegotiationAction(lastMessage.metadata.action),
      );
    }

    // Handle showing preferences if preferences are not provided
    // and the latest message action is SHOW_PREFERENCES
    if (
      !preferencesProvided &&
      lastMessage?.metadata?.action === NegotiationAction.SHOW_PREFERENCES
    ) {
      dispatch(loadNegotiationVariables(negotiationId));
    }

    // Dispatch runId if there is user message in session
    // and runId should be the id of the last user message
    if (messages && messages.length > 0) {
      if (!lastMessage.is_aani_message) {
        const runId = lastMessage.id;
        dispatch(slice.actions.loadCurrentAgentRun(runId));
      } else if (messages.length >= 2) {
        const lastUserMessage = messages[messages.length - 2];
        const runId = lastUserMessage.id;
        dispatch(slice.actions.loadCurrentAgentRun(runId));
      }
    }

    // Handle the case where the user refresh the page
    // We dispatch isProcessing to continue polling
    // for agent actions and response
    if (agentRunStatus === AgentRunStatus.THINKING)
      dispatch(slice.actions.setIsProcessing(true));
  };

export const sendMessage =
  (message: DealChatMessage, negotiationId: string): AppThunk =>
  async (dispatch) => {
    dispatch(slice.actions.setIsSubmitting(true));

    const response = await axios({
      method: "POST",
      url: `${process.env.REACT_APP_API_BASE_URL}api/deal/negotiation/${negotiationId}/message`,
      data: {
        message: message.msg,
      },
    });

    const { runId } = response.data;

    const userMessage: DealChatMessage = {
      id: runId,
      msg: message.msg,
      is_aani_message: false,
      date: message.date,
    };

    dispatch(slice.actions.loadNewMessages([userMessage]));
    dispatch(slice.actions.loadCurrentAgentRun(runId));

    dispatch(slice.actions.setIsProcessing(true));
    dispatch(slice.actions.setIsSubmitting(false));
  };

export const loadResponse =
  (negotiationId: string, runId: string): AppThunk =>
  async (dispatch) => {
    const supplierMessageId = runId;
    try {
      const response = await axios({
        headers: {
          "Content-Type": "application/json",
          "Cache-Control": "no-cache",
        },
        method: "GET",
        url: `${process.env.REACT_APP_API_BASE_URL}api/deal/negotiation/${negotiationId}/response/${supplierMessageId}`,
      });

      const {
        id,
        msg,
        responseType,
        metadata,
        replyToSupplierMessageId,
      }: AaniResponse = response.data;

      if (responseType === "CANCELLED") {
        dispatch(slice.actions.setIsProcessing(false));
        return response.data;
      }

      // Handle the preference selection response
      // which contains action field in metadata
      if (metadata?.action) {
        dispatch(slice.actions.loadNegotiationAction(metadata.action));

        if (metadata.action === NegotiationAction.SHOW_PREFERENCES) {
          dispatch(loadNegotiationVariables(negotiationId));
        } else if (metadata.action === NegotiationAction.SHOW_OFFER) {
          dispatch(slice.actions.loadCurrentOffer(metadata.offer));
        }
      }

      const responseMessage: DealChatMessage = {
        id,
        msg,
        is_aani_message: true,
        date: new Date().toString(),
        is_rated: false,
        reply_to_supplier_message_id: replyToSupplierMessageId,
        // offer: metadata?.offer,
      };

      if (responseType === "ERROR") {
        dispatch(slice.actions.loadNewMessages([responseMessage]));
        dispatch(slice.actions.setIsProcessing(false));
        return response.data;
      }

      if (responseType !== "THINKING") {
        // Handle the agent has returned a response
        dispatch(slice.actions.loadNewMessages([responseMessage]));
        dispatch(slice.actions.setIsProcessing(false));

        // Loading if offer is created for the current negotiation
        dispatch(loadOffer(negotiationId));
      } else {
        // Handle the agent is still thinking
        dispatch(slice.actions.setIsProcessing(true));
      }

      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

export const submitPreferencesSelectionApi =
  (negotiationId: string, selectedVariableIdentifiers: string[]): AppThunk =>
  async (dispatch) => {
    dispatch(slice.actions.setIsSubmitting(true));

    const response = await axios({
      method: "POST",
      url: `${process.env.REACT_APP_API_BASE_URL}api/deal/negotiation/${negotiationId}/preferences`,
      data: { selectedVariablesIds: selectedVariableIdentifiers },
    });

    const { runId, msg } = response.data;

    // if (offer) dispatch(slice.actions.loadCurrentOffer(offer));

    dispatch(
      slice.actions.loadSellerSelectedPreferencesVariableIds(
        selectedVariableIdentifiers,
      ),
    );

    const userMessage: DealChatMessage = {
      id: runId,
      msg,
      is_aani_message: false,
      date: new Date().toISOString(),
    };

    dispatch(slice.actions.loadNewMessages([userMessage]));
    dispatch(slice.actions.loadNegotiationAction(null));
    dispatch(slice.actions.setIsSubmitting(false));

    // After receiving the preference response
    // We need to wait for the agent to generate offer
    // So we set the state isProcessing to true and update runId of new agent run
    // to poll for new agent response
    dispatch(slice.actions.loadCurrentAgentRun(runId));
    dispatch(slice.actions.setIsProcessing(true));
  };

export const confirmAgreement =
  (isAgreed: boolean, negotiationId: string): AppThunk =>
  async (dispatch) => {
    dispatch(slice.actions.setIsSubmitting(true));

    const response = await axios({
      method: "POST",
      url: `${process.env.REACT_APP_API_BASE_URL}api/deal/negotiation/${negotiationId}/agreement`,
      data: {
        isAgreed,
      },
    });

    const {
      messageId,
      responseId,
      userMessage: userMsg,
      responseMessage: responseMsg,
      negotiationStatus,
    } = response.data;
    const userMessage: DealChatMessage = {
      id: messageId,
      msg: userMsg,
      is_aani_message: false,
      date: new Date().toISOString(),
    };
    dispatch(slice.actions.loadNewMessages([userMessage]));

    // We only return responseId if the user agree with the offer
    // Otherwise, we send a new StartAgentCommand in backend
    // and return responseId as Null
    if (!responseId) {
      // We will fetch agent response if the user disagree with the offer
      // by updataing current agent run runId
      dispatch(slice.actions.loadCurrentAgentRun(messageId));
      dispatch(slice.actions.setIsProcessing(true));
    } else {
      const responseMessage: DealChatMessage = {
        id: responseId,
        msg: responseMsg,
        is_aani_message: true,
        date: new Date().toString(),
        is_rated: false,
      };
      dispatch(slice.actions.loadNewMessages([responseMessage]));
    }

    dispatch(slice.actions.loadNegotiationStatus(negotiationStatus));
    dispatch(slice.actions.loadNegotiationAction(null));
    dispatch(slice.actions.setIsSubmitting(false));
  };

export const cancelMessageAction =
  (): AppThunk => async (dispatch, getState) => {
    const { runId } = getState().deal;
    try {
      await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}api/deal/negotiation/${runId}/cancel`,
        {},
      );
      dispatch(slice.actions.updateMessagesAfterCancel());
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

export const { reducer } = slice;

export default slice;

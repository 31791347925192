import React, { useRef, useEffect } from "react";

import { Box, useTheme } from "@mui/material";
import { Scrollbars } from "react-custom-scrollbars-2";

type ScrollbarProps = {
  children: React.ReactNode;
  scrollToBottomOnChange?: boolean;
  style?: React.CSSProperties;
};

const Scrollbar = ({
  children,
  scrollToBottomOnChange = false,
  style,
  ...rest
}: ScrollbarProps) => {
  const theme = useTheme();
  const scrollbarRef = useRef<Scrollbars | null>(null);

  const scrollToBottom = () => {
    if (scrollbarRef.current) {
      scrollbarRef.current.scrollToBottom();
    }
  };

  useEffect(() => {
    // Scroll to the bottom of the content when children change and scrollToBottomOnChange is true
    if (scrollToBottomOnChange) {
      scrollToBottom();
    }
  }, [children, scrollToBottomOnChange]);

  return (
    <Scrollbars
      autoHide
      renderThumbVertical={() => (
        <Box
          sx={{
            width: 10,
            background: `${theme.palette.primary.dark}`,
            "&:hover": {
              background: `${theme.palette.primary.dark}`,
            },
          }}
        />
      )}
      ref={(ref) => {
        scrollbarRef.current = ref;
      }}
      style={style}
      {...rest}
    >
      {children}
    </Scrollbars>
  );
};

Scrollbar.defaultProps = {
  scrollToBottomOnChange: false,
  style: {},
};

export default Scrollbar;

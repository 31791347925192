import MenuIcon from "@mui/icons-material/Menu";
import { Box, Grid, IconButton, Typography, useTheme } from "@mui/material";
import ChartButton from "src/content/insight/Chart/ChartButton";
import ClusterInformationButton from "src/content/insight/ClusterInformation/ClusterInformationButton";
import KnowledgeBankMenu from "src/content/insight/KnowledgeBankMenu";
import HeaderUserbox from "src/layouts/shared/Header/Userbox";

const NavigationBar = ({ toggleDrawer }: { toggleDrawer: () => void }) => {
  const theme = useTheme();

  return (
    <Grid
      container
      direction="row"
      height="100%"
      paddingX="16px"
      color={theme.palette.common.white}
      paddingY={0}
      sx={{
        justifyContent: "space-between",
        alignItems: "center",
        backgroundColor: theme.palette.common.white,
      }}
    >
      <Grid item xs={8} display="flex" alignItems="center" columnGap="16px">
        <IconButton
          size="medium"
          onClick={toggleDrawer}
          color="primary"
          sx={{
            borderRadius: "50%",
          }}
        >
          <MenuIcon
            fontSize="small"
            sx={{ color: theme.colors.primary.main }}
          />
        </IconButton>
        <Typography variant="h5" sx={{ color: theme.colors.secondary.main }}>
          Insight
        </Typography>
        <KnowledgeBankMenu />
      </Grid>
      <Grid
        item
        xs={4}
        display="flex"
        alignItems="center"
        justifyContent="flex-end"
        columnGap="8px"
      >
        <Box display="flex" gap="4px">
          <Box sx={{ display: { xs: "block", md: "none" } }}>
            <ChartButton />
          </Box>
          <ClusterInformationButton />
        </Box>
        <HeaderUserbox />
      </Grid>
    </Grid>
  );
};

export default NavigationBar;

import { createSlice } from "@reduxjs/toolkit";
import axios, { AxiosResponse } from "axios";
import { AgentRunStatus } from "src/models/agent";
import { ResponseType } from "src/models/response";
import { AppThunk } from "src/store";

import type { PayloadAction } from "@reduxjs/toolkit";

const API_PREFIX = `${process.env.REACT_APP_API_BASE_URL}api/aani/range-review`;

export interface AssistanceMessage {
  id: string;
  msg: string;
  date: string;
  isAaniMessage: boolean;
}

export interface AssistanceSessionResponse {
  messages: AssistanceMessage[];
  agentRunStatus: AgentRunStatus;
}

export interface SendMessageResponse {
  runId: string;
}

export interface AssistanceState {
  messages: AssistanceMessage[];
  runId: string;
  isProcessing: boolean;
  agentRunStatus: AgentRunStatus;
}

const initialState: AssistanceState = {
  messages: [],
  runId: null,
  isProcessing: false,
  agentRunStatus: AgentRunStatus.INITIALISING,
};

const slice = createSlice({
  name: "range-review-assistance",
  initialState,
  reducers: {
    loadAssistanceSession(
      state,
      action: PayloadAction<AssistanceSessionResponse>,
    ) {
      const { messages, agentRunStatus } = action.payload;
      return {
        ...state,
        messages,
        agentRunStatus,
      };
    },

    loadNewMessages(state, action: PayloadAction<AssistanceMessage[]>) {
      const { messages } = state;
      const newMessages = [...messages, ...action.payload];
      return {
        ...state,
        messages: newMessages,
      };
    },
    loadCurrentAgentRun(state, action: PayloadAction<string>) {
      return {
        ...state,
        runId: action.payload,
      };
    },
    updateMessagesAfterCancel(state) {
      const { runId } = state;
      const indexOfRunId = state.messages.findIndex(
        (element) => element.id === runId,
      );
      if (indexOfRunId !== -1) {
        const updatedMessages = state.messages.slice(0, indexOfRunId);
        return {
          ...state,
          messages: updatedMessages,
        };
      }
      return state;
    },
    setIsProcessing(state, action: PayloadAction<boolean>) {
      return {
        ...state,
        isProcessing: action.payload,
      };
    },
  },
});

export const fetchAssistanceSession =
  (negotiationId: string): AppThunk =>
  async (dispatch) => {
    // Reset the messages state
    dispatch(slice.actions.loadAssistanceSession(initialState));

    const response: AxiosResponse<AssistanceSessionResponse> = await axios.get(
      `${API_PREFIX}/${negotiationId}/assistance`,
    );

    dispatch(slice.actions.loadAssistanceSession(response.data));

    const { messages, agentRunStatus } = response.data;

    // Dispatch runId if there is user message in session
    // and runId should be the id of the last user message
    if (messages && messages.length > 0) {
      const lastMessage = messages[messages.length - 1];
      if (!lastMessage.isAaniMessage) {
        const runId = lastMessage.id;
        dispatch(slice.actions.loadCurrentAgentRun(runId));
      } else if (messages.length >= 2) {
        const lastUserMessage = messages[messages.length - 2];
        const runId = lastUserMessage.id;
        dispatch(slice.actions.loadCurrentAgentRun(runId));
      }
    }

    // Handle the case where the user refresh the page
    // We dispatch isProcessing to continue polling
    // for agent actions and response
    if (
      agentRunStatus === AgentRunStatus.THINKING ||
      agentRunStatus === AgentRunStatus.INITIALISING
    )
      dispatch(slice.actions.setIsProcessing(true));
  };

export const sendMessage =
  (negotiationId: string, message: AssistanceMessage): AppThunk =>
  async (dispatch) => {
    try {
      const response: AxiosResponse<SendMessageResponse> = await axios.post(
        `${API_PREFIX}/${negotiationId}/assistance/message`,
        {
          message: message.msg,
        },
      );

      const { runId } = response.data;

      const userMessage: AssistanceMessage = {
        id: runId,
        msg: message.msg,
        isAaniMessage: false,
        date: new Date().toISOString(),
      };

      dispatch(slice.actions.loadNewMessages([userMessage]));
      dispatch(slice.actions.loadCurrentAgentRun(runId));

      dispatch(slice.actions.setIsProcessing(true));
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

export const loadResponse =
  (negotiationId: string, runId: string): AppThunk =>
  async (dispatch) => {
    dispatch(slice.actions.setIsProcessing(true));

    try {
      const response = await axios.get(
        `${API_PREFIX}/${negotiationId}/assistance/response?runId=${runId}`,
        {
          headers: {
            "Content-Type": "application/json",
            "Cache-Control": "no-cache",
          },
        },
      );

      const { id, msg, responseType } = response.data;

      if (responseType === ResponseType.CANCELLED) {
        dispatch(slice.actions.setIsProcessing(false));
        return response.data;
      }

      const responseMessage: AssistanceMessage = {
        id,
        msg,
        isAaniMessage: true,
        date: new Date().toISOString(),
      };

      if (responseType === ResponseType.ERROR) {
        dispatch(slice.actions.loadNewMessages([responseMessage]));
        dispatch(slice.actions.setIsProcessing(false));
        return response.data;
      }

      if (responseType !== ResponseType.THINKING) {
        // Handle the agent has returned a response
        dispatch(slice.actions.loadNewMessages([responseMessage]));
        dispatch(slice.actions.setIsProcessing(false));
      }

      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

export const cancelMessageAction =
  (runId: string): AppThunk =>
  async (dispatch) => {
    try {
      await axios.post(
        `${API_PREFIX}/assistance/cancel`,
        {
          runId,
        },
        {
          headers: {
            "Content-Type": "application/json",
            "Cache-Control": "no-cache",
          },
        },
      );
      dispatch(slice.actions.updateMessagesAfterCancel());
      dispatch(slice.actions.setIsProcessing(false));
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

export const { reducer } = slice;

export default slice;

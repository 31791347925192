import React, { useEffect, useState, useCallback } from "react";

import { LoadingButton } from "@mui/lab";
import {
  Box,
  TextField,
  Typography,
  Paper,
  Alert,
  CircularProgress,
} from "@mui/material";
import axios, { AxiosResponse } from "axios";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import { useParams } from "react-router-dom";
import { loadNegotiation } from "src/slices/deal";
import { useDispatch, useSelector } from "src/store";

interface OtpResponse {
  success: boolean;
  message: string;
}

const DealLogin = ({ onOtpVerified }: { onOtpVerified: () => void }) => {
  const [email, setEmail] = useState("");
  const [otp, setOtp] = useState("");
  const [isOtpSent, setIsOtpSent] = useState(false);
  const [isSendingOtp, setIsSendingOtp] = useState(false);
  const [isLoggingIn, setIsLoggingIn] = useState(false);
  const [isLoadingEmail, setIsLoadingEmail] = useState(true);
  const [errorMessage, setErrorMessage] = useState("");
  const { negotiationId } = useParams();
  const { executeRecaptcha } = useGoogleReCaptcha();

  const dispatch = useDispatch();
  const { negotiation } = useSelector((state) => state.deal);

  // Load negotiation
  useEffect(() => {
    dispatch(loadNegotiation(negotiationId));
  }, [negotiationId]);

  // Fetch supplier email
  useEffect(() => {
    const fetchEmail = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_BASE_URL}api/deal/negotiation/${negotiationId}/supplier-email`,
        );
        setEmail(response.data.email);
      } catch (err) {
        console.error("Failed to fetch email:", err);
        setErrorMessage("Failed to load supplier email.");
      } finally {
        setIsLoadingEmail(false);
      }
    };
    fetchEmail();
  }, [negotiationId]);

  const handleSendOtp = async () => {
    setIsSendingOtp(true);
    try {
      const response: AxiosResponse<OtpResponse> = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}api/deal/negotiation/${negotiationId}/send-otp`,
        {
          headers: {
            "Content-Type": "application/json",
            "Cache-Control": "no-cache",
          },
        },
      );
      const { success, message } = response.data;
      if (success) {
        setIsOtpSent(true);
        setErrorMessage("");
      } else {
        setErrorMessage(message);
      }
    } catch (error) {
      setErrorMessage(`Error sending OTP: ${error.message}`);
    } finally {
      setIsSendingOtp(false);
    }
  };

  const handleLogin = useCallback(async () => {
    if (!executeRecaptcha) {
      setErrorMessage("Recaptcha not yet available.");
      return;
    }

    setIsLoggingIn(true);
    setErrorMessage("");

    const recaptchaToken = await executeRecaptcha("verify_otp");

    try {
      const response: AxiosResponse<OtpResponse> = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}api/deal/negotiation/${negotiationId}/verify-otp`,
        { otpToken: otp, recaptchaToken },
        {
          headers: {
            "Content-Type": "application/json",
            "Cache-Control": "no-cache",
          },
        },
      );
      const { success, message } = response.data;
      if (success) {
        onOtpVerified();
      } else {
        setErrorMessage(`Error verifying OTP: ${message}`);
      }
    } catch (error) {
      setErrorMessage(`Error verifying OTP: ${error.message}`);
    } finally {
      setIsLoggingIn(false);
    }
  }, [executeRecaptcha, negotiationId, otp]);

  if (isLoadingEmail) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          minHeight: "100vh",
          bgcolor: "#f4f6f8",
        }}
      >
        <CircularProgress
          size={48}
          sx={{ alignSelf: "center", justifySelf: "center" }}
        />
      </Box>
    );
  }

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        minHeight: "100vh",
        bgcolor: "#f4f6f8",
      }}
    >
      <Paper
        elevation={0}
        sx={{
          p: 4,
          width: 500,
          textAlign: "left",
        }}
      >
        <Box mb={2} display="flex" justifyContent="center">
          {negotiation && negotiation.accountId && (
            <img
              height={150}
              alt="logo"
              src={`${process.env.REACT_APP_API_BASE_URL}api/deal/${negotiation.accountId}/logo`}
            />
          )}
        </Box>

        {!isOtpSent ? (
          <>
            {/* Step 1: Enter Email and Send OTP */}
            <Typography variant="h6" mb={1}>
              Sign In
            </Typography>
            <Typography variant="body2" color="textSecondary">
              We&apos;ll send a code to{" "}
              <Box component="span" fontWeight="bold">
                {email || "your email"}
              </Box>{" "}
              to sign you in.
            </Typography>
            {errorMessage && (
              <Alert severity="error" variant="outlined" sx={{ mt: 2 }}>
                {errorMessage}
              </Alert>
            )}
            <Box display="flex" justifyContent="flex-end" mt={4}>
              <LoadingButton
                loading={isSendingOtp}
                variant="contained"
                color="primary"
                onClick={handleSendOtp}
                disabled={!email}
              >
                Send Code
              </LoadingButton>
            </Box>
          </>
        ) : (
          <>
            {/* Step 2: Enter OTP Code */}
            <Typography variant="h6" mb={1}>
              Enter Code
            </Typography>
            <Typography variant="body2" color="textSecondary">
              We just sent a code to{" "}
              <Box component="span" fontWeight="bold">
                {email}
              </Box>
              . Please enter it below.
            </Typography>
            {errorMessage && (
              <Alert severity="error" variant="outlined" sx={{ mt: 2 }}>
                {errorMessage}
              </Alert>
            )}
            <TextField
              fullWidth
              label="Enter Code"
              variant="standard"
              type="text"
              value={otp}
              onChange={(e) => setOtp(e.target.value)}
              sx={{ marginTop: 2 }}
            />
            <Box display="flex" justifyContent="flex-end" mt={4}>
              <LoadingButton
                loading={isLoggingIn}
                variant="contained"
                color="primary"
                onClick={handleLogin}
                disabled={!otp}
              >
                Sign In
              </LoadingButton>
            </Box>
          </>
        )}
      </Paper>
    </Box>
  );
};

export default DealLogin;

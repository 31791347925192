import { useState, useEffect, useRef } from "react";

import { Popover, Box, Typography, CircularProgress } from "@mui/material";
import axios from "axios";
import ReactMarkdown from "react-markdown";

import { getHoverContent } from "../api";

const ContentPopover = ({
  bankId,
  contentId,
  anchorEl,
}: {
  bankId: string;
  contentId: string;
  anchorEl: { clientX: number; clientY: number };
}) => {
  const [contentData, setContentData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  // Refs to store the current timeout and Axios CancelToken source
  const cancelTokenSourceRef = useRef(null);
  const timeoutRef = useRef(null);

  useEffect(() => {
    // If there's no anchor element, don't proceed
    if (!anchorEl) return () => {};

    // Clear previous timeout if any
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }

    // Clear the previous Axios request if it exists
    if (cancelTokenSourceRef.current) {
      cancelTokenSourceRef.current.cancel(
        "Operation canceled due to new request.",
      );
    }

    // Create a new CancelToken source for the current request
    const cancelTokenSource = axios.CancelToken.source();
    cancelTokenSourceRef.current = cancelTokenSource;

    // Set a 1-second delay before fetching the content
    timeoutRef.current = setTimeout(() => {
      setLoading(true);
      setError(null);

      // Fetch content details using getHoverContent
      getHoverContent(bankId, contentId, {
        cancelToken: cancelTokenSource.token,
      })
        .then((data) => {
          setContentData(data);
          setLoading(false);
        })
        .catch((err) => {
          if (!axios.isCancel(err)) {
            setError(err);
            setLoading(false);
          }
        });
    }, 1000); // 1-second delay

    // Cleanup function to clear timeout and cancel API request
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
      if (cancelTokenSourceRef.current) {
        cancelTokenSourceRef.current.cancel(
          "Operation canceled due to component unmounting.",
        );
      }
    };
  }, [bankId, contentId, anchorEl]);

  return (
    <Popover
      open={Boolean(anchorEl)}
      sx={{
        pointerEvents: "none",
      }}
      hideBackdrop
      disableScrollLock
      anchorReference="anchorPosition"
      anchorPosition={
        anchorEl ? { top: anchorEl.clientY, left: anchorEl.clientX } : undefined
      }
      anchorOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
      disableRestoreFocus
    >
      <Box sx={{ p: 2, maxWidth: 300 }}>
        {error && (
          <Typography variant="body1">Error: {error.message}</Typography>
        )}
        {!error && (
          <>
            {loading ? (
              <Box sx={{ display: "flex", justifyContent: "center" }}>
                <CircularProgress />
              </Box>
            ) : (
              <Typography variant="body1">
                <ReactMarkdown>{contentData}</ReactMarkdown>
              </Typography>
            )}
          </>
        )}
      </Box>
    </Popover>
  );
};

export default ContentPopover;

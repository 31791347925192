import { Grid } from "@mui/material";
import { Helmet } from "react-helmet-async";
import PageTitleWrapper from "src/components/PageTitleWrapper";

import PageHeader from "./PageHeader";
import UserTable from "./UserTable";

const UsersSettings = () => (
  <>
    <Helmet title="SETTINGS" />
    <PageTitleWrapper>
      <PageHeader />
    </PageTitleWrapper>

    <Grid
      sx={{
        px: 4,
      }}
      container
      direction="row"
      justifyContent="center"
      alignItems="stretch"
      spacing={4}
    >
      <Grid item xs={12}>
        <UserTable />
      </Grid>
    </Grid>
  </>
);

export default UsersSettings;

import * as React from "react";

import "./sankey.css";
import HourglassBottomIcon from "@mui/icons-material/HourglassBottom";
import MonetizationOnTwoToneIcon from "@mui/icons-material/MonetizationOnTwoTone";
import TimerIcon from "@mui/icons-material/Timer";
import {
  Box,
  Typography,
  ToggleButtonGroup,
  ToggleButton,
  Stack,
} from "@mui/material";
import * as d3 from "d3";
import { select } from "d3";
import { sankey, sankeyLinkHorizontal } from "d3-sankey";
import Text from "src/components/Text";

interface INode {
  node: number;
  type: string;
  name: string;
  visited: string;
}

interface ILink {
  source: number;
  target: number;
  value: number;
}

interface ITabItem {
  name: string;
  nodes: INode[];
  links: ILink[];
}

interface IProp {
  title: string;
  total: { [id: string]: number };
  tabs: ITabItem[];
  svgWidth: number;
  svgHeight: number;
  nodeWidth: number;
  linkStrokeWidth: number;
}

interface IState {
  selectedId: number;
}

class Sankey extends React.Component<IProp, IState> {
  public readonly state: IState = {
    selectedId: 0,
  };

  private sankeyNode: any;

  private tabClicked = (event: React.MouseEvent<HTMLElement>) => {
    const { id } = event.currentTarget;
    this.setState(() => ({
      selectedId: Number(id),
    }));
  };

  public componentDidMount() {
    this.renderSankey(this.props.tabs[this.state.selectedId.toString()]);
  }

  public componentDidUpdate() {
    this.renderSankey(this.props.tabs[this.state.selectedId.toString()]);
  }

  private tabItems(tabs: ITabItem[]) {
    return (
      <Stack
        direction="row"
        spacing={2}
        justifyContent="center"
        alignItems="center"
        sx={{ pt: "30px" }}
      >
        <ToggleButtonGroup
          color="primary"
          size="small"
          value={this.state.selectedId}
          exclusive
        >
          {tabs.map(({ name }: ITabItem, index: number) => (
            <ToggleButton
              sx={{ pl: 3, pr: 3 }}
              value={index}
              id={String(index)}
              onClick={this.tabClicked}
            >
              {name}
            </ToggleButton>
          ))}
        </ToggleButtonGroup>
      </Stack>
    );
  }

  private total(total: { [id: string]: number }) {
    const format = d3.format(",");
    const totalKey = this.props.tabs[this.state.selectedId.toString()].name;

    return (
      <Stack
        direction="row"
        spacing={2}
        justifyContent="center"
        alignItems="center"
      >
        <Box
          p={2}
          sx={{
            textAlign: "center",
          }}
        >
          <Text color="warning" className="" flex="">
            <MonetizationOnTwoToneIcon
              fontSize="large"
              sx={{ color: "#2442AF" }}
            />
          </Text>
          <Typography variant="h3">{total[totalKey]}</Typography>
          <Typography variant="subtitle2">
            $ of total negotiations concluded with AANI DEAL
          </Typography>
        </Box>

        <Box
          p={2}
          sx={{
            textAlign: "center",
          }}
        >
          <Text color="warning" className="" flex="">
            <HourglassBottomIcon fontSize="large" sx={{ color: "#2442AF" }} />
          </Text>
          <Typography variant="h3">6000</Typography>
          <Typography variant="subtitle2">
            Approx Time Saved (staff hours)
          </Typography>
        </Box>

        <Box
          p={2}
          sx={{
            textAlign: "center",
          }}
        >
          <Text color="warning" className="" flex="">
            <TimerIcon fontSize="large" sx={{ color: "#2442AF" }} />
          </Text>
          <Typography variant="h3">25 mins</Typography>
          <Typography variant="subtitle2">
            Average time email to agreed terms
          </Typography>
        </Box>
      </Stack>
    );
  }

  private renderSankey(tabItem: ITabItem) {
    const node = this.sankeyNode;
    const margin = { top: 100, right: 10, bottom: 50, left: 10 };
    const width = (this.props.svgWidth || 1050) - margin.left - margin.right;
    const height = (this.props.svgHeight || 400) - margin.top - margin.bottom;
    // remove the currently existing drawings
    select(node).select("g").remove();
    const svg = select(node)
      .attr("width", width + margin.left + margin.right)
      .attr("height", height + margin.top + margin.bottom)
      .append("g")
      .attr("transform", `translate(${margin.left},${margin.top})`);

    const graph: ITabItem = tabItem;
    graph.nodes.forEach((d: any, index: number) => {
      d.fixedValue = 3;
    });
    const nodeWidth = this.props.nodeWidth || 40;
    const sankeyGraph = sankey()
      .nodeId((d: any, index: number) => index)
      .nodeWidth(nodeWidth)
      .size([width, height - 5])
      .nodeSort(null);
    sankeyGraph(graph);

    // define the linear gradients
    const nodesData = graph.nodes.filter((d: any) => !d.color);
    const linksData = graph.links.filter((d: any) => !d.color);
    const gradientsData = [...nodesData, ...linksData];
    const def = select(node).append("defs");
    const linearGradient = def
      .selectAll(".linear-gradient")
      .data(gradientsData)
      .enter()
      .append("linearGradient")
      .attr("id", (d: any) => {
        if (d.source && d.target) {
          return `linear-gradient-link-${d.index}`;
        }
        return `linear-gradient-${d.index}`;
      })
      .attr("x1", "0%")
      .attr("y1", "0%")
      .attr("x2", "100%")
      .attr("y2", "0%");
    // .attr("gradientUnits", "objectBoundingBox");

    // Set the color for the start (0%)
    linearGradient
      .append("stop")
      .attr("offset", "0%")
      .attr("stop-color", (d: any) => d.startColor);

    // Set the color for the end (100%)
    linearGradient
      .append("stop")
      .attr("offset", "100%")
      .attr("stop-color", (d: any) => d.endColor);

    const strokeWidth = this.props.linkStrokeWidth;
    const linkG = svg
      .selectAll(".link")
      .data(graph.links)
      .enter()
      .append("path")
      .attr("class", "link")
      .attr("d", (d: any) => {
        const path = sankeyLinkHorizontal()(d);
        const match = path.match(/,([^C]+)C/);
        if (match.length !== 2) {
          return path;
        }
        const replacementValue = +match[1] + 0.01;
        const fixedPath = path.replace(match[1], `${replacementValue}`);
        return fixedPath;
      })
      .style(
        "stroke",
        (d: any, index: number) => `url(#linear-gradient-link-${0})`,
      )
      .style("stroke-width", (d: any) => strokeWidth || 40)
      .sort((a: any, b: any) => b.dy0 - a.dy0);

    const nodeG = svg
      .append("g")
      .on("click", () => {})
      .selectAll(".node")
      .data(graph.nodes)
      .enter()
      .append("g")
      .attr("class", "node")
      .attr("transform", (d: any) => `translate(${d.x0},${d.y0})`);

    nodeG
      .append("rect")
      .attr("x", -2)
      .attr("y", -2)
      .attr("height", (d: any) => (d.height = d.y1 - d.y0 + 4))
      .attr("width", nodeWidth + 4)
      .style("fill", (d: any) => d.color || `url(#linear-gradient-${d.index})`)
      .style("rx", 6);

    // category
    nodeG
      .append("text")
      .attr("x", 0.2 * nodeWidth)
      .attr("y", (d: any) => {
        if (d.index < 1) return d.height * 0.63;
        return d.height * 0.33;
      })
      .style("font-size", 14)
      .style("fill", (d: any) => (d.index > 0 ? "white" : "black"))
      .html((d: any) => d.type);

    // visited
    nodeG
      .append("text")
      .attr("x", 0.2 * nodeWidth)
      .attr("y", (d: any) => d.height * 0.6)
      .style("font-size", 18)
      .style("font-weight", "bold")
      .style("fill", (d: any) => (d.index > 0 ? "white" : "black"))
      .html((d: any) => d.name || d.visited || "67");

    // visited
    nodeG
      .append("text")
      .attr("x", 0.2 * nodeWidth)
      .attr("y", (d: any) => d.height * 0.82)
      .style("font-size", 12)
      .style("fill", (d: any) => (d.index > 0 ? "white" : "black"))
      .text((d: any) => d.category);
  }

  public render() {
    const { title, total, tabs } = this.props;
    return (
      <div className="sankey-container">
        <div className="title">{title}</div>
        {this.tabItems(tabs)}
        <div style={{ paddingTop: "30px" }}>
          <svg
            width="100%"
            height="100%"
            ref={(node) => {
              this.sankeyNode = node;
            }}
          />
          <div style={{ width: "40%", margin: "0 auto", paddingTop: "10px" }}>
            {this.total(total)}
          </div>
        </div>
      </div>
    );
  }
}

export default Sankey;

import { useState, createContext, FC, ReactNode } from "react";

export type SidebarContextType = {
  sidebarToggle: boolean;
  toggleSidebar: () => void;
  closeSidebar: () => void;
};

export type SidebarProviderProps = {
  children: ReactNode;
};

export const SidebarContext = createContext<SidebarContextType>({
  sidebarToggle: false,
  toggleSidebar: () => {},
  closeSidebar: () => {},
});

export const SidebarProvider: FC<SidebarProviderProps> = ({ children }) => {
  const [sidebarToggle, setSidebarToggle] = useState<boolean>(false);

  const toggleSidebar = () => {
    setSidebarToggle(!sidebarToggle);
  };

  const closeSidebar = () => {
    setSidebarToggle(false);
  };

  return (
    <SidebarContext.Provider
      value={{ sidebarToggle, toggleSidebar, closeSidebar }}
    >
      {children}
    </SidebarContext.Provider>
  );
};

import { Card, CardContent, Grid, Typography } from "@mui/material";
import { Helmet } from "react-helmet-async";
import PageTitleWrapper from "src/components/PageTitleWrapper";

import PageHeader from "./PageHeader";

const BillingsSettings = () => (
  <>
    <Helmet title="SETTINGS" />
    <PageTitleWrapper>
      <PageHeader />
    </PageTitleWrapper>

    <Grid
      sx={{
        px: 4,
      }}
      container
      direction="row"
      justifyContent="center"
      alignItems="stretch"
      spacing={4}
    >
      <Grid item xs={12}>
        <Card>
          <CardContent>
            <Typography variant="h5" sx={{ p: 2 }}>
              You are on a special subscription
            </Typography>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  </>
);

export default BillingsSettings;

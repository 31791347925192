import React, { useEffect, useMemo, useState } from "react";

import { Box, ListItemButton, Typography, useTheme } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { useUser } from "src/contexts/UserContext";
import headerMenu from "src/layouts/shared/Header/HeaderMenu/items";
import { IMenuItem } from "src/models/menu";

const ModuleMenu = () => {
  const { userModules } = useUser();
  const theme = useTheme();
  const [selectedModule, setSelectedModule] = useState<string>("");
  const navigate = useNavigate();
  const location = useLocation();

  // Memoize filtered menu items based on user modules
  const filteredMenuItems: IMenuItem[] = useMemo(
    () =>
      headerMenu.items.filter(
        (item) => userModules[item.name] && userModules[item.name].length > 0,
      ),
    [userModules],
  );

  const handleMenuItemClick = (item: IMenuItem) => {
    setSelectedModule(item.name);
    navigate(item.link);
  };

  useEffect(() => {
    // Find the module based on the current path or default to the first module if only one
    const currentModule =
      filteredMenuItems.length === 1
        ? filteredMenuItems[0].name
        : filteredMenuItems.find((item) =>
            location.pathname.startsWith(item.link),
          )?.name;

    if (currentModule) {
      setSelectedModule(currentModule);
    }
  }, [location.pathname, filteredMenuItems]);

  return (
    <Box
      height="100%"
      paddingX={2}
      paddingY={2}
      borderRight={`1px solid ${theme.colors.secondary.lighter}`}
      sx={{ backgroundColor: theme.colors.secondary.lighter }}
    >
      <img
        src="/static/images/logo/uncapt-orange-logo.png"
        alt="Uncapt"
        style={{
          width: "100%",
          maxWidth: "120px",
          maxHeight: "60px",
          objectFit: "contain",
          paddingTop: "8px",
          paddingBottom: "8px",
        }}
      />
      {filteredMenuItems?.length > 1 && (
        <Box
          marginTop={3}
          display="flex"
          flexDirection="column"
          justifyContent="flex-start"
          alignItems="flex-start"
          gap={2}
        >
          {filteredMenuItems.map((item) => (
            <ListItemButton
              disableGutters
              selected={selectedModule === item.name}
              key={item.name}
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: 0.5,
                padding: 1,
                width: "100%",
                "&:hover": {
                  backgroundColor: theme.colors.secondary.lighter,
                },
                "&.Mui-selected": {
                  backgroundColor: theme.colors.secondary.lighter,
                },
                "&.Mui-selected:hover": {
                  backgroundColor: theme.colors.secondary.lighter,
                },
              }}
              onClick={() => handleMenuItemClick(item)}
            >
              <item.icon />
              <Typography
                variant="body2"
                textAlign="center"
                fontWeight={selectedModule === item.name && 600}
              >
                {item.name}
              </Typography>
            </ListItemButton>
          ))}
        </Box>
      )}
    </Box>
  );
};

export default ModuleMenu;

import { createSlice } from "@reduxjs/toolkit";
import { TermsStageData } from "src/content/aani/negotiations/range-review/Terms/model";

import type { PayloadAction } from "@reduxjs/toolkit";

interface TermsState {
  stageData: TermsStageData;
}

const initialState: TermsState = {
  stageData: { products: [] },
};

const termsSlice = createSlice({
  name: "range-review-terms",
  initialState,
  reducers: {
    loadStageData(state, action: PayloadAction<TermsStageData>) {
      return {
        ...state,
        stageData: action.payload,
      };
    },
  },
});

export default termsSlice;

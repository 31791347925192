import { useState } from "react";

import { Button, Alert, CircularProgress } from "@mui/material";
import useAuth from "src/hooks/useAuth";
import useRefMounted from "src/hooks/useRefMounted";

function LoginAuth0() {
  const { login } = useAuth();
  const [error, setError] = useState(null);
  const isMountedRef = useRefMounted();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleLogin = async () => {
    try {
      setIsSubmitting(true);
      await login();
    } catch (err) {
      setIsSubmitting(false);
      console.error(err);
      if (isMountedRef.current) {
        setError(err.message);
      }
    }
  };

  return (
    <>
      {error && <Alert severity="error">{error}</Alert>}
      <Button
        fullWidth
        onClick={handleLogin}
        size="large"
        sx={{
          py: 2,
        }}
        variant="outlined"
      >
        {isSubmitting ? (
          <CircularProgress color="success" size="1rem" />
        ) : (
          "Sign in"
        )}
      </Button>
    </>
  );
}

export default LoginAuth0;

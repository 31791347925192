import { useEffect, useState } from "react";

import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import {
  Box,
  MenuItem,
  Select,
  FormControl,
  CircularProgress,
  Typography,
  Drawer,
  IconButton,
} from "@mui/material";
import ReactMarkdown from "react-markdown";
import { useSelector } from "src/store";

import { getClusterDetails } from "../api";

const ClusterInformationDrawer = ({
  showClusterInformation,
  setShowClusterInformation,
}: {
  showClusterInformation: boolean;
  setShowClusterInformation: (show: boolean) => void;
}) => {
  const [clusters, setClusters] = useState([]);
  const [selectedClusterId, setSelectedClusterId] = useState("");
  const [clusterDetails, setClusterDetails] = useState(null);
  const [loadingClusterDetails, setLoadingClusterDetails] = useState(true);
  const selectedKnowledgeBank = useSelector(
    (state) => state.insight.selectedKnowledgeBank,
  );

  useEffect(() => {
    // Fetch cluster names and IDs
    if (selectedKnowledgeBank) {
      setSelectedClusterId(selectedKnowledgeBank.clusters[0].id);
      setClusters(selectedKnowledgeBank.clusters);
    }
  }, [selectedKnowledgeBank]);

  useEffect(() => {
    if (selectedClusterId && selectedKnowledgeBank) {
      // Fetch cluster details when a cluster is selected
      const fetchClusterDetails = async () => {
        setLoadingClusterDetails(true);
        setClusterDetails(null);
        try {
          const response = await getClusterDetails(
            selectedKnowledgeBank.id,
            selectedClusterId,
          );
          setClusterDetails(response.details);
        } catch (error) {
          console.error("Error fetching cluster details:", error);
        }
        setLoadingClusterDetails(false);
      };

      if (selectedClusterId && selectedClusterId !== "") {
        fetchClusterDetails();
      }
    }
  }, [selectedClusterId]);

  return (
    <Drawer
      variant="temporary"
      anchor="right"
      open={showClusterInformation}
      onClose={() => setShowClusterInformation(false)}
      ModalProps={{
        keepMounted: true,
      }}
      sx={{
        "& .MuiDrawer-paper": {
          height: "100%",
          width: "100%",
          maxWidth: "600px",
        },
      }}
    >
      <IconButton
        color="primary"
        onClick={() => setShowClusterInformation(false)}
        sx={{
          position: "absolute",
          right: 12,
          top: 12,
        }}
      >
        <CloseOutlinedIcon />
      </IconButton>
      <Box
        height="100%"
        padding={2}
        display="flex"
        flexDirection="column"
        gap={2}
      >
        <Typography variant="h5" textAlign="center">
          Cluster Information
        </Typography>
        <FormControl fullWidth>
          <Typography variant="subtitle1" gutterBottom>
            Select Cluster
          </Typography>
          <Select
            labelId="cluster-select-label"
            value={selectedClusterId}
            size="small"
            onChange={(e) => setSelectedClusterId(e.target.value)}
          >
            {clusters.map((cluster) => (
              <MenuItem key={cluster.id} value={cluster.id}>
                {cluster.title}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        {loadingClusterDetails && (
          <CircularProgress
            sx={{ alignSelf: "center", justifySelf: "center" }}
          />
        )}
        {clusterDetails && (
          <Box>
            <ReactMarkdown>{clusterDetails}</ReactMarkdown>
          </Box>
        )}
      </Box>
    </Drawer>
  );
};

export default ClusterInformationDrawer;

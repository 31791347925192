import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import CheckCircleOutlineOutlinedIcon from "@mui/icons-material/CheckCircleOutlineOutlined";
import UploadFileOutlinedIcon from "@mui/icons-material/UploadFileOutlined";
import { Box, Typography, useTheme, Stack } from "@mui/material";
import { useSnackbar } from "notistack";
import { useDropzone } from "react-dropzone";

const FileUpload = ({
  onFilesAccepted,
}: {
  onFilesAccepted: (file: File[]) => void;
}) => {
  const theme = useTheme();
  const { enqueueSnackbar } = useSnackbar();

  const {
    acceptedFiles,
    isDragActive,
    isDragAccept,
    isDragReject,
    getRootProps,
  } = useDropzone({
    maxFiles: 4,
    maxSize: 10485760, // 10MB
    accept: {
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
        [".docx"],
      "application/pdf": [".pdf"],
    },
    onDropAccepted(files) {
      onFilesAccepted(files);
    },
    onDropRejected() {
      enqueueSnackbar(
        "You cannot upload these file types or the file is too large.",
        {
          variant: "error",
          anchorOrigin: { vertical: "top", horizontal: "right" },
          autoHideDuration: 5000,
        },
      );
    },
  });

  const filesList = acceptedFiles.map((file) => (
    <Box
      key={file.name}
      display="flex"
      alignItems="center"
      paddingY={1}
      paddingX={2}
      gap={1}
    >
      <CheckCircleOutlineOutlinedIcon color="success" />
      <Typography variant="body2">{file.name}</Typography>
    </Box>
  ));

  return (
    <Stack spacing={2}>
      <Box>
        <Typography variant="subtitle1">Quick upload</Typography>
        <Typography variant="caption">
          Please upload <b>RAS or ACAT assessment document</b> relevant to the
          client .docx .pdf • max file size 10mb
        </Typography>
      </Box>
      {filesList.length > 0 && <Box>{filesList}</Box>}
      <Box
        padding={3}
        borderRadius={theme.general.borderRadius}
        border={`1px dashed ${theme.palette.primary.light}`}
        bgcolor={theme.colors.primary.lighter}
        sx={{ cursor: "pointer" }}
        {...getRootProps()}
      >
        <Box display="flex" justifyContent="center" alignItems="center" gap={1}>
          {isDragAccept && (
            <>
              <CheckCircleOutlineOutlinedIcon color="success" />
              <Typography variant="body2">
                Drop the files to start uploading
              </Typography>
            </>
          )}
          {isDragReject && (
            <>
              <CancelOutlinedIcon color="error" />
              <Typography variant="body2">
                You cannot upload these file types
              </Typography>
            </>
          )}
          {!isDragActive && (
            <>
              <UploadFileOutlinedIcon color="primary" />
              <Typography variant="body2">
                Select or drag & drop the file here
              </Typography>
            </>
          )}
        </Box>
      </Box>
    </Stack>
  );
};

export default FileUpload;

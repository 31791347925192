import { useState } from "react";

import AddIcon from "@mui/icons-material/Add";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  Grid,
  TextField,
  Box,
  useTheme,
  CircularProgress,
  InputAdornment,
} from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { Dayjs } from "dayjs";
import { FormikHelpers, useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import VoiceRecorder from "src/components/VoiceRecorder/VoiceRecorder";
import { useSnackbarService } from "src/hooks/useSnackbarService";
import { createClient } from "src/slices/mia/mia";
import { useDispatch } from "src/store";
import * as yup from "yup";

export interface NewClientData {
  firstName: string;
  lastName: string;
  preferredName?: string;
  dateOfBirth: Dayjs | null;
  notes?: string;
}
export enum Gender {
  MALE = "MALE",
  FEMALE = "FEMALE",
  OTHER = "OTHER",
}

const NewClientButton = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [openDialog, setOpenDialog] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const theme = useTheme();

  const { showSuccessSnackbar, showErrorSnackbar } = useSnackbarService();

  const initialValues: NewClientData = {
    firstName: "",
    lastName: "",
    preferredName: "",
    notes: "",
    dateOfBirth: null,
  };

  const handleNewClientButtonClick = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const validationSchema = yup.object({
    firstName: yup.string().required("First name is required"),
    lastName: yup.string().required("Last name is required"),
    dateOfBirth: yup.string().required("Date of birth is required"),
    notes: yup.string().required("Notes are required"),
  });

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (
      values: NewClientData,
      actions: FormikHelpers<NewClientData>,
    ) => {
      setSubmitting(true);
      try {
        // Create new client and load client full name to state
        const { clientId } = await dispatch(createClient(values));

        showSuccessSnackbar("Client created successfully!");
        actions.resetForm();
        handleCloseDialog();

        // Navigate to the new client URL
        navigate(`/mia/clients/${clientId}`);
      } catch (error) {
        showErrorSnackbar(error.message);
      } finally {
        setSubmitting(false);
      }
    },
  });

  const handleTranscriptionComplete = (transcript: string) => {
    formik.setFieldValue("notes", transcript);
  };

  return (
    <>
      <Button
        variant="contained"
        color="secondary"
        startIcon={<AddIcon />}
        onClick={handleNewClientButtonClick}
        sx={{
          backgroundColor: theme.colors.secondary.main,
          color: theme.palette.common.white,
          borderRadius: theme.general.borderRadiusLg,
          paddingX: 2,
        }}
      >
        <Typography variant="subtitle2">New Client</Typography>
      </Button>

      <Dialog
        fullWidth
        maxWidth="md"
        open={openDialog}
        onClose={handleCloseDialog}
      >
        {/* Dialog will show up after user clicks the new client button */}
        <DialogTitle>
          <Typography variant="subtitle1">Create New Client</Typography>
        </DialogTitle>

        <form onSubmit={formik.handleSubmit}>
          {/* Formik form to create a new client */}
          <DialogContent dividers>
            <Grid container spacing={2}>
              {/* Left section */}
              <Grid item xs={6}>
                <Box
                  sx={{
                    marginBottom: 2,
                    padding: 2,
                    borderRadius: theme.general.borderRadius,
                    backgroundColor: theme.colors.secondary.lighter,
                  }}
                >
                  <Typography variant="subtitle1" align="center" color="black">
                    Client Information
                  </Typography>
                </Box>

                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <TextField
                      fullWidth
                      id="firstName"
                      name="firstName"
                      label="First Name"
                      value={formik.values.firstName}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.firstName &&
                        Boolean(formik.errors.firstName)
                      }
                      helperText={
                        formik.touched.firstName && formik.errors.firstName
                      }
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      fullWidth
                      id="lastName"
                      name="lastName"
                      label="Last Name"
                      value={formik.values.lastName}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.lastName &&
                        Boolean(formik.errors.lastName)
                      }
                      helperText={
                        formik.touched.lastName && formik.errors.lastName
                      }
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      fullWidth
                      id="preferredName"
                      name="preferredName"
                      label="Preferred Name"
                      value={formik.values.preferredName}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.preferredName &&
                        Boolean(formik.errors.preferredName)
                      }
                      helperText={
                        formik.touched.preferredName &&
                        formik.errors.preferredName
                      }
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        label="Date of Birth"
                        sx={{ width: "100%" }}
                        format="DD/MM/YYYY"
                        disableFuture
                        value={formik.values.dateOfBirth}
                        onChange={(date: Dayjs) => {
                          formik.setFieldValue("dateOfBirth", date);
                        }}
                        slotProps={{
                          textField: {
                            variant: "outlined",
                            error:
                              formik.touched.dateOfBirth &&
                              Boolean(formik.errors.dateOfBirth),
                            helperText:
                              formik.touched.dateOfBirth &&
                              formik.errors.dateOfBirth ? (
                                <>{formik.errors.dateOfBirth}</>
                              ) : null, // React fragment to resolve typescript error
                          },
                        }}
                      />
                    </LocalizationProvider>
                  </Grid>
                </Grid>
              </Grid>

              {/* Right section */}
              <Grid item xs={6}>
                <Box
                  sx={{
                    marginBottom: 2,
                    padding: 2,
                    backgroundColor: theme.colors.secondary.lighter,
                    borderRadius: theme.general.borderRadius,
                  }}
                >
                  <Typography variant="subtitle1" align="center" color="black">
                    Intake Notes
                  </Typography>
                </Box>
                <TextField
                  fullWidth
                  multiline
                  rows={14}
                  id="notes"
                  name="notes"
                  label="Primary concerns and symptoms"
                  value={formik.values.notes}
                  onChange={formik.handleChange}
                  error={formik.touched.notes && Boolean(formik.errors.notes)}
                  helperText={formik.touched.notes && formik.errors.notes}
                  variant="outlined"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment
                        position="start"
                        sx={{
                          alignSelf: "flex-start",
                          mt: 1.5,
                        }}
                      >
                        <VoiceRecorder
                          onTranscriptionComplete={handleTranscriptionComplete}
                          tooltipTitle="Speak my notes"
                          transcribeAudioEndpoint={`${process.env.REACT_APP_API_BASE_URL}api/mia/transcribe-audio`}
                        />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseDialog} color="error">
              Cancel
            </Button>
            <Button
              variant="contained"
              color="secondary"
              type="submit"
              disabled={submitting}
              startIcon={
                submitting && <CircularProgress size={20} color="secondary" />
              }
            >
              Create
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
};

export default NewClientButton;

export enum AgentRunStatus {
  INITIALISING = "INITIALISING",
  THINKING = "THINKING",
  ERROR = "ERROR",
}

export enum AgentActionType {
  TOOL = "TOOL",
  THOUGHT = "THOUGHT",
}

export enum UserActionFeedbackType {
  POSITIVE = "POSITIVE",
  NEGATIVE = "NEGATIVE",
  NEUTRAL = "NEUTRAL",
}

export interface AgentAction {
  actionId: string;
  title: string;
  result: string | object;
  resultType: string;
}

import useAuth from "src/hooks/useAuth";
import ModuleLayout from "src/layouts/shared/layout/ModuleLayout";

import Sidebar from "./Sidebar";

const SettingsLayout = () => {
  const { user } = useAuth();

  return (
    <>
      {user.isAccountAdmin && <ModuleLayout SidebarComponent={<Sidebar />} />}
    </>
  );
};

export default SettingsLayout;

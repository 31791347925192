import React, { useEffect, useState } from "react";

import SendTwoToneIcon from "@mui/icons-material/SendTwoTone";
import { LoadingButton } from "@mui/lab";
import {
  MenuItem,
  useTheme,
  Box,
  Stack,
  Typography,
  Grid,
} from "@mui/material";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { useNavigate } from "react-router-dom";
import {
  createTrainingNegotiationSession,
  TrainingType,
} from "src/slices/training/trainingNegotiation";
import * as Yup from "yup";

import { getCatalogues, getSessionsByTrainingType } from "../../api/api";
import { Catalogue, TrainingInput } from "../../shared/interface";
import SessionList from "../components/SessionList";

const TrainingNegotiationCreate = () => {
  const theme = useTheme();
  const [sessions, setSessions] = useState([]);
  const [catalogues, setCatalogues] = useState<Catalogue[]>([]);
  const [catalogue, setCatalogue] = useState<Catalogue | null>(null);
  const [initialTrainingInputs, setInitialTrainingInputs] = useState({});
  const [isLoadingCatalogues, setLoadingCatalogues] = useState(true);
  const [submitting, setSubmitting] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchSessions = async () => {
      try {
        const { sessions: sessionList } = await getSessionsByTrainingType(
          TrainingType.NEGOTIATION,
        );
        setSessions(sessionList);
      } catch (error) {
        console.log(error);
      }
    };
    fetchSessions();
  }, []);

  // Fetch negotiation training catalogues
  useEffect(() => {
    const fetchCatalogues = async () => {
      try {
        const cats = await getCatalogues(TrainingType.NEGOTIATION);
        setCatalogues(cats);
      } catch (error) {
        console.error(error);
      } finally {
        setLoadingCatalogues(false);
      }
    };
    fetchCatalogues();
  }, []);

  const handleInitialTrainingInputs = (_catalogue: Catalogue) => {
    const catalogueTrainingInputs = _catalogue?.trainingInputs?.reduce(
      (acc, input) => {
        acc[input.id] = input.defaultValue ?? "";
        return acc;
      },
      {},
    );
    setInitialTrainingInputs(catalogueTrainingInputs);
  };

  // Set selected catalogue and initial training inputs after fetching catalogues
  useEffect(() => {
    if (catalogues.length > 0) {
      const firstCatalogue = catalogues[0];
      setCatalogue(firstCatalogue);
      handleInitialTrainingInputs(firstCatalogue);
    }
  }, [catalogues]);

  const handleChangeCatalogue = (event: SelectChangeEvent) => {
    const selectedCatalogue = catalogues.find(
      (catalog) => catalog.id === event.target.value,
    );
    setCatalogue(selectedCatalogue);

    // Set initial training inputs values for the current training catalogue
    handleInitialTrainingInputs(selectedCatalogue);
  };

  const handleNewSession = async (values) => {
    try {
      setSubmitting(true);
      const response = await createTrainingNegotiationSession(
        catalogue.id,
        catalogue.title,
        values.trainingInputs,
      );
      navigate(`/training/negotiation/${response.id}`);
    } catch (error) {
      console.error(error);
    } finally {
      setSubmitting(false);
    }
  };

  const textField = (input) => (
    <Field name={`trainingInputs.${input.id}`}>
      {({ field }) => (
        <textarea
          {...field}
          type="text"
          placeholder={input.defaultValue}
          style={{
            padding: "0.5rem",
            border: "1px solid #ccc",
            borderRadius: "4px",
            fontSize: "1rem",
            transition: "border-color 0.3s ease",
          }}
          disabled={isLoadingCatalogues}
        />
      )}
    </Field>
  );

  const numberField = (input) => (
    <Field name={`trainingInputs.${input.id}`}>
      {({ field }) => (
        <input
          {...field}
          type="number"
          max={input.maxValue}
          min={input.minValue}
          placeholder={input.minValue}
          style={{
            width: "",
            padding: "0.5rem",
            border: "1px solid #ccc",
            borderRadius: "4px",
            fontSize: "1rem",
            transition: "border-color 0.3s ease",
          }}
          disabled={isLoadingCatalogues}
        />
      )}
    </Field>
  );

  const booleanField = (input) => (
    <Field name={`trainingInputs.${input.id}`}>
      {({ field }) => (
        <input
          {...field}
          type="checkbox"
          style={{
            width: "",
            padding: "0.5rem",
            border: "1px solid #ccc",
            borderRadius: "4px",
            fontSize: "1rem",
            transition: "border-color 0.3s ease",
          }}
          disabled={isLoadingCatalogues}
        />
      )}
    </Field>
  );

  const selectField = (input) => (
    <Field name={`trainingInputs.${input.id}`}>
      {({ field }) => (
        <Select
          {...field}
          fullWidth
          onChange={(e: SelectChangeEvent) => {
            field.onChange(e);
          }}
          disabled={isLoadingCatalogues}
        >
          {input.options.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </Select>
      )}
    </Field>
  );

  const toField = (input: TrainingInput) => {
    if (input.type === "number") {
      return numberField(input);
    }

    if (input.type === "boolean") {
      return booleanField(input);
    }

    if (input.type === "options") {
      return selectField(input);
    }

    return textField(input);
  };

  return (
    <Grid
      container
      spacing={2}
      sx={{
        margin: 0,
        marginTop: 1,
        height: `calc(100vh - ${theme.header.height})`,
      }}
    >
      <Grid item xs={2}>
        <SessionList sessions={sessions} />
      </Grid>
      <Grid item xs={10} sx={{ marginTop: "1rem", display: "flex" }}>
        <Formik
          initialValues={{
            catalogueId: catalogue?.id ?? "",
            trainingInputs: initialTrainingInputs,
          }}
          enableReinitialize // Enable reinitialization of form values when catalogue changes
          validationSchema={Yup.object({
            catalogueId: Yup.string().required("Catalogue is required"),
          })}
          onSubmit={handleNewSession}
        >
          {({ errors, touched }) => (
            <Form style={{ display: "flex", flex: 1 }}>
              <Box flex={1}>
                <Box mb={2}>
                  <Typography variant="h5" mb={1}>
                    Catalogue
                  </Typography>
                  <Field name="catalogueId">
                    {({ field }) => (
                      <Select
                        {...field}
                        fullWidth
                        onChange={(e: SelectChangeEvent) => {
                          field.onChange(e);
                          handleChangeCatalogue(e);
                        }}
                        disabled={isLoadingCatalogues}
                        error={
                          touched.catalogueId && Boolean(errors.catalogueId)
                        }
                        sx={{ maxWidth: "400px" }}
                      >
                        {catalogues.map((x: Catalogue) => (
                          <MenuItem key={x.id} value={x.id}>
                            {x.title}
                          </MenuItem>
                        ))}
                      </Select>
                    )}
                  </Field>
                  <ErrorMessage name="catalogueId" component="div" />
                </Box>
                {catalogue && catalogue.trainingInputs && (
                  <Box mb={4}>
                    <Typography variant="h5" mb={2}>
                      Training Inputs
                    </Typography>
                    {catalogue.trainingInputs.map((input) => (
                      <Grid container spacing={2} key={input.id} maxWidth={400}>
                        <Grid item xs={6}>
                          <Typography fontSize={12}>{input.name}</Typography>
                        </Grid>
                        <Grid item xs={6}>
                          <Box pb={1}>{toField(input)}</Box>
                        </Grid>
                      </Grid>
                    ))}
                  </Box>
                )}
                <Box mb={2}>
                  <Stack width="100%" spacing={2}>
                    <LoadingButton
                      type="submit"
                      variant="contained"
                      disabled={submitting || isLoadingCatalogues}
                      startIcon={<SendTwoToneIcon />}
                      sx={{
                        border: "1px solid rgb(229, 234, 242)",
                        width: "auto",
                        maxWidth: "150px",
                      }}
                      loading={submitting}
                    >
                      {submitting ? "Thinking" : "Submit"}
                    </LoadingButton>
                  </Stack>
                </Box>
              </Box>
            </Form>
          )}
        </Formik>
      </Grid>
    </Grid>
  );
};

export default TrainingNegotiationCreate;

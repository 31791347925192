import axios from "axios";

const fetchCSRFToken = async () => {
  const response = await axios.get(
    `${process.env.REACT_APP_API_BASE_URL}csrf-token`,
  );
  const token = await response.data.csrf_token;
  return token;
};

export default fetchCSRFToken;

import React, { useState, useEffect } from "react";

import AccessibilityNewIcon from "@mui/icons-material/AccessibilityNew";
import {
  IconButton,
  Tooltip,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  CircularProgress,
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Grid,
  Typography,
  Divider,
} from "@mui/material";
import { useSnackbarService } from "src/hooks/useSnackbarService";
import User from "src/models/users";

import {
  IsSuccessResponse,
  UserModuleRoles,
  getUserAvailableModuleRoles,
  setUserModulesRoles,
} from "../../api/user";

const ManageAccessButton: React.FC<{ user: User }> = ({ user }) => {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [moduleRoles, setModuleRoles] = useState<UserModuleRoles[]>([]);
  const [selectedModuleRole, setSelectedModuleRole] = useState<
    Record<string, string>
  >({});
  const { showSuccessSnackbar, showErrorSnackbar } = useSnackbarService();

  const fetchModulesRoles = async () => {
    setLoading(true);
    const userModuleRoles = await getUserAvailableModuleRoles(user.id);
    setModuleRoles(userModuleRoles);

    // Set selectedModuleRole based on userCurrentRole
    const newSelectedModuleRole: Record<string, string> = {};
    userModuleRoles.forEach((moduleRole) => {
      if (moduleRole.userCurrentRole !== null) {
        newSelectedModuleRole[moduleRole.module] = moduleRole.userCurrentRole;
      }
    });
    setSelectedModuleRole(newSelectedModuleRole);

    setLoading(false);
  };

  useEffect(() => {
    if (open) fetchModulesRoles();
  }, [user.id, open]);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSaveRoles = async () => {
    setLoading(true);
    try {
      const response: IsSuccessResponse = await setUserModulesRoles(
        user.id,
        selectedModuleRole,
      );
      if (response.isSuccess) {
        showSuccessSnackbar(response.message);
      } else {
        showErrorSnackbar(response.message);
      }
    } catch (error) {
      showErrorSnackbar(error.message);
    }
    setLoading(false);
    handleClose();
  };

  const handleRoleChange = (module: string, role: string) => {
    if (role === "") {
      // Handle when user remove module role for a module
      // Remove module and its role from selectedModuleRole
      const newSelectedModuleRole = { ...selectedModuleRole };
      delete newSelectedModuleRole[module];
      setSelectedModuleRole(newSelectedModuleRole);
    } else {
      // Update selectedModuleRole with new module role
      setSelectedModuleRole({
        ...selectedModuleRole,
        [module]: role,
      });
    }
  };
  return (
    <>
      <Tooltip title="Manage Access">
        <IconButton aria-label="manage access" onClick={handleOpen}>
          <AccessibilityNewIcon />
        </IconButton>
      </Tooltip>
      <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
        <DialogTitle variant="h4">Manage Access</DialogTitle>
        {loading ? (
          <Box display="flex" justifyContent="center" py={5}>
            <CircularProgress />
          </Box>
        ) : (
          <>
            <DialogContent>
              <DialogContentText>
                Here are modules user account have access to. Assign a module
                role to grant level of access needed.
              </DialogContentText>
              <Grid container spacing={2} mt={1}>
                <Grid item xs={6}>
                  <Typography variant="h5">Module</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="h5">Module Role</Typography>
                </Grid>
                <Grid item xs={12}>
                  <Divider />
                </Grid>
                {moduleRoles.map((moduleRole) => (
                  <React.Fragment key={moduleRole.module}>
                    <Grid item xs={6}>
                      <InputLabel id={`module-label-${moduleRole.module}`}>
                        {moduleRole.module}
                      </InputLabel>
                    </Grid>
                    <Grid item xs={6}>
                      <FormControl fullWidth>
                        <Select
                          value={selectedModuleRole[moduleRole.module] || ""}
                          onChange={(e) =>
                            handleRoleChange(
                              moduleRole.module,
                              e.target.value as string,
                            )
                          }
                          displayEmpty
                        >
                          <MenuItem value="">
                            <em>None</em>
                          </MenuItem>
                          {moduleRole.availableRoles.map((role) => (
                            <MenuItem key={role} value={role}>
                              {role}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                  </React.Fragment>
                ))}
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose} color="primary">
                Cancel
              </Button>
              <Button onClick={handleSaveRoles} color="primary">
                Save
              </Button>
            </DialogActions>
          </>
        )}
      </Dialog>
    </>
  );
};

export default ManageAccessButton;

import { useEffect, useState } from "react";

import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import {
  Box,
  Button,
  Chip,
  ListItemIcon,
  Menu,
  MenuItem,
  Typography,
} from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { IHeaderMenu, IMenuItem } from "src/models/menu";

import headerMenu from "./items";

const HeaderMenu = ({ menuItems }: { menuItems?: IHeaderMenu }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedModule, setSelectedModule] = useState<string>("Select Module");
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (menuItems?.items.length === 1) {
      // If there is only one module, select it by default
      setSelectedModule(menuItems.items[0].name);
    } else {
      // If there are multiple modules,
      // select the one that has the link includes the current path
      const currentModule = menuItems?.items.find((item) =>
        location.pathname.startsWith(item.link),
      )?.name;

      if (currentModule) {
        setSelectedModule(currentModule);
      }

      // For suppliers table, we match it to the "Deal" module at the moment
      if (location.pathname.startsWith("/aani/suppliers")) {
        setSelectedModule("Deal");
      }
    }
  }, [location.pathname, menuItems]);

  const handleClick = () => {
    if (menuItems?.items.length === 1) {
      // If there is only one module, navigate to it
      const item = menuItems.items[0];
      setSelectedModule(item.name);
      navigate(item.link);
    } else {
      // If there are multiple modules, open the menu
      setAnchorEl(
        anchorEl ? null : document.getElementById("header-menu-button"),
      );
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMenuItemClick = (item: IMenuItem) => {
    setSelectedModule(item.name);
    navigate(item.link);
    setAnchorEl(null);
  };

  const betaModules = ["AANI Copilot"];

  return (
    <>
      <Button
        id="header-menu-button"
        aria-haspopup="true"
        onClick={handleClick}
        endIcon={menuItems?.items.length > 1 && <KeyboardArrowDownIcon />}
        variant="text"
        size="small"
        fullWidth
        sx={{
          fontSize: 20,
        }}
      >
        {selectedModule}
      </Button>

      {betaModules.includes(selectedModule) && (
        <Chip label="beta" color="primary" />
      )}
      {menuItems?.items.length > 1 && (
        <Menu
          id="header-menu"
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleClose}
          MenuListProps={{
            "aria-labelledby": "header-menu-button",
          }}
        >
          <Box>
            {menuItems.items.map((item) => (
              <MenuItem
                key={item.name}
                onClick={() => handleMenuItemClick(item)}
                style={{ marginTop: 4, marginBottom: 4 }}
              >
                <ListItemIcon>
                  <item.icon />
                </ListItemIcon>
                <Typography variant="h6">{item.name}</Typography>
              </MenuItem>
            ))}
          </Box>
        </Menu>
      )}
    </>
  );
};

HeaderMenu.defaultProps = {
  menuItems: headerMenu,
};

export default HeaderMenu;

import numeral from "numeral";
import { Helmet } from "react-helmet-async";


import data from "./data.json";

import {
  Card,
  Box,
  styled,
  Typography,
  useTheme,
  Divider,
  Grid,
} from "@mui/material";
import Sankey from "./Sankey";

const DividerSuccess = styled(Divider)(
  ({ theme }) => `
        width: 50px;
        background: ${theme.colors.success.main};
        height: 6px;
        border-radius: 50px;
    `
);

function StatsBox({ theme, value, formatType, displayName }) {
  const formatValue = (value, formatType) => {
    return numeral(value).format(formatType);
  };

  return (
    <Box
      sx={{
        textAlign: "center",
      }}
    >
      <Card
        sx={{
          p: 3,
          textAlign: "center",
        }}
      >
        <Typography
          variant="h1"
          sx={{
            fontSize: `${theme.typography.pxToRem(30)}`,
          }}
        >
          {formatValue(value, formatType)}
        </Typography>
        <DividerSuccess
          sx={{
            mx: "auto",
            my: 2,
          }}
        />
        <Typography
          variant="h3"
          fontWeight="bold"
          sx={{
            fontSize: `${theme.typography.pxToRem(14)}`,
            textTransform: "uppercase",
          }}
        >
          {displayName}
        </Typography>
      </Card>
    </Box>
  );
}

function Dashboard() {
  const theme = useTheme();

  return (
    <>
      <Helmet>
        <title>View</title>
      </Helmet>
      <Grid
        container
        direction="row"
        justifyContent="left"
        alignItems="stretch"
        spacing={4}
      >
        <Grid item xs={12}>
          <Sankey
              {...data}
              svgWidth={1350}
              svgHeight={500}
              nodeWidth={170}
              linkStrokeWidth={30}
          />
        </Grid>
      </Grid>
    </>
  );
}

export default Dashboard;

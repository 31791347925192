import React, { useState, useEffect } from "react";

import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
import {
  IconButton,
  Tooltip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  CircularProgress,
  Box,
} from "@mui/material";
import { AccountUser } from "src/slices/settings/user";

import { setUserRoles, getUserRoles, IsSuccessResponse } from "../../api/user";
import { useDialog } from "../hooks/useDialog";
import { useSnackbarService } from "../hooks/useSnackbarService";
import RolesSelection from "../shared/RolesSelection";

interface ManageRolesButtonProps {
  user: AccountUser;
}

export const availableRoles = [
  "Account Admin",
  "Member",
  "Buyer",
  "Category Manager",
];

const ManageRolesButton: React.FC<ManageRolesButtonProps> = ({ user }) => {
  const [selectedRoles, setSelectedRoles] = useState<string[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const { showSuccessSnackbar, showErrorSnackbar } = useSnackbarService();
  const { open, handleOpen, handleClose } = useDialog();

  useEffect(() => {
    const fetchRoles = async () => {
      setLoading(true);
      const userRoles = await getUserRoles(user.id);
      setSelectedRoles(userRoles);
      setLoading(false);
    };

    if (open) {
      fetchRoles();
    }
  }, [user.id, open]);

  const handleSaveRoles = async () => {
    setLoading(true);
    try {
      const response: IsSuccessResponse = await setUserRoles(
        user.id,
        selectedRoles,
      );
      if (response.isSuccess) {
        showSuccessSnackbar(response.message);
      } else {
        showErrorSnackbar(response.message);
      }
    } catch (error) {
      showErrorSnackbar(error.message);
    }
    setLoading(false);
    handleClose();
  };

  return (
    <>
      <Tooltip title="Manage Roles">
        <IconButton aria-label="manage roles" onClick={handleOpen}>
          <PersonOutlinedIcon />
        </IconButton>
      </Tooltip>
      <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
        <DialogTitle variant="h4">Manage Roles</DialogTitle>
        {loading ? (
          <Box display="flex" justifyContent="center" pb={4}>
            <CircularProgress />
          </Box>
        ) : (
          <>
            <DialogContent>
              <DialogContentText>
                Select roles to assign or unassign for this user
              </DialogContentText>
              <Box mt={2}>
                <RolesSelection
                  selectedRoles={selectedRoles}
                  setSelectedRoles={setSelectedRoles}
                />
              </Box>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose} color="primary">
                Cancel
              </Button>
              <Button onClick={handleSaveRoles} color="primary">
                Save
              </Button>
            </DialogActions>
          </>
        )}
      </Dialog>
    </>
  );
};

export default ManageRolesButton;

import React, { Dispatch, SetStateAction, useState } from "react";

import { LoadingButton } from "@mui/lab";
import {
  Checkbox,
  Typography,
  Box,
  CircularProgress,
  Stack,
} from "@mui/material";
import axios, { AxiosResponse } from "axios";
import useAuth from "src/hooks/useAuth";
import { Module } from "src/models/module";

import { TermsAcceptanceResponse } from ".";

const Terms = ({
  setHasAcceptedTerms,
}: {
  setHasAcceptedTerms: Dispatch<SetStateAction<boolean>>;
}) => {
  const [checked, setChecked] = useState(false);
  const [loading, setLoading] = useState(false);
  const { user } = useAuth();

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(event.target.checked);
  };

  const handleAccept = async () => {
    try {
      setLoading(true);
      const response: AxiosResponse<TermsAcceptanceResponse> = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}api/accounts/current/terms/${Module.MIA}/accept`,
      );
      setHasAcceptedTerms(response.data.hasAcceptedTerms);
    } catch (error) {
      console.error("Error accepting terms:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="flex-start"
      alignItems="flex-start"
      width="50%"
      height="50%"
      margin="auto"
      paddingX={4}
      bgcolor="background.paper"
      borderRadius={2}
    >
      <Box>
        <Stack spacing={2}>
          <Typography variant="h4" gutterBottom>
            Hi {user.name.split(" ")[0]}!
          </Typography>
          <Typography variant="h6">
            Before you get started, please take a moment to review and accept
            our terms and conditions.
          </Typography>
          <Typography variant="h6">Key terms and conditions:</Typography>
          <ul>
            <li> No, we don&lsquo;t train on your data.</li>
            <li>
              MIA may make mistakes or not fully understand the situation.
            </li>
            <li>
              MIA is still in an extended trial phase and subject to ongoing
              development and improvement.
            </li>
            <li>
              MIA should not be used to replace the clinical judgement of a
              health care professional to make a clinical decision.
            </li>
            <li>
              MIA is intended only for the purpose of providing or supporting a
              recommendation.
            </li>
            <li>
              Always double check information and use your own judgement before
              following any guidance.
            </li>
          </ul>
        </Stack>
      </Box>
      <Box display="flex" alignItems="center" mt={4} mb={2}>
        <Checkbox
          checked={checked}
          onChange={handleCheckboxChange}
          color="primary"
          size="small"
        />
        <Typography variant="body1" fontSize={14} paddingLeft={1}>
          I agree to the terms and conditions.
        </Typography>
      </Box>
      <LoadingButton
        size="medium"
        color="primary"
        onClick={handleAccept}
        loading={loading}
        loadingIndicator={<CircularProgress size={24} />}
        variant="contained"
        disabled={!checked}
      >
        <span>Accept</span>
      </LoadingButton>
    </Box>
  );
};

export default Terms;

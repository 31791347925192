import React, { useState } from "react";

import PersonAddIcon from "@mui/icons-material/PersonAdd";
import { IconButton, Tooltip, CircularProgress } from "@mui/material";
import axios, { AxiosResponse } from "axios";
import { useTranslation } from "react-i18next";
import { useSnackbarService } from "src/hooks/useSnackbarService";
import { IsSuccessResponse } from "src/models/response";
import { AccountUser } from "src/slices/settings/user";

interface ReinviteUserButtonProps {
  user: AccountUser;
}

const ReinviteUserButton: React.FC<ReinviteUserButtonProps> = ({ user }) => {
  const [loading, setLoading] = useState<boolean>(false);
  const { showSuccessSnackbar, showErrorSnackbar } = useSnackbarService();

  const { t } = useTranslation();

  const handleReinvite = async () => {
    setLoading(true);
    try {
      const response: AxiosResponse<IsSuccessResponse> = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}api/settings/users/${user.id}/reinvite`,
      );
      if (response.data.isSuccess) {
        showSuccessSnackbar(response.data.message);
      } else {
        showErrorSnackbar(response.data.message);
      }
    } catch (error) {
      showErrorSnackbar(error.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Tooltip title={t("Reinvite User")}>
      <IconButton
        aria-label="reinvite user"
        onClick={handleReinvite}
        disabled={loading}
      >
        {loading ? <CircularProgress size={24} /> : <PersonAddIcon />}
      </IconButton>
    </Tooltip>
  );
};

export default ReinviteUserButton;

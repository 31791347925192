import axios, { AxiosResponse } from "axios";

export interface InviteUserResponse {
  isSuccess: boolean;
  message: string;
}

export interface GetUserRolesResponse {
  roles: string[];
}

export interface IsSuccessResponse {
  isSuccess: boolean;
  message: string;
}
export interface SuspendUserResponse {
  isSuccess: boolean;
  message: string;
}

export interface UnsuspendUserResponse {
  isSuccess: boolean;
  message: string;
}

export interface UserModuleRoles {
  module: string;
  availableRoles: string[];
  userCurrentRole?: string;
}

export interface GetUserAvailabeModuleRolesResponse {
  moduleRoles: UserModuleRoles[];
}

const API_PREFIX = `${process.env.REACT_APP_API_BASE_URL}api/settings`;

export const inviteUser = async (
  firstName: string,
  lastName: string,
  email: string,
  roles: string[],
  modulesRoles: Record<string, string>,
) => {
  try {
    // Send a POST request to the server to invite a new user
    // The server will send an email to the user with an invitation link
    // with the fields set as parameters
    const response: AxiosResponse<InviteUserResponse> = await axios.post(
      `${API_PREFIX}/users/invite`,
      {
        firstName,
        lastName,
        email,
        roles,
        modulesRoles,
      },
    );

    return response.data as InviteUserResponse;
  } catch (error) {
    throw new Error(error);
  }
};

export const getUserRoles = async (userId: string) => {
  try {
    const response: AxiosResponse<GetUserRolesResponse> = await axios.get(
      `${API_PREFIX}/users/${userId}/roles`,
    );
    return response.data.roles as string[];
  } catch (error) {
    throw new Error(error);
  }
};

export const setUserRoles = async (userId: string, roles: string[]) => {
  // Send a POST request to the server to update the user roles
  try {
    const response: AxiosResponse<IsSuccessResponse> = await axios.post(
      `${API_PREFIX}/users/${userId}/roles`,
      {
        roles,
      },
    );
    return response.data;
  } catch (error) {
    throw new Error(error);
  }
};

export const suspendUser = async (userId: string) => {
  try {
    const response: AxiosResponse<SuspendUserResponse> = await axios.post(
      `${API_PREFIX}/users/${userId}/suspend`,
    );
    return response.data;
  } catch (error) {
    throw new Error(error);
  }
};

export const unsuspendUser = async (userId: string) => {
  try {
    const response: AxiosResponse<UnsuspendUserResponse> = await axios.post(
      `${API_PREFIX}/users/${userId}/unsuspend`,
    );
    return response.data as UnsuspendUserResponse;
  } catch (error) {
    throw new Error(error);
  }
};

export const getUserAvailableModuleRoles = async (userId: string) => {
  try {
    const response: AxiosResponse<GetUserAvailabeModuleRolesResponse> =
      await axios.get(`${API_PREFIX}/users/${userId}/modules-roles`);
    return response.data.moduleRoles as UserModuleRoles[];
  } catch (error) {
    throw new Error(error);
  }
};

export const setUserModulesRoles = async (
  userId: string,
  modulesRoles: Record<string, string>,
) => {
  try {
    const response: AxiosResponse<IsSuccessResponse> = await axios.post(
      `${API_PREFIX}/users/${userId}/modules-roles`,
      {
        modulesRoles,
      },
    );
    return response.data;
  } catch (error) {
    throw new Error(error);
  }
};

export const getAdminModuleRoles = async () => {
  try {
    const response: AxiosResponse<GetUserAvailabeModuleRolesResponse> =
      await axios.get(`${API_PREFIX}/admin/modules-roles`);
    return response.data.moduleRoles as UserModuleRoles[];
  } catch (error) {
    throw new Error(error);
  }
};

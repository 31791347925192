import React, { useState } from "react";

import RemoveCircleOutlineOutlinedIcon from "@mui/icons-material/RemoveCircleOutlineOutlined";
import {
  IconButton,
  Tooltip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  CircularProgress,
  Box,
} from "@mui/material";
import { AccountUser, getUsers } from "src/slices/settings/user";
import { useDispatch } from "src/store";

import { SuspendUserResponse, suspendUser } from "../../api/user";
import { useDialog } from "../hooks/useDialog";
import { useSnackbarService } from "../hooks/useSnackbarService";

interface SuspendButtonProps {
  user: AccountUser;
}

const SuspendButton: React.FC<SuspendButtonProps> = ({ user }) => {
  const { showErrorSnackbar, showSuccessSnackbar } = useSnackbarService();
  const { open, handleOpen, handleClose } = useDialog();
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const handleSuspendUser = async () => {
    try {
      setLoading(true);
      const response: SuspendUserResponse = await suspendUser(user.id);
      if (response.isSuccess) {
        showSuccessSnackbar(response.message);
        // Update the users state in the Redux store
        // after the user has been suspended
        dispatch(getUsers());
      } else {
        showErrorSnackbar(response.message);
      }
    } catch (error) {
      showErrorSnackbar(error.message);
    } finally {
      setLoading(false);
      handleClose();
    }
  };

  return (
    <>
      <Tooltip title="Suspend">
        <IconButton aria-label="suspend" onClick={handleOpen}>
          <RemoveCircleOutlineOutlinedIcon />
        </IconButton>
      </Tooltip>
      <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
        <DialogTitle>Suspend User</DialogTitle>
        {loading ? (
          <Box display="flex" justifyContent="center" pb={4}>
            <CircularProgress />
          </Box>
        ) : (
          <>
            <DialogContent>
              <DialogContentText>
                Are you sure you want to suspend <b>&quot;{user.name}&quot;</b>?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose} color="primary">
                Cancel
              </Button>
              <Button onClick={handleSuspendUser} color="primary">
                Suspend
              </Button>
            </DialogActions>
          </>
        )}
      </Dialog>
    </>
  );
};

export default SuspendButton;

export interface AutomatedNegotiation {
  id: string;
  title: string;
  state: AutomatedNegotiationState;
  negotiation_round: number;
  preferences_provided: boolean;
  accepted_offer_id: number;
  created_at: string;
  expires_at: string;
  supplier_name?: string;
}

export enum AutomatedNegotiationState {
  INITIALISED = "INITIALISED",
  REQUESTED_PREFERENCES = "REQUESTED_PREFERENCES",
  MADE_FIRST_OFFER = "MADE_FIRST_OFFER",
  NEGOTIATING = "NEGOTIATING",
  EXPIRED = "EXPIRED",
  OFFER_ACCEPTED = "COMPLETED_OFFER_ACCEPTED",
  ESCALATED = "ESCALATED",
}

import { Box, Card, Container, styled } from "@mui/material";
import { Helmet } from "react-helmet-async";
import Logo from "src/components/LogoSign";
import useAuth from "src/hooks/useAuth";

import Auth0Login from "../LoginAuth0";

const Content = styled(Box)(
  () => `
    display: flex;
    flex: 1;
    width: 100%;
`,
);

const MainContent = styled(Box)(
  () => `
  padding: 0px;
  width: 100%;
  display: flex;
  align-items: center;
`,
);

function LoginCover() {
  const { method } = useAuth();

  return (
    <>
      <Helmet title="Uncapt" />
      <Content>
        <MainContent>
          <Container
            sx={{
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
            }}
            maxWidth="sm"
          >
            <Card
              sx={{
                p: 4,
                my: 4,
              }}
            >
              <Box sx={{ p: 2 }}>
                <Logo />
              </Box>
              {method === "Auth0" && <Auth0Login />}
            </Card>
          </Container>
        </MainContent>
      </Content>
    </>
  );
}

export default LoginCover;
